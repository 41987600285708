<template>
  <div>
    <PageHeader
      title="Admin Users"
      lead="Manage the administrators for your company's WorkBright account. Promote staff to admin or create a new admin user."
    />
    <div class="d-flex justify-content-between align-items-end">
      <div class="btn-group my-3">
        <a
          class="btn btn-primary"
          :href="$routes.new_admin_user_path"
        >
          <WbIcon type="plus-circle" />
          New Admin
        </a>
        <WbButton
          class="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-label="More Options"
        >
          <WbIcon
            type="caret-down"
            class="fa-fw right"
          />
        </WbButton>
        <ul class="dropdown-menu">
          <li>
            <a :href="$routes.promote_admin_users_path">Promote existing staff member</a>
          </li>
        </ul>
      </div>

      <WbSearchInput
        ref="search"
        :label="null"
        placeholder="Search"
        :autofocus="true"
        :value="query"
        :throttle="true"
        @input="handleSearchInput"
      />
    </div>
    <ul
      v-if="shouldShowPendingAdmin"
      ref="adminTypeNav"
      class="nav nav-tabs mb-3"
    >
      <li
        class="nav-item"
        :class="{active: view === 'admin'}"
      >
        <a
          id="adminUsersNav--admin"
          role="button"
          @click="view = 'admin'"
        >
          Admin Users
        </a>
      </li>
      <li
        class="nav-item"
        :class="{active: view === 'pending'}"
      >
        <a
          id="adminUsersNav--pending"
          role="button"
          @click="view = 'pending'"
        >
          Pending Admin Users
        </a>
      </li>
    </ul>
    <Spinner
      v-if="isLoading"
      ref="loader"
    />
    <template v-else>
      <template v-if="hasSufficientData">
        <AdminUsersTable
          id="admin-users-table"
          :users="users"
          :isPendingAdmin="view === 'pending'"
          @update="handleUpdate"
        />
        <WbListPaginationFooter
          ref="pagination"
          :previousPage="pagination.previous_page"
          :nextPage="pagination.next_page"
          :lastPage="pagination.total_pages"
          :totalItems="pagination.total_items"
          :indexStart="indexStart"
          :indexEnd="indexEnd"
          @pageChange="handlePageChange"
        />
      </template>
      <AlertMessage
        v-else
        ref="noDataMessage"
        alertType="info"
        message="There are currently no admins to display"
      />
    </template>
  </div>
</template>
<script>
  import AdminUsersTable from 'components/admin/admin_users/AdminUsersTable'
  import AlertMessage from 'components/common/AlertMessage'
  import PageHeader from 'components/common/PageHeader'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import WbSearchInput from 'components/common/WbSearchInput'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'the-admin-users-root',
    components: {
      AdminUsersTable,
      AlertMessage,
      PageHeader,
      Spinner,
      WbButton,
      WbIcon,
      WbListPaginationFooter,
      WbSearchInput,
    },
    props: {
      shouldShowPendingAdmin: {
        type: Boolean,
      },
    },
    data () {
      return {
        isLoading: false,
        page: 1,
        view: 'admin',
        query: '',
      }
    },
    computed: {
      ...mapGetters({
        users: 'admin_users/collection',
        pagination: 'admin_users/pagination',
      }),
      hasSufficientData () {
        return this.users?.length
      },
      indexEnd () {
        let val = 0
        if (this.pagination) {
          val = Math.min(this.indexStart + this.pagination.per_page - 1, this.pagination.total_items)
        }
        return val
      },
      indexStart () {
        let val = 0
        if (this.pagination) {
          val = (this.pagination.page - 1) * this.pagination.per_page + 1
        }
        return val
      },
    },
    watch: {
      view () {
        this.page = 1
        this.query = ''
        this.requestData()
      },
      query (newValue) {
        if (newValue.length >= 3 || newValue.length == 0 ) {
          this.requestData()
        }
      },
    },
    created () {
      this.requestData()
    },
    methods: {
      ...mapActions({
        fetchAdminUsers: 'admin_users/fetch',
      }),
      handlePageChange (page) {
        this.page = page
        this.requestData()
      },
      handleUpdate () {
        this.requestData()
      },
      handleSearchInput (value) {
        this.page = 1
        this.query = value
      },
      async requestData () {
        this.isLoading = true

        try {
          await this.fetchAdminUsers({
            admin_status: this.view,
            page: this.page,
            query: this.query,
          })
        } catch (xhr) {
          Util.genericAjaxError('An error occurred fetching admin users.', xhr)
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>
