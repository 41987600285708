// Container for displaying a table of Everify cases

<template>
  <div class="row">
    <div class="col-md-3">
      <SortOrder
        ref="sort-order"
        :filter="sortBy.filter"
        :collectionViewName="collectionViewName"
      />
      <ListFilterSet
        ref="list-filter-set"
        :filters="filters"
        :collectionViewName="collectionViewName"
        class="pt-4 mt-4"
      />
    </div>
    <div class="col-md-9">
      <div class="wb-list everify-case-list">
        <WbListHeader
          ref="list-header"
          :filteredRowIds="[]"
          :collectionViewName="collectionViewName"
          searchPlaceholder="Search Staff Name..."
        />
        <v-wait :for="loadingName">
          <template #waiting>
            <Spinner :showLongWaitMessage="true" />
          </template>
          <p
            v-if="everifyCases.length == 0"
            ref="no-cases"
          >
            No matching E-Verify cases
          </p>
          <ul
            ref="case-list"
            class="list-group"
          >
            <EverifyCaseListItem
              v-for="everifyCase in everifyCases"
              :key="everifyCase.id"
              :everifyCase="everifyCase"
              :collectionViewName="collectionViewName"
              @caseDeleted="handleCaseDeleted"
            />
          </ul>
          <WbListPaginationFooter
            v-if="displayPagination"
            ref="pagination"
            :previousPage="pagination.previousPage"
            :nextPage="pagination.nextPage"
            :lastPage="pagination.lastPage"
            :totalItems="pagination.totalItems"
            :indexStart="pagination.indexStart"
            :indexEnd="pagination.indexEnd"
            :collectionViewName="collectionViewName"
          />
        </v-wait>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import * as mutate from 'vuex/mutation_types'
  import WbListHeader from 'components/common/WbListHeader'
  import ListFilterSet from 'components/common/ListFilterSet'
  import EverifyCaseListItem from 'components/everify/cases/EverifyCaseListItem'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import Spinner from 'components/common/Spinner'
  import PubSub from 'lib/pub_sub'
  import SortOrder from 'components/common/collection_view_filters/SortOrder'
  import PersistedCollectionViewWrapper from 'lib/persisted_collection_view_wrapper'
  import Locale from 'lib/locale'
  import { FILTER_EVERIFY_STATUSES } from 'components/admin/dashboard/EverifyCaseStatusConstants'
  import { debounce } from 'underscore'
  import Constants from 'lib/constants'

  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_everify_cases'
  const DETAIL_HASH = '#detail'

  export default {
    name: 'everify-case-list-container',
    components: {
      WbListHeader,
      ListFilterSet,
      EverifyCaseListItem,
      WbListPaginationFooter,
      Spinner,
      SortOrder,
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
        loadingName: 'everify_cases_loading',
        sortBy: {
          filter: {
            title: 'Case Info',
            filter_key: 'sort',
            defaultValue: 'date',
            options: [
              { value: 'date', label: 'Most Recent' },
              { value: 'name', label: 'Last Name' },
            ],
          },
        },
        filters: [
          {
            type: 'multiselect',
            title: 'Case Status',
            filter_key: 'case_status',
            placeholder: 'Any',
            options: FILTER_EVERIFY_STATUSES,
          },
          {
            type: 'select',
            title: 'Employment Status',
            filter_key: 'employment_status',
            options: [
              { value: 'active', label: 'Active Staff Only' },
              { value: 'inactive', label: 'Inactive Staff Only' },
              { value: null, label: 'All' },
            ],
          },
          {
            type: 'select',
            title: Locale.t('everify.deadline'),
            filter_key: 'deadline_on',
            options: [
              { value: null, label: Locale.t('everify.deadlineFilter.all') },
              { value: 'past_due', label: Locale.t('everify.deadlineFilter.past') },
              { value: 'soon', label: Locale.t('everify.deadlineFilter.soon') },
              { value: 'any', label: Locale.t('everify.deadlineFilter.any') },
            ],
          },
        ],
        refreshDisable: false,
        urlIdParam: null,
        urlHash: null,
      }
    },

    computed: {
      displayPagination() {
        return this.pagination && this.everifyCases.length > 0
      },
      isDetailMode() {
        return !!this.urlIdParam && this.detailHashPresent
      },
      isNormalMode() {
        return !this.isDetailMode
      },
      detailHashPresent() {
        return this.urlHash === DETAIL_HASH
      },
      fetchParams() {
        if (this.isDetailMode){
          return {id: this.urlIdParam}
        } else {
          return {
            ...this.filterValues,
            sort: this.sortOrder,
            page: this.pageNumber,
          }
        }
      },
      ...mapGetters({
        pageContext: 'pageContext',
        filterValues: `${VUEX_COLLECTION_VIEW_NAME}/filterValues`,
        sortOrder: `${VUEX_COLLECTION_VIEW_NAME}/sortOrder`,
        pageNumber: `${VUEX_COLLECTION_VIEW_NAME}/page`,
        everifyCases: 'everify_cases/collection',
        pagination: 'everify_cases/pagination',
        requiresRefresh: 'everify_cases/requiresRefresh',
      }),
      ...mapState({
        employees: state => state['employees'].collection,
      }),
    },

    watch: {
      requiresRefresh () {
        if (this.requiresRefresh) {
          this.fetchCases()
        }
      },
    },

    created () {
      this.setupCollectionViewStore()
      this.initUrlProps()
      if (this.isNormalMode){ this.initNormalMode() }
      if (this.isDetailMode){ this.initDetailMode() }
      this.subscribeToPubSubEvents()
    },

    beforeUnmount() {
      this.$store.unregisterModule(this.collectionViewName)
      if (this.persistedPageWrapper){ this.persistedPageWrapper.unsubscribe() }
    },

    methods: {
      clearIdParam() {
        const url = new URL(window.location)
        url.hash = ''
        url.searchParams.delete("id")
        this.urlHash = null
        window.history.pushState({}, '', decodeURI(url.href))
      },
      fetchCases() {
        this.startLoading()
        this.fetchEverifyCases({
          filters: this.fetchParams,
        }).then(
          this.handleFetchedCases,
        ).finally(
          this.endLoading,
        )
      },
      debounceFetchCases: debounce(function() {
        this.fetchCases()
      }, Constants.DEFAULT_REQUEST_THROTTLE),
      startLoading() {
        this.$wait.start(this.loadingName)
      },
      endLoading() {
        this.$wait.end(this.loadingName)
      },
      subscribeToPubSubEvents() {
        this.updateChannel = PubSub.subscribeToUserChannel({
          topic: 'everify_case',
          unique: true,
        })
        this.updateChannel.bind('update', (data) => {
          this
            .fetchEverifyCaseById({id: data.id})
            .then((everify_case) => { this.$store.commit('everify_cases/MERGE_INTO_RECORD', everify_case) })
        })
      },
      setDefaultFilterValues() {
        // Set default filter to show only cases for active employees if no employment status has been selected
        // - also resets page number to 1
        if (!this.filterValues.employment_status) {
          this.$store.dispatch(`${this.collectionViewName}/setFilterValue`, {
            filter_key: 'employment_status',
            value: 'active',
          })
        }
      },
      handleFetchedCases() {
        this.$nextTick(() => {
          if (this.isDetailMode && this.pagination.totalItems > 0){
            this.setPageContextKeys({ everify_case_id: this.urlIdParam })
          }
        })
      },
      bindCollectionViewStore() {
        // Bind the CollectionViewStore to PersistedPageState
        this.persistedPageWrapper = new PersistedCollectionViewWrapper(this.$store, this.collectionViewName, ['case_status', 'search', 'deadline_on'])
      },
      setupStoreSubscriptions() {
        // Refresh the list whenever filters change
        this.$store.subscribe((mutation) => {
          switch (mutation.type) {
            case `${this.collectionViewName}/SET_FILTER_VALUE`:
              this.toNormalMode()
              this.debounceFetchCases()
              break
            case `${this.collectionViewName}/SET_SORT_ORDER`:
            case `${this.collectionViewName}/SET_PAGE_NUMBER`:
            case `${this.collectionViewName}/CLEAR_FILTER_VALUES`:
              this.fetchCases()
              break
          }
        })
      },
      setupCollectionViewStore() {
        // Configure a CollectionViewStore to manage the list
        if (!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
          this.$store.registerModule(this.collectionViewName, CollectionViewStore)
        }
      },
      initUrlProps() {
        const url = new URL(window.location)
        this.urlIdParam = url.searchParams.has('id') ? parseInt(url.searchParams.get("id")) : null
        this.urlHash = url.hash
      },
      toNormalMode() {
        if (this.isDetailMode){
          this.clearIdParam()
          this.bindCollectionViewStore()
        }
      },
      initNormalMode() {
        this.clearIdParam()
        this.bindCollectionViewStore()
        this.setupStoreSubscriptions()
        this.setDefaultFilterValues()
      },
      initDetailMode() {
        this.setupStoreSubscriptions()
        this.fetchCases()
      },
      handleCaseDeleted: async function () {
        this.fetchCases()
      },
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
      ...mapActions({
        fetchEverifyCaseById: 'everify_cases/fetchById',
        fetchEverifyCases: 'everify_cases/fetchAll',
      }),
    },
  }

</script>
