<template>
  <RadioToggle
    v-if="radioToggleOptions"
    ref="toggle"
    title="Verification:"
    :value="status"
    :options="radioToggleOptions"
    @selected="handleFilterChange"
  />
</template>

<script>
  import RadioToggle from 'components/common/RadioToggle'

  export const DOCUMENT_VERIFICATION_VALUES = [
    { label: 'All', value: 'all' },
    { label: 'Verified', value: 'completed' },
    { label: 'Unable to Verify', value: 'failed' },
    { label: 'Reviewed', value: 'reviewed' },
  ]

  const VALID_FILTERS = [
    'countersigned',
    'pending',
    'awaiting_ssn',
    'reverify',
    'pending_countersign',
  ]

  export default {
    components: {
      RadioToggle,
    },
    props: {
      filter: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        default: 'all',
      },
    },
    emits: ['selected'],
    computed: {
      radioToggleOptions() {
        return VALID_FILTERS.includes(this.filter) ? DOCUMENT_VERIFICATION_VALUES : null
      },
    },
    methods: {
      handleFilterChange(selection) {
        this.$emit('selected', selection)
      },
    },
  }
</script>
