import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function createBulkStaffUpdate({fileUrl, fileCache, fileContentType}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'POST',
      type: 'JSON',
      url: getRoute('user_updates_path'),
      data: {
        bulk_staff_update: {
          file_url: fileUrl,
          file_cache: fileCache,
          file_content_type: fileContentType,
        },
      },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function selectEmployeeId({id, employee_id_column_index}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('select_employee_id_user_update_path', {id}),
      data: {
        bulk_staff_update: {employee_id_column_index},
      },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function matchColumns({id, columns_map}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('match_columns_user_update_path', { id }),
      data: {
        bulk_staff_update: {columns_map},
      },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function startProcessing({id, pubsub_channel}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('start_processing_user_update_path', { id }),
      data: {
        pubsub_channel: pubsub_channel,
      },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function fetchBulkStaffUpdates (page = 1) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'GET',
      type: 'JSON',
      url: `${getRoute('list_user_updates_path')}?page=${page}`,
    }).success((xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function destroy ({ id }) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'DELETE',
      type: 'JSON',
      url: getRoute('user_update_path', { id }),
    }).success((xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function transitionBack({id}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('transition_back_user_update_path', { id }),
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}
