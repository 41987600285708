<template>
  <FormFieldNumber
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :errors="errors"
    :required="required"
    :value="value"
    :hint="hint"
    :min="min"
    addonPre="$"
    :disabled="disabled"
    @input="handleInput"
  />
</template>

<script>
  import FormFieldNumber from 'components/common/FormFieldNumber'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-currency',
    components: {
      FormFieldNumber,
    },
    mixins: [FormFieldConcern],

    props: {
      value: {
        type: [String, Number],
        required: false,
      },

      min: {
        required: false,
      },

      disabled: {
        required: false,
        default: false,
      },
    },

    methods: {
      handleInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
