// /* global moment */

import AttachmentCollectionView from 'views/document_submissions/i9/attachment_collection_view'
import I9Submission from 'lib/i9_submission'

// Review and edit an individual employee's I9 documentation
//
// model - I9Submission
//
// options - childIndex: integer, index in collection for element naming purposes
//           alwaysExpanded: boolean, if true view opens with documentation visible, unable to collapse
//
export default Backbone.Marionette.LayoutView.extend({
  template: '#i9-bulk-countersign-row-tmpl',
  className: 'panel panel-default',
  originalStartDate: null,  // Original start date for this employee. Used to determine if it has been changed

  regions: {
    documentationRegion: '.documentation-container',      // Read-only documentation list
  },

  ui: {
    expand: '.expand',                      // Button to expand/contract accordion
    updateDocuments: '.update-documents',            // Button to update documentation
    editAdditionalInfoButton: '.btn-edit-additional-info',    // Button to edit Additional Info text
    editEmployersInfoButton: '.btn-edit-employers-info',    // Button to edit Additional Info text

    startDateInput: 'input.start-date-input',
    updateProfileStartDate: '.update-profile-start-date-box',

    warningRow: '.warning-msg-row',
    warningMessageLabel: '.warning-msg-row .alert #warning-message',
    indicatorIcon: '.row-indicator-icon',

    lateReasonDropdown: '#late-verification',
  },

  events: {
    'click @ui.expand': 'showDocumentation',    // Click handler to show read-only documentation
  },

  triggers: {
    'click @ui.updateDocuments': 'update:documentation',           // Handled by the CollectionView this is bound to
    'click @ui.editAdditionalInfoButton': 'edit:additionalInfo',    // Handled by the CollectionView this is bound to
    'click @ui.editEmployersInfoButton': 'edit:employersInfo',    // Handled by the CollectionView this is bound to
  },

  bindings: {
    'input.start-date-input': 'employee_start_date',
    'input.update-profile-start-date': 'update_profile_start_date',
    'input.late-reason-other': 'late_reason_other',
    '.late-reason': {
      observe: 'late_reason',
      onSet: (val, options) => {
        options.view.model.set('late_reason_other', null) // Always clear out the other reason if changed
        return val
      },
    },
    '.i9_employer_review_late_reason_other': {
      observe: 'late_reason',
      visible: (val) => val == 'OTHER',
      updateView: false,
    },
  },

  modelEvents: {
    change: 'renderWarnings',
    'change:employee_start_date': 'startDateChange',
  },

  initialize: function() {
    // Retain the original start date to see if it has changed later on (drives the visibility of the 'Update Start Date' checkbox)
    this.originalStartDate = this.model.get('employee_start_date').toISOString()

    this.lateSubmissionCheck()
  },

  startDateChange: function() {
    this.toggleUpdateProfileVisiblity()
    this.lateSubmissionCheck()
  },

  // Toggle the visiblity for the 'Update Profile Start Date' checkbox
  toggleUpdateProfileVisiblity: function() {
    // Determine if the start date has changed
    const startDate = this.model.get('employee_start_date').toISOString()
    const hasChanged = this.originalStartDate != startDate

    const isHidden = this.ui.updateProfileStartDate.is(':hidden') // Is the box currently hidden?

    // Show the box if start date is different from the employee profile
    this.ui.updateProfileStartDate.toggle(hasChanged)

    // - Check the box by default if it was originally hidden
    // - Uncheck the box if nothing has changed
    // - Do nothing if the box was already visible
    if (hasChanged && isHidden || !hasChanged){
      this.model.set('update_profile_start_date', (hasChanged && isHidden) )
    }
  },

  lateSubmissionCheck: function() {
    const startDate = this.model.get('employee_start_date')

    if (startDate.isValid()) {
      I9Submission.isLateSubmission(
        this.model.get('employee_start_date'),
      ).then( result => {
        this.model.set('is_late', result)

        // If the submission is within three days, remove the late reason(s)
        if (!result) {
          this.model.set('late_reason', null)
          this.model.set('late_reason_other', null)
        }
      })
    }
  },

  templateHelpers: function() {
    return { index: this.getOption('childIndex') }
  },

  onRender: function() {
    // HACKFIX for iOS Safari
    setTimeout(function(){
      $.runInitializers(this.$el)
    }.bind(this))

    this.stickit()
    this.toggleUpdateProfileVisiblity()

    // View is always expanded - open the accordion, load documents, and prevent collapsing
    if (this.getOption('alwaysExpanded')){
      this.showDocumentation(true)
      this.$el.find(".panel-collapse").addClass('in')
      this.ui.expand.removeClass('collapsed')
    }
  },


  // Show the documentation the first time the accordian is expanded
  showDocumentation: function(force) {
    if (this.documentationRegion.hasView() && force !== true) {
      return
    }

    this.documentationRegion.show(new AttachmentCollectionView({
      collection: this.model.documentationSet,
      className: 'i9-documentation i9-review',
    }))

    this.allImgEditors = _.map(this.$el.find('.attachment-img'), function (imageEl) {
      const imgView = new App.Views.Common.ImageEditorView({
        el: $(imageEl).parent().prev('.img-ed'),
        imgSrc: imageEl.src,
        adminMode: true,
        submission: this.model,
        updateCallback: function(options) {
          const attachment = this.model.documentationSet.attachmentForIdentifier(options.originalFileIdentifier)
          attachment.updateEditedAttachment(options)
        }.bind(this),
      })
      imgView.render()

      return imgView
    }.bind(this))
  },

  // Populates the warning message label if there are warnings to display, shows the row, and appends the icon.
  renderWarnings: function() {
    // FIXME: Convert warning messages to I18n strings
    if (this._blankStartDate()) {
      // Add warning message about blank start-date
      const blankWarning = "Warning: Only recruiters should leave the First Day of Employment blank."
      this._toggleWarning(blankWarning)
      this.ui.lateReasonDropdown.hide()
    } else if (this._isCountersignedLate()) {
      // Add warning message and late-reason dropdown
      const lateWarning = "Warning: The verification date (today) is more than 3 business days after the staff member's first day of work. Please select a reason why it is being submitted late."
      this._toggleWarning(lateWarning)
      this.ui.lateReasonDropdown.show()
    } else {
      // No warning
      this._toggleWarning(null)
      this.ui.lateReasonDropdown.hide()
    }
  },

  _isCountersignedLate: function() {
    return this.model.get('is_late')
  },

  _blankStartDate: function() {
    return !this.model.get('employee_start_date').isValid()
  },

  _toggleWarning(msg) {
    if (_.isEmpty(msg)) {
      this.ui.warningMessageLabel.text('')
      this.ui.warningRow.hide()
      this.ui.indicatorIcon.toggleClass('warning-indicator', false)
    } else {
      this.ui.warningMessageLabel.text(msg)
      this.ui.warningRow.show()
      this.ui.indicatorIcon.toggleClass('warning-indicator', true)
    }
  },

  onDestroy: function () {
    if (!this.allImgEditors) { return };

    // Destroy all ImgEditors!
    _.each(this.allImgEditors, function(editor) { editor.destroy() })
  },
})
