<template>
  <div class="wb-card">
    <div class="wb-card__title">
      {{ widgetTitle }}
      <p
        v-if="lastUpdated"
        class="wb-card__last_updated"
      >
        Last updated
        <WbSimpleDateTime
          :value="lastUpdated"
          :includeTimeago="true"
        />
        <a
          class="ml-3"
          href='javascript:void(0)'
          @click="$emit('refresh')"
        >
          Refresh
          <WbIcon
            type="refresh"
            class="ml-1"
          />
        </a>
      </p>
    </div>
    <div class="wb-card__body">
      <p>
        <em v-if="errorMsg.length">
          {{ errorMsg }}
        </em>
      </p>
      <slot />
    </div>
  </div>
</template>

<script>
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'dashboard-widget',
    components: {
      WbIcon,
      WbSimpleDateTime,
    },
    props: {
      widgetTitle: {
        type: String,
        required: true,
      },
      errorMsg: {
        type: String,
        required: false,
        default: '',
      },
      lastUpdated: {
        type: String,
        required: false,
        default: '',
      },
    },
  }
</script>
