<template>
  <div>
    <PageHeader title="Enhanced Document Verification" />
    <div
      v-if="isFeatureEnabled"
      ref="enabled"
      class="panel gray-bg"
    >
      Enhanced Document Verification has been enabled on your account.
    </div>
    <div
      v-else
      ref="disabled"
    >
      <div class="row">
        <div class="col-md-7">
          <h3 class="feature-list-section-title">
            Powerful Document Verification features are available across WorkBright I-9's pricing tiers, helping your business prevent common errors, improve compliance, and reduce time spent correcting document issues.
          </h3>

          <h4 class="feature-list-section-title">What’s included in each tier: </h4>
          <img
            ref="documentVerificationsImage"
            :src="documentVerificationsImageUrl"
            class="upsell-image w-100 my-3"
          />
          <h4
            ref="growthTierTitle"
            class="feature-list-section-title"
          >Growth tier features:</h4>
          <ul ref="growthTierList">
            <li>
              <strong>Enhanced Camera with Optical Character Recognition:</strong>
              Like depositing a check in a banking app, the upgraded I-9 photo experience guides employees to take well-lit, in-focus, and properly framed photos of their supporting documents. Document information is then automatically transcribed to reduce errors.
            </li>
            <li>
              <strong>Type Match:</strong> WorkBright ensures the document uploaded matches the type the employee selected. For example, if they choose a U.S. passport as their Type A document but upload a state ID, they’ll be prompted to update the type or upload a different photo.
            </li>
            <li>
              <strong>Image Quality Detection:</strong> Ensures work authorization document images are clear, legible, and fully in frame.
            </li>
          </ul>
          <h4
            ref="premiumTierTitle"
            class="feature-list-section-title"
          >Premium tier features:</h4>
          <ul ref="premiumTierList">
            <li><strong>Everything listed in the Growth tier above</strong></li>
            <li><strong>Valid for Work Authorization Verification:</strong> Confirms that work authorization documents are unexpired and don’t contain restrictive language (e.g., Permanent Resident Card restrictions).</li>
            <li><strong>Original Document Verification:</strong> Verifies that documents presented are original physical documents, not screenshots or replicas.</li>
            <li><strong>Document Fraud Detection:</strong> Uses metadata, color analysis, and modification detection to flag altered or potentially fraudulent documents.</li>
          </ul>

          <h4
            ref="whyDocVerificationTitle"
            class="feature-list-section-title"
          >Why use enhanced document verification? </h4>
          <ul ref="whyDocVerificationList">
            <li><strong>Improved new hire experience:</strong> Onboarding staff get a guided camera experience and real-time confirmation of valid document submissions. Expanded OCR support also helps more employees have their documents auto-transcribed during Section 1.</li>
            <li><strong>More efficient administration:</strong> Document verification flags documents that don’t meet compliance standards, enabling admins to review issues—like validity or incorrect formatting—before final submission. This added review step prevents re-work by catching potential issues early, ensuring informed, fair hiring decisions.</li>
            <li><strong>Fraud prevention:</strong> Advanced screening flags irregularities or tampering, reducing the risk of onboarding unauthorized employees as employment scams increase.</li>
            <li><strong>Expert document validation:</strong> Continuously updated screening criteria serve as a permanent document expert, adapting to changes across hundreds of formats to keep your organization compliant.</li>
          </ul>

          <h4
            ref="howDoIKnowTitle"
            class="feature-list-section-title"
          >How do I know what tier I’m in and what’s the cost? </h4>
          <p ref="howDoIKnowContent">
            These enhancements are included in our Growth and Premium tiers at no additional cost, as detailed above. Contact your Account Manager at <a
              ref="contactAdLink"
              href="mailto:ad@workbright.com"
            >ad@workbright.com</a> to confirm your current tier and included features.
          </p>
          <h4
            ref="getStartedTitle"
            class="feature-list-section-title"
          >How do I get started?</h4>
          <p ref="getStartedContent">
            Fill out the form on this page to get these features activated. 
          </p>

          <h4
            ref="moreInfoTitle"
            class="feature-list-section-title"
          >Need more information?</h4>
          <p ref="moreInfoContent">
            <a
              ref="knowledgeBaseLink"
              href="https://workbright.my.site.com/admins/s/article/Document-Verification"
            >Check out the knowledge base</a> to learn more about Document Verification features and workflows.
          </p>
        </div>
        <div class="col-md-5">
          <div class="panel gray-bg">
            <iframe
              src="https://workbrightsupport.com/document-verification-add-on-request/"
              width="100%"
              height="400"
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import PageHeader from 'components/common/PageHeader'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'the-document-verifications-root',

    components: {
      PageHeader,
    },

    props: {
      isFeatureEnabled: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        title: 'Document Verification Add-on Request',
        description: 'Enter your email to add Document Verification to your account. After submitting your email, a WorkBright team member will contact you with pricing and next steps.',
        featureName: 'document_verifications',
      }
    },

    computed: {
      documentVerificationsImageUrl () {
        return getRoute('Images.document_verifications')
      },
    },
  }
</script>
