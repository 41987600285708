<template>
  <div>
    <PermissionSetSummary
      v-for="(permissionSet, index) in permissionSets"
      :key="index"
      :permissionSet="permissionSet"
    />
  </div>
</template>

<script>
  import PermissionSetSummary from 'components/permissions/summary/PermissionSetSummary'

  export default {
    name: 'permissions-summary',
    components: {
      PermissionSetSummary,
    },
    props: {
      permissionSets: {
        type: Array,
      },
    },
    computed: {
      permissionsJson() {
        return JSON.stringify(this.permissionSets)
      },
    },
  }
</script>
