<template>
  <div>
    <AlertMessage
      ref="updateCount"
      class="mb-4"
      variant="success"
      :title="`${staffUpdatedCount} staff were successfully updated`"
    />
    <AlertMessage
      v-if="errorCount"
      ref="errorCount"
      class="mb-4"
      variant="danger"
      :title="`${errorCount} staff failed to update`"
    >
      <div>
        <WbButton
          ref="expandButton"
          :label="areErrorsExpanded ? 'Hide details' : 'See Details'"
          context="link"
          class="pl-0"
          @click="toggleErrors"
        />
        <table
          v-if="areErrorsExpanded"
          ref="errorTable"
          class="table"
        >
          <thead>
            <tr>
              <th>
                WorkBright ID
              </th>
              <th
                v-for="(column, index) in updateColumns"
                :key="index"
              >
                {{ column.name }}
              </th>
              <th>
                Error(s)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(changeItem, index) in failedChangeItems"
              :key="index"
            >
              <td>
                {{ changeItem.employee_id }}
              </td>
              <td
                v-for="(change, subIndex) in changeItem.changes"
                :key="subIndex"
                :class="{'text-danger': change.errors?.length}"
              >
                {{ formatNormalizedValue(change.normalized_value) }}
                <TooltipIcon
                  v-if="change.errors?.length"
                  :ref="`${change.field_key}${changeItem.employee_id}Error`"
                  icon="exclamation-circle"
                  :message="humanizeErrorMessages(change.errors)"
                  class="ml-1"
                />
              </td>
              <td>
                <ul class="pl-3">
                  <li
                    v-for="(error, errorIndex) in changeItem.errors"
                    :key="errorIndex"
                  >
                    {{ getRemainingErrorsMessage(error) }}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </AlertMessage>
    <a
      :href="staffPath"
      class="btn btn-primary"
    >
      Return to Staff
    </a>
  </div>
</template>
<script>
  import { getRoute } from 'lib/routes'
  import AlertMessage from 'components/common/AlertMessage'
  import TooltipIcon from 'components/common/TooltipIcon'
  import WbButton from 'components/common/WbButton'

  export default {
    components: {
      AlertMessage,
      TooltipIcon,
      WbButton,
    },
    props: {
      bulkStaffUpdate: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        areErrorsExpanded: false,
      }
    },
    computed: {
      errorCount () {
        return this.bulkStaffUpdate.sheet_data?.staff_failed_count || 0
      },
      failedChangeItems() {
        return (this.bulkStaffUpdate.sheet_data?.normalized || []).filter(changeItem => {
          return changeItem.changes.some(change => change.errors?.length) || changeItem.errors?.length
        })
      },
      staffPath () {
        return getRoute('employees_path')
      },
      staffUpdatedCount () {
        return this.bulkStaffUpdate.sheet_data?.staff_updated_count || 0
      },
      updateColumns () {
        return Object.values(this.bulkStaffUpdate.columns_map || {}).filter(column => column.ignored === false)
      },
    },
    methods: {
      toggleErrors () {
        this.areErrorsExpanded = !this.areErrorsExpanded
      },
      humanizeErrorMessages (errors) {
        return errors.join(', ')
      },
      getRemainingErrorsMessage (error) {
        if (error.type === 'validation_error') {
          return error.full_message
        } else {
          return this.$locale.t(`userUpdates.errors.${error.type}`)
        }
      },
      formatNormalizedValue (normalizedValue) {
        return normalizedValue === null || normalizedValue === undefined || normalizedValue === ''
          ? '--'
          : String(normalizedValue)
      },
    },
  }
</script>
