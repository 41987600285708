<template>
  <div
    ref="matchColumnsTableValidator"
    class="panel gray-bg mt-0"
  >
    <div class="panel-heading">
      <h3 class="panel-title">
        All Spreadsheet Columns Assigned
      </h3>
    </div>
    <div class="panel-body">
      <div
        v-if="unassignedFields.length > 0"
        ref="unassignedFieldsErrorMessage"
      >
        <p>
          Each column in your spreadsheet must either be mapped to a WorkBright field or you must select "Ignore this column".
        </p>
        <p class="text-danger m-0">
          <WbIcon type="times" />
          <span>
            {{ columnsUnassignedErrorMessage }}
          </span>
        </p>
      </div>
      <p
        v-if="!hasMappedField"
        ref="noMappedFieldErrorMessage"
        class="text-danger m-0"
      >
        <WbIcon type="times" />
        <span>
          You need to map at least one column.
        </span>
      </p>
      <p
        v-else-if="!unassignedFields.length"
        ref="everyColumnAssignedMessage"
        class="text-success m-0"
      >
        <WbIcon type="check" />
        Every column in your spreadsheet is assigned.
      </p>
    </div>
    <div class="panel-footer text-right">
      <WbButton
        v-if="hasMappedField"
        ref="nextStepButton"
        :disabled="!isTableValid"
        :tooltip="nextButtonTooltip"
        tooltip-position="left"
        @click="handleNextStep"
      >
        Next
      </WbButton>
    </div>
  </div>
</template>

<script>
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'

  const MAX_NUMBER_OF_UNASSIGNED_FIELDS_TO_DISPLAY = 3

  export default {
    name: 'table-validator',
    components: {
      WbButton,
      WbIcon,
    },
    props: {
      sortedColumnsMap: {
        type: Array,
        required: true,
      },
    },
    emits: ['nextStep'],
    computed: {
      fieldKeys() {
        return this.sortedColumnsMap
          .reduce((keys, entry) => {
            if (!entry.ignored && entry.field_key !== '') {
              keys.push(entry.field_key)
            }
            return keys
          }, [])
      },
      hasMappedField() {
        return this.sortedColumnsMap.filter(item => item.field_key).length > 0
      },
      unassignedFields() {
        return this.sortedColumnsMap.filter(item => !item.field_key && !item.ignored)
      },
      columnsUnassignedErrorMessage() {
        const unassignedFieldsCount = this.unassignedFields.length
        let additionalFieldsCountMsg = ''
        const isAre = unassignedFieldsCount > 1 ? 'are' : 'is'
        const fieldNames = this.unassignedFields
          .map((field) => field.name)
          .slice(0, MAX_NUMBER_OF_UNASSIGNED_FIELDS_TO_DISPLAY)
          .join(', ')

        if (unassignedFieldsCount > MAX_NUMBER_OF_UNASSIGNED_FIELDS_TO_DISPLAY) {
          additionalFieldsCountMsg = `, and ${unassignedFieldsCount - MAX_NUMBER_OF_UNASSIGNED_FIELDS_TO_DISPLAY} more`
        }


        return `${fieldNames}${additionalFieldsCountMsg} ${isAre} not assigned.`
      },
      nextButtonTooltip() {
        if (this.isTableValid) {return null}

        return 'Please correct the errors to continue.'
      },
      isTableValid() {
        return this.hasMappedField && this.unassignedFields.length === 0
      },
    },
    methods: {
      handleNextStep() {
        this.$emit('nextStep')
      },
    },
  }
</script>
