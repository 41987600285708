// icon to display when content is blocked
<template>
  <span class="blocked-content">
    <i class="fa fa-ban" />
    Restricted
  </span>
</template>

<script>
  export default {
    name: 'blocked-content-icon',
  }
</script>
