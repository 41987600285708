<template>
  <FormField
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :errors="errors"
    :required="required"
    :hint="hint"
    :min="min"
  >
    <div :class="[{ 'input-group': addon || addonPre }, 'short-field']">
      <span
        v-if="addonPre"
        class="input-group-addon"
      >
        {{ addonPre }}
      </span>
      <input
        :id="name"
        class="form-control"
        type="number"
        :name="name"
        :placeholder="placeholderValue"
        :value="value"
        :min="min"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
      />
      <span
        v-if="addon"
        class="input-group-addon"
      >
        {{ addon }}
      </span>
    </div>
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-number',
    components: {
      FormField,
    },
    mixins: [FormFieldConcern],
    props: {
      // Addon after
      addon: {
        type: String,
        required: false,
      },
      // Addon before
      addonPre: {
        type: String,
        required: false,
      },

      value: {
        type: [String, Number],
        required: false,
      },

      min: {
        required: false,
      },

      disabled: {
        required: false,
        default: false,
      },
    },
  }
</script>
