<template>
  <blockquote
    v-if="!accepted"
    ref="rejectionReasonBlockquote"
  >
    <i
      v-if="!rejectionReason && !this.rejectionReasons.length"
      ref="noReasonGiven"
      class="text-muted"
    >No reason
      given.</i>
    <span
      v-else
      ref="rejectionReasonsDetails"
    >
      {{ rejectionReasonsDetails }}
    </span>
  </blockquote>
</template>

<script>
  export default {
    name: 'rejection-reason-blockquote',
    props: {
      accepted: {
        required: true,
      },
      employeeName: {
        required: true,
      },
      rejectionReason: {
        required: true,
        default: null,
      },
      rejectionReasons: {
        required: true,
        type: Array,
        default: () => [],
      },
    },
    computed: {
      rejectionReasonsDetails() {
        if (this.rejectionReason) {
          return this.rejectionReason
        } else {
          return this.rejectionReasons.map((code) => {
            return this.$locale.t(`document_submissions.rejection_reasons.${code}`, this.employeeName)
          }).join(' and ')
        }
      },
    },
  }
</script>
