<template>
  <div>
    <h4>Which form would you like to upload?</h4>
    <WbSearchInput
      v-model="searchValue"
      label="Search by Name..."
    />
    <div class="list-group">
      <a
        v-for="document in filteredDocuments"
        :key="document.id"
        class="list-group-item"
        :href="$routes.new_admin_employee_document_submission_path({employee_id: pageContext.employee.id, document_id: document.id})"
      >
        {{ document.name }}
      </a>
    </div>
    <div
      v-if="filteredDocuments.length == 0"
      class="alert alert-warning"
    >
      No Documents to Upload
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WbSearchInput from 'components/common/WbSearchInput'

  export default {
    name: 'upload-submission-select',
    components: {
      WbSearchInput,
    },
    props: {

    },

    data() {
      return {
        searchValue: '',
      }
    },

    computed: {
      ...mapGetters({
        pageContext: 'pageContext',
        documentAssignmentFindBy: 'document_assignments/findBy',
      }),

      filteredDocuments() {
        return this.uploadableDocuments.filter((document) => {
          if (this.searchValue.length > 0
            && document.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) == -1 ) {
            return false
          }
          return true
        })
      },

      // Any extra uploadable documents
      // Includes any Document that isn't actively assigned or already submitted
      uploadableDocuments() {
        // Find all Active or Submitted DocumentAssignments
        const documentAssignments = this.documentAssignmentFindBy({ employee_id: this.pageContext.employee.id })
        const active = _.filter(documentAssignments, (a) => { return a.active })
        const submitted = _.filter(documentAssignments, (a) => { return !!a.current_submission_id })

        // Find the Document IDs of documents we shouldn't show
        const ignoredDocumentIds = _.uniq(
          active.map( (a) => { return a.document_id }).concat(
            submitted.map( (a) => { return a.document_id }),
          ),
        )

        // Find all Documents not included in the ignore list
        const documents = _.filter(this.$store.state.documents.collection, (document) => {
          return ignoredDocumentIds.indexOf(document.id) == -1
        })

        // Sort by name
        return _.sortBy(documents, (document) => { return document.name })
      },
    },
  }
</script>
