<template>
  <WbModal
    ref="modal"
    class="text-left"
    title="Company's Info"
    :watchPageContextVariable="modalKey"
  >
    <slot>
      <div class="form-horizontal panel profile">
        <FormFieldInput
          ref="name"
          v-model="formData.business_name"
          orientation="horizontal"
          label="Business Name"
          :errors="formErrors"
          name="business_name"
          type="text"
        />

        <FormFieldInput
          ref="street"
          v-model="formData.business_street"
          orientation="horizontal"
          label="Address"
          :errors="formErrors"
          name="business_street"
          type="text"
        />

        <FormFieldInput
          ref="city"
          v-model="formData.business_city"
          orientation="horizontal"
          label="City"
          :errors="formErrors"
          name="business_city"
          type="text"
        />

        <FormFieldSelect
          ref="state"
          v-model="formData.business_state"
          orientation="horizontal"
          label="State"
          :errors="formErrors"
          name="business_state"
          :options="usStates"
          :value="formData.business_state"
        />

        <FormFieldInput
          ref="zip"
          v-model="formData.business_zip"
          orientation="horizontal"
          label="Zip Code"
          :errors="formErrors"
          name="business_zip"
          type="text"
        />
      </div>
    </slot>
    <template #footer>
      <WbButton
        context="default"
        @click.prevent="hideModal"
      >
        Cancel
      </WbButton>
      <WbButton
        ref="saveButton"
        label="Save"
        :isLoading="isLoading"
        @click="saveBusinessData"
      />
    </template>
  </WbModal>
</template>
<script>
  import WbButton from 'components/common/WbButton'
  import WbModal from 'components/common/WbModal'
  import { mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import { updateBusinessData } from 'services/admin/documentSubmissionsService'
  import Util from 'lib/util'

  export default {
    name: 'edit-employer-information-modal',
    components: {
      FormFieldInput,
      FormFieldSelect,
      WbButton,
      WbModal,
    },
    props: {
      business: {
        type: Object,
        required: true,
      },
      submissionModel: {
        type: Object,
        required: true,
      },
      usStates: {
        type: Array,
        required: true,
      },
      onUpdate: {
        type: Function,
        required: true,
      },
    },
    data () {
      return {
        formData: { ...this.business },
        formErrors: {},
        isLoading: false,
        modalKey: 'employer-address',
      }
    },
    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
      saveBusinessData () {
        this.isLoading = true

        updateBusinessData(this.submissionModel.attributes.id, { ...this.formData })
          .then((_xhr) => {
            this.onUpdate(this.formData)

            this.hideModal()

            this.formErrors = {}
          }).catch(xhr => {
            if (xhr.responseJSON?.errors) {
              this.formErrors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError('An error occurred trying to update employer data', xhr)
            }
          }).finally(() => {
            this.isLoading = false
          })
      },
      hideModal () {
        this.$refs.modal.hide()
      },
      openModal () {
        const key = this.modalKey
        this.setPageContextKeys({ [key]: true})
      },
    },
  }
</script>
