import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function resolveDocumentVerification(submissionId, action, comments) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      data: {
        document_verification_resolution: {
          resolve_action: action,
          comments: comments,
        },
      },
      url: getRoute('resolve_document_verifications_i9_submission_path', { id: submissionId }),
    }).success(({ submission, verified_attachments }) => {
      resolve({ submission, verified_attachments })
    }).fail(xhr => {
      reject(xhr)
    })
  })
}
