<template>
  <div>
    <PageHeader
      title="Clone Documents"
      lead="Clone documents from one account to another."
      variant="bordered"
    />

    <div class="form-horizontal">
      <FormAlerts
        :success="success"
        :errors="formErrors"
        :msgSuccess="$locale.t('documents.clone.success')"
      />
      <div class="input-wrapper medium-field">
        <FormField
          orientation="horizontal"
          :required="true"
          label="Source Account"
          :errors="formErrors"
          name="source_tenant"
          class="form-field-source-account"
        >
          <WbMultiselect
            v-model="sourceTenant"
            :selectOptions="accountsSelectFormatted"
            :enableSearchField="true"
            :multiple="false"
            placeholder=""
          />
        </FormField>
        <FormField
          orientation="horizontal"
          :required="true"
          label="Source Document(s)"
          :errors="formErrors"
          name="source_document_ids"
          class="form-field-source-document"
        >
          <div
            v-if="sourceDocuments"
            ref="documentSelect"
          >
            <WbMultiselect
              v-model="sourceDocumentIds"
              :selectOptions="sourceDocumentsSelectFormatted"
              :enableSearchField="true"
              :multiple="true"
              :selectAll="true"
              selectAllText="Select All Forms"
              :disabled="sourceDocuments.length == 0"
              :placeholder="sourceDocuments.length == 0 ? 'No documents to clone' : 'Select one or more documents'"
            />
          </div>
          <label
            v-else
            class='control-label'
          >––</label>
        </FormField>
        <FormField
          orientation="horizontal"
          :required="true"
          label="Destination Account"
          :errors="formErrors"
          name="destination_tenant"
          class="form-field-destination-tenant"
        >
          <WbMultiselect
            v-model="destinationTenant"
            :selectOptions="accountsSelectFormatted"
            :enableSearchField="true"
            :multiple="false"
            placeholder=""
          />
        </FormField>
        <FormField
          orientation="horizontal"
          label="Employee Groups"
          :errors="formErrors"
          name="group_ids"
          class="form-field-employee-groups"
        >
          <div
            v-if="destinationEmployeeGroups"
            ref="destinationEmployeeSelect"
          >
            <WbMultiselect
              :selectOptions="destinationEmployeeGroupsFormatted"
              :value="destinationGroupsSelected"
              placeholder=""
              :enableSearchField="true"
              @input="values => setSelectedGroups(values)"
            />
          </div>
          <label
            v-else
            class='control-label'
          >––</label>
        </FormField>
        <FormFieldCheckbox
          v-model="forceInternal"
          label="Force Internal Only"
          :errors="formErrors"
          name="force_internal"
          class="form-field-internal-only"
        />
        <Toolbar>
          <WbButton
            ref="cloneButton"
            :isLoading="isCloning"
            @click="clone"
          >
            Clone
          </WbButton>
        </Toolbar>
      </div>
    </div>
  </div>
</template>

<script>
  import WbMultiselect from 'components/common/WbMultiselect'
  import WbButton from 'components/common/WbButton'
  import FormAlerts from 'components/common/FormAlerts'
  import FormField from 'components/common/FormField'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import PageHeader from 'components/common/PageHeader'
  import Toolbar from 'components/common/Toolbar'
  import { mapState } from 'vuex'
  import { getRoute } from 'lib/routes'
  import PubSub from 'lib/pub_sub'
  import Util from 'lib/util'
  import { ajax } from 'jquery'

  export default {
    name: 'the-clone-document-root',
    components: {
      WbMultiselect,
      FormAlerts,
      FormField,
      FormFieldCheckbox,
      PageHeader,
      Toolbar,
      WbButton,
    },

    data() {
      return {
        isCloning: false,
        sourceTenant: null,
        sourceDocumentIds: [],
        destinationEmployeeGroups: null,
        destinationTenant: null,
        destinationGroupsSelected: [],
        forceInternal: false,
        pubSubChannel: null,
        sourceDocuments: null,
        formErrors: {},
        success: false,
      }
    },

    computed: {
      accountsSelectFormatted() {
        return this.pageContext.accounts.map((a) => { return { value: a.id, label: a.name } })
      },

      sourceDocumentsSelectFormatted() {
        if (!this.sourceDocuments) { return null }
        return this.sourceDocuments.map((d) => { return { value: d.id, label: d.name }})
      },

      destinationEmployeeGroupsFormatted() {
        if (!this.destinationEmployeeGroups) { return null }
        return this.destinationEmployeeGroups.map((d) => { return { value: d.id, label: d.name }})
      },

      ...mapState([
        'pageContext',
      ]),
    },

    watch: {
      sourceTenant(newAccountId, oldAccountId) {
        if (newAccountId != oldAccountId) {
          this.sourceDocuments = null
          this.sourceDocumentIds = []

          ajax({
            type: 'GET',
            url: getRoute('documents_for_account_clone_documents_path'),
            dataType: 'json',
            data: {
              account_id: newAccountId,
            },
            progressBar: false,
            wbGenericFailureMsg: this.$locale.t("documents.clone.document_fetch_fail"),
          }).done( (data) => {
            this.sourceDocuments = data
          })
        }
      },

      destinationTenant(newAccountId, oldAccountId) {
        if (newAccountId != oldAccountId) {
          this.destinationEmployeeGroups = null
          this.destinationGroupsSelected = []

          ajax({
            type: 'GET',
            url: getRoute('destination_employee_groups_clone_documents_path'),
            dataType: 'json',
            data: {
              account_id: newAccountId,
            },
            progressBar: false,
            wbGenericFailureMsg: this.$locale.t("documents.clone.employee_group_fetch_fail"),
          }).done( (data) => {
            this.destinationEmployeeGroups = data
          })
        }
      },
    },

    mounted() {
      this.subscribeToPubSubEvents()
    },

    methods: {
      clone() {
        this.success = false
        this.formErrors = {}

        this.isCloning = true

        ajax({
          method: 'POST',
          url: getRoute('clone_documents_path'),
          data: {
            clone_document: {
              source_document_ids: this.sourceDocumentIds,
              source_tenant: this.sourceTenant,
              destination_tenant: this.destinationTenant,
              destination_employee_group_ids: this.destinationGroupsSelected,
              force_internal: this.forceInternal,
            },
            pubsub_channel: this.pubSubChannel.name,
          },
          wbGenericFailureMsg: this.$locale.t("documents.clone.fail"),
        }).done((_response, _textStatus, jqXHR) => {
          // If status 201 'created', clone is complete
          // If status is 202 'accepted', clone is not complete, running background
          if (jqXHR.status == 201) {
            this.documentCloneSuccessCallback()
          }
        }).fail((xhr) => {
          if (xhr.responseJSON) { this.formErrors = xhr.responseJSON.errors || {} }
        }).always(() => {
          this.isCloning = false
        })
      },

      setSelectedGroups(groupIds) {
        this.destinationGroupsSelected = groupIds
      },

      subscribeToPubSubEvents: function () {
        this.pubSubChannel = PubSub.subscribeToUserChannel({
          topic: 'clone_document',
          unique: true,
        })

        this.pubSubChannel.bind('complete', this.documentCloneSuccessCallback.bind(this))
        this.pubSubChannel.bind('failed', this.documentCloneFailCallback.bind(this))
      },

      documentCloneSuccessCallback() {
        this.sourceDocumentIds = []
        this.success = true
      },

      documentCloneFailCallback() {
        Util.errorDialog(this.$locale.t("documents.clone.fail"), this.$locale.t("errors.ajax.generic"))
      },
    },
  }
</script>
