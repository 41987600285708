<template>
  <div
    v-if="display"
    class="table-group-fields"
  >
    <table class="table table-bordered table-condensed">
      <tbody>
        <tr>
          <th width="30%">
            {{ this.name }}
          </th>
          <th
            v-for="(action, index) in actions"
            :key="index"
            :width="actionsWidth"
          >
            {{ actionName(action) }}
          </th>
        </tr>
        <tr
          v-for="group in permissionGroups"
          :key="group.key"
        >
          <td>
            {{ group.permissions[0].targetName($store) }}
          </td>
          <GrantCell
            v-for="(action, index) in actions"
            :key="index"
            :target-id="group.target_id"
            :width="actionsWidth"
            :action="action"
            :permissions="group.permissions"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import GrantCell from 'components/permissions/summary/GrantCell'
  import PermissionActionGroupCollection from 'models/policy_generator/permission_action_group_collection'
  import Permission from 'models/policy_generator/permission'

  export default {
    name: 'action-group-collection-summary',

    components: {
      GrantCell,
    },

    props: {
      permissionSet: {
        type: Object,
        required: true,
      },

      name: {
        type: String,
        required: true,
      },

      filter: {
        type: String,
        required: true,
      },
    },

    computed: {
      permissions() {
        let filteredPermissions = this.permissionSet.permissions.filter((p) => p.target_type == this.filter)

        // HACKFIX: Remove Document 'manage' if present
        filteredPermissions = filteredPermissions.filter((p) =>
          !(p.target_type == 'Document' && p.target_id == null && p.action == 'manage'),
        )

        return filteredPermissions
      },

      actions() {
        const targetType = this.permissions[0].target_type
        let actions = []

        if (targetType == 'Document') {
          actions = ['view_submission', 'unlock_submission', 'accept_submission', 'upload_submission', 'edit_attachments', 'countersign_submission']
        } else if (targetType == 'RepresentableField') {
          actions = ['view', 'update']
        } else {
          throw new Error("Invalid target_type for this view")
        }

        return actions
      },

      actionsWidth() {
        const width = (100 - 30) / this.actions.length
        return `${width}%`
      },

      permissionGroups() {
        return (new PermissionActionGroupCollection(this.permissions)).groups()
      },

      display() {
        return this.permissions.length > 0
      },
    },

    methods: {
      actionName(action) {
        const p = new Permission({
          target_type: this.permissions[0].target_type,
          action: action,
        })
        return p.actionName()
      },
    },
  }
</script>
