<template>
  <div
    class="wb-multiselect"
    :class="classNames"
  >
    <Multiselect
      v-model="localValue"
      :allow-empty="allowEmpty"
      :options="options"
      label="label"
      track-by="value"
      :multiple="multiple"
      :close-on-select="!multiple"
      :clear-on-select="false"
      :disabled="isDisabled"
      :group-values="groupValues"
      group-label="label"
      :group-select="selectAll"
      :searchable="enableSearchField"
      :taggable="false"
      :placeholder="placeholderValue"
      @input="handleInput"
    >
      <template
        v-slot:selection="slotProps"
      >
        <span
          v-if="slotProps.values.length && !slotProps.isOpen"
          class="multiselect__single"
        >
          {{ getSelectedText(slotProps.values) }}
        </span>
      </template>
    </Multiselect>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  const VARIANTS = ['button']

  export default {
    name: 'wb-multiselect',
    components: {
      Multiselect,
    },

    props: {
      allowEmpty: {
        type: Boolean,
        default: true,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      selectOptions: {
        type: Array,
        required: true,
        default: () => [],
      },

      value: {
        type: [Number, String, Array],
        default: () => [],
      },

      // Includes field to search for options
      enableSearchField: {
        type: Boolean,
        default: false,
      },

      selectAll: {
        type: Boolean,
        default: false,
      },

      selectAllText: {
        type: String,
        default: 'Select All',
      },

      multiple: {
        type: Boolean,
        default: true,
      },

      placeholder: {
        type: String,
      },

      resetOnSelect: {
        type: Boolean,
        default: false,
      },

      variant: {
        type: String,
        validator: value => {
          return VARIANTS.includes(value)
        },
      },
    },

    data () {
      return {
        localValue: [],
      }
    },

    computed: {
      classNames () {
        const classNames = []

        if (this.multiple) {
          classNames.push('wb-multiselect-multi')
        }
        if (this.variant === 'button') {
          classNames.push('wb-multiselect-btn')
        }
        if (this.enableSearchField) {
          classNames.push('wb-multiselect-searchable')
        }
        return classNames
      },
      groupValues () {
        return this.selectAll ? 'options' : null
      },
      options () {
        return this.selectAll ?
          this.selectAllOptions :
          this.selectOptions
      },
      selectAllOptions () {
        return [{
          label: this.selectAllText,
          options: this.selectOptions,
        }]
      },
      isDisabled() {
        return this.disabled || !this.selectOptions || this.selectOptions.length === 0
      },
      placeholderValue () {
        return this.isDisabled ? null : this.placeholder
      },
    },

    watch: {
      value: {
        handler() {
          this.setLocalValue()
        },
        deep: true,
      },
    },

    created () {
      this.setLocalValue()
    },

    methods: {
      getSelectedText (values) {
        const options = values.length === 1 ? 'option' : 'options'
        return `${values.length} ${options} selected`
      },

      getMultiValueToEmit(options) {
        return options.map(option => option.value)
      },

      getSingleValueToEmit(option) {
        return option && option.value
      },

      handleInput (values) {
        const value = this.multiple ?
          this.getMultiValueToEmit(values) :
          this.getSingleValueToEmit(values)
        this.$emit('input', value)

        if (this.resetOnSelect) {
          this.localValue = []
        }
      },
      setLocalValue () {
        this.localValue = this.selectOptions.filter(option => {
          return this.multiple ?
            this.value.includes(option.value) :
            this.value === option.value
        })
      },
    },
  }
</script>
