<template>
  <div>
    <PageHeader
      title="ADP® Workforce Now"
    />
    <div
      v-if="isEnabled"
      ref="enabled"
      class="panel gray-bg"
    >
      <a
        v-if="hasIntegration"
        ref="viewLink"
        :href="settingsPath"
      >
        View/Update your ADP® Integration
      </a>
      <LaddaButton
        v-else
        ref="createButton"
        class="btn btn-primary"
        @click="createAdpIntegration"
      >
        Start your ADP® Integration
      </LaddaButton>
    </div>
    <div
      v-else
      ref="disabled"
    >

      <div class="row">
        <div class="col-md-7">
          <h3 class="feature-list-section-title">What is ADP® Workforce Now?</h3>
          <p>
            Create employees and send W4 and payroll data to ADP Workforce Now by integrating your WorkBright account. This integration is billed and contracted with your ADP account. By submitting your email and clicking “Add ADP to Account” you are simply notifying WorkBright to enable the integration and will be instructed with how to purchase the integration from the ADP Marketplace.
          </p>
          <h3 class="feature-list-section-title">What does the integration include?</h3>
          <ul>
            <li class="feature-list-item">
              <div class="feature-list-item__icon">
                <WbIcon
                  iconSet="a4s-icon"
                  type="hiring"
                />
              </div>
              <span class="feature-list-item__message">
                Send demographic data from the individual WorkBright profile to the staff profile in ADP.
              </span>
            </li>
            <li class="feature-list-item">
              <div class="feature-list-item__icon">
                <WbIcon
                  iconSet="a4s-icon"
                  type="webinar"
                />
              </div>
              <span class="feature-list-item__message">
                Single Sign On (SSO) to your ADP Workforce Now account from WorkBright.
              </span>
            </li>
            <li class="feature-list-item">
              <div class="feature-list-item__icon">
                <WbIcon
                  iconSet="a4s-icon"
                  type="quality"
                />
              </div>
              <span class="feature-list-item__message">
                Send Federal W4 data to the staff profile in ADP
              </span>
            </li>
            <li class="feature-list-item">
              <div class="feature-list-item__icon">
                <WbIcon
                  iconSet="a4s-icon"
                  type="time-management"
                />
              </div>
              <span class="feature-list-item__message">
                Send payroll data including pay rate, type, frequency and direct deposit info to the staff profile in ADP.
              </span>
            </li>
            <li class="feature-list-item">
              <div class="feature-list-item__icon">
                <WbIcon
                  iconSet="a4s-icon"
                  type="brief"
                />
              </div>
              <span class="feature-list-item__message">
                Select onboarding templates and company codes associated with your ADP account and select in WorkBright wizard to sync data.
              </span>
            </li>
          </ul>
          <p class="mt-3">
            <a
              href="https://workbright.com/why-us/flexible-integration-options/ADP/"
              target="_blank"
            >Click here</a> to learn more about the exact fields that transfer and watch a recorded demonstration of how it works here.
          </p>
          <h3 class="feature-list-section-title">
            Pricing &amp; Getting Started
          </h3>
          <p>
            Use the form to the right to have WorkBright enable the ADP® Workforce Now integration on your account. From there, you will receive notifications of how to purchase from ADP marketplace and configure on your account.
          </p>
          <h4 class="mt-3 d-inline mr-1">ADP Pricing</h4>(billed and added to your ADP Workforce Now account)
          <dl class="mt-3">
            <dt>0-500 Employees:</dt>
            <dd>$59/mo or $469/year (30% savings)</dd>
          </dl>
          <dl>
            <dt>501-1000 Employees:</dt>
            <dd>$159/mo or $1,336/year (30% savings)</dd>
          </dl>
          <dl>
            <dt>1000+ Employees:</dt>
            <dd>$249/mo or $2,092/year (30% savings)</dd>
          </dl>
        </div>
        <div class="col-md-5">
          <div class="panel gray-bg">
            <iframe
              src="https://workbrightsupport.com/adp-add-on-request-form/?test"
              height="400"
              frameborder="0"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import PageHeader from 'components/common/PageHeader'
  import WbIcon from 'components/common/WbIcon'
  import LaddaButton from 'components/common/LaddaButton'
  import { ajax } from 'jquery'
  import Util from 'lib/util'

  export default {
    name: 'the-adp-integration-root',

    components: {
      LaddaButton,
      PageHeader,
      WbIcon,
    },

    props: {
      integration: {
        type: Object,
      },
      isEnabled: {
        type: Boolean,
      },
    },

    computed: {
      hasIntegration() {
        return !!this.integrationId
      },
      integrationId () {
        return this.integration?.id
      },
      settingsPath () {
        return this.$routes.integration_path({ id: this.integrationId})
      },
    },

    methods: {
      createAdpIntegration () {
        this.$refs.createButton.start()
        ajax({
          method: 'POST',
          url: this.$routes.integrations_path,
          data: {
            integration_type: 'adp',
          },
        }).success((xhr) => {
          if (xhr.integration && xhr.integration.id) {
            Util.navigateAndShowFlashNotice(this.$routes.integration_path({ id: xhr.integration.id}), 'ADP® Integration process successfully started')
          } else {
            this.handleCreateFail(xhr)
          }
        }).fail(xhr => {
          this.handleCreateFail(xhr)
        })
      },
      handleCreateFail (xhr) {
        this.$refs.createButton.stop()
        Util.genericAjaxError("An error occurred.", xhr)
      },
    },
  }
</script>
