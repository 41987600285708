<template>
  <div class="form-group form-group-vertical clearfix">
    <hr />
    <div class="d-flex align-items-center">
      <h3
        ref="header"
        class="mr-3"
      >
        Preparer/Translator
        <span
          v-if="shouldShowIndex"
          ref="headerIndex"
        >
          {{humanizedIndex}}
        </span>
      </h3>
      <button
        v-if="shouldShowRemoveLink"
        ref="preparer-remove-link"
        class="btn btn-link btn-decoration-none"
        @click="handleRemovePreparer"
      >
        <span class="text-danger">
          <WbIcon
            type="trash"
          />
          Remove
        </span>
      </button>
    </div>
    <p>{{ $locale.t('staff.i9.preparerTranslator.description') }}</p>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-3">
          <FormFieldInput
            ref="first_name"
            v-model="localData.first_name"
            label="First Name"
            :required="true"
            :errors="displayedErrors"
            class="pr-2"
            name="first_name"
            type="text"
            @input="handleFormUpdate"
          />
        </div>
        <div class="col-xs-12 col-sm-2">
          <FormFieldInput
            ref="middle_initial"
            v-model="localData.middle_initial"
            label="Middle Initial"
            :required="false"
            :errors="displayedErrors"
            class="pr-2"
            name="middle_initial"
            type="text"
          />
        </div>
        <div class="col-xs-12 col-sm-4">
          <FormFieldInput
            ref="last_name"
            v-model="localData.last_name"
            label="Last Name"
            :required="true"
            :errors="displayedErrors"
            class="w-30"
            name="last_name"
            type="text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-7">
          <FormFieldInput
            ref="address"
            v-model="localData.address"
            label="Address"
            :required="true"
            :errors="displayedErrors"
            name="address"
            type="text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-3">
          <FormFieldInput
            ref="city"
            v-model="localData.city"
            label="City or Town"
            :required="true"
            :errors="displayedErrors"
            class="pr-2"
            name="city"
            type="text"
          />
        </div>
        <div class="col-xs-12 col-sm-2">
          <FormFieldSelect
            ref="state"
            v-model="localData.state"
            label="State"
            :required="true"
            :errors="displayedErrors"
            :options="usStates"
            fieldWidth=""
            class="pr-2"
            name="state"
          />
        </div>
        <div class="col-xs-12 col-sm-2">
          <FormFieldInput
            ref="zipCode"
            v-model="localData.zip"
            label="Zip Code"
            :required="true"
            :errors="displayedErrors"
            name="zip"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="callout-legal">
      <p>{{ perjuryStatement }}</p>
    </div>

    <div name="signature">
      <SignaturePad
        ref="signaturePad"
        :value="{ ...localData.signature, signature_name: this.fullName }"
        :nameField="preparer.signature_name_field"
        :errors="displayedErrors"
        @input="handleSignatureUpdate"
      />
    </div>
  </div>
</template>

<script>
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import WbIcon from 'components/common/WbIcon'
  import SignaturePad from 'components/common/SignaturePad'
  import DateTime from 'lib/util/datetime'
  import moment from 'moment'

  const FIELDS_TO_SKIP_VALIDATION = ['errors', 'submitted_at', 'signature_name_field']

  export default {
    name: 'i9-preparer-form-fields',
    components: {
      FormFieldInput,
      FormFieldSelect,
      SignaturePad,
      WbIcon,
    },

    props: {
      preparer: {
        type: Object,
        required: true,
      },
      usStates: {
        type: Array,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      showErrors: {
        type: Boolean,
        required: true,
      },
      shouldShowIndex: {
        type: Boolean,
        default: true,
      },
      shouldShowRemoveLink: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        errors: {},
        localData: {},
        perjuryStatement: this.$locale.t('document_submissions.supplementA.perjuryStatement'),
        requiredError: ['This field is required'],
      }
    },

    computed: {
      fullName() {
        return [this.localData.first_name, this.localData.last_name].filter(Boolean).join(' ')
      },

      humanizedIndex() {
        return this.index + 1
      },

      displayedErrors() {
        return this.showErrors ? this.errors : {}
      },
    },

    watch: {
      localData: {
        deep: true,
        handler () {
          this.clearErrors()
          this.validate()
          this.handleFormUpdate()
        },
      },
    },

    created () {
      this.localData = {
        ...this.preparer,
      }
    },

    methods: {
      handleFormUpdate() { 
        this.$emit('update-preparer', {
          ...this.localData,
          submitted_at: this.getTimestamp(),
          errors: this.errors,
        })
      },

      handleRemovePreparer() {
        this.$emit('remove-preparer', this.index)
      },

      handleSignatureUpdate (signature) {
        this.localData.signature = signature
      },

      getTimestamp () {
        return moment.tz(DateTime.timezoneIANA()).format()
      },

      // Check each field for validation errors and add them to the 'errors' property on the preparer.
      // For now, we're just validating that every required field has a value.
      validate() {
        Object.entries(this.localData).forEach(([key, value]) => {
          // Skip 'errors', 'signature_name_field', and 'middle_initial' because they don't need to be validated.
          if (FIELDS_TO_SKIP_VALIDATION.includes(key)) {
            return
          } else if (key === 'middle_initial') {
            if (value.length > 1) {
              this.errors = { ...this.errors, middle_initial: ["Only 1 letter allowed"] }
            }
          // Handle signatures separately because the signature fields are objects instead of strings and
          // the error names (signature_data_uri, supplement_a_signature_name) are different than the field names.
          } else if (key === 'signature') {
            if (!value.signature) {
              this.errors = { ...this.errors, signature_data_uri: this.requiredError }
            }
            if (!value.signature_name) {
              this.errors = { ...this.errors, [this.localData.signature_name_field.name]: this.requiredError }
            }
          // For all other fields, if the value is missing, add a validation error.
          } else if (!value) {
            this.errors = { ...this.errors, [key]: this.requiredError }
          }
        })
      },

      clearErrors() {
        this.errors = {}
      },
    },
  }
</script>
