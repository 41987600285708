<template>
  <div class="p-2">
    <div
      v-for="(document, index) in documentationSet"
      :key="index"
    >
      <h5 :ref="`documentTitle${index}`">{{ document.document_title }}</h5>
      <FileViewer
        :ref="`supportingDocumentationFileViewer${index}`"
        :fileUrl="document.file_front_url"
      />
      <br />
      <FileViewer
        v-if="hasDocumentBack(document)"
        :ref="`supportingDocumentationFileViewerBack${index}`"
        :fileUrl="document.file_back_url"
      />
    </div>
  </div>
</template>

<script>
  import FileViewer from 'components/common/FileViewer'

  export default {
    name: 'supporting-documentation',

    components: {
      FileViewer,
    },

    props: {
      documentationSet: {
        type: Array,
        required: true,
      },
    },

    methods: {
      hasDocumentBack(document) {
        return !!document.file_back_url
      },
    },
  }
</script>
