<template>
  <div>
    <BackButton
      label="View Past Updates"
      :url="viewPastUpdatesPath"
    />
    <PageHeader
      title="Bulk Staff Update"
    />
    <BulkStaffUpdateWizard
      :bulk-staff-change="bulkStaffChange"
    />
  </div>
</template>
<script>
  import BulkStaffUpdateWizard from 'components/user_updates/BulkStaffUpdateWizard'
  import PageHeader from 'components/common/PageHeader'
  import BackButton from 'components/common/BackButton'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'the-user-updates-root',
    components: {
      BackButton,
      BulkStaffUpdateWizard,
      PageHeader,
    },
    provide() {
      return {
        fields: this.fields,
      }
    },
    props: {
      fields: {
        type: Array,
        required: true,
      },
      bulkStaffChange: {
        type: Object,
        default: null,
      },
    },
    computed: {
      viewPastUpdatesPath() {
        return getRoute('user_updates_path')
      },
    },
  }
</script>
