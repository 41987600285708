<template>
  <div class="wb-card wb-card--upsell">
    <div class="wb-card__title">
      Remote I-9 is now available
    </div>
    <div class="wb-card__body mt-3">
      <ul>
        <li>Enables 100% remote completion of onboarding paperwork before a new hire's first day</li>
        <li>Eliminate time-consumptive administrative resolution of common data entry errors</li>
        <li>100% federally-compliant process</li>
      </ul>
      <a
        class="btn btn-primary mt-2"
        :href="$routes.remote_i9_account_path"
      >
        Learn more about Remote I-9
      </a>
    </div>
  </div>
</template>
