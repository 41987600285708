<template>
  <SlideoutPanel
    watchPageContextVariable="show_staff_list_slideout"
    @modalHidden="clearViewSelections"
  >
    <template
      v-if="employee"
      #title
    >
      <h3 class="modal-title">
        {{ employeeFullName }}
        <br />
        <small
          v-if="!emailBlocked"
          class="email"
        >
          {{ employee.email }}
        </small>
        <small
          v-if="emailBlocked"
          v-html="blockedContent"
        />
        <br />
        <small>
          <a :href="employeePath">View Staff Profile</a>
        </small>
      </h3>
    </template>
    <div v-if="!finishedLoading">
      <p class="text-center">Loading Employee Data.</p>
      <Spinner :showLongWaitMessage="true" />
    </div>
    <div v-if="finishedLoading">
      <RadioToggle
        :title="filter.title"
        :value="currentSlideoutView"
        :options="filter.options"
        buttonSize="btn-sm"
        @selected="handleSlideoutViewSelection"
      />
      <hr />
      <div v-if="displayGroups">
        <StaffListSlideoutGroupsContainer :groups="groups" />
      </div>
      <div v-if="displayForms">
        <StaffListSlideoutDocumentAssignmentsContainer
          v-if="displayNonInternalForms"
          title="Staff Forms"
          :initialStatusView="initialFormsStatusView"
          :documentAssignments="nonInternalDocumentAssignments"
        />
        <hr v-if="displayNonInternalForms && displayInternalForms" />
        <StaffListSlideoutDocumentAssignmentsContainer
          v-if="displayInternalForms"
          title="Admin Forms"
          :initialStatusView="initialFormsStatusView"
          :documentAssignments="internalDocumentAssignments"
        />
      </div>
    </div>
  </SlideoutPanel>
</template>

<script>
  import SlideoutPanel from 'components/common/SlideoutPanel'
  import Auth from 'lib/util/auth'
  import Spinner from 'components/common/Spinner'
  import RadioToggle from 'components/common/RadioToggle'
  import StaffListSlideoutGroupsContainer from 'components/admin/staff_list/StaffListSlideoutGroupsContainer'
  import StaffListSlideoutDocumentAssignmentsContainer from 'components/admin/staff_list/StaffListSlideoutDocumentAssignmentsContainer'

  export default {
    name: 'staff-list-slideout',

    components: {
      SlideoutPanel,
      Spinner,
      RadioToggle,
      StaffListSlideoutGroupsContainer,
      StaffListSlideoutDocumentAssignmentsContainer,
    },

    props: {
      employee: {
        type: Object,
      },
      initialSlideoutView: {
        type: String,
        default: "groups",
      },
      initialFormsView: {
        type: String,
        default: "all",
      },
      initialFormsStatusView: {
        type: String,
        default: "all",
      },
      groups: {
        type: Array,
      },
      documentAssignments: {
        type: Array,
      },
    },

    data() {
      return {
        conditionalPrefix: "No ",
        filter: {
          title: '',
          filter_key: '',
          defaultValue: 'groups',
          options: [
            { value: 'groups', label: 'Assigned Groups' },
            { value: 'forms', label: 'Required Forms' },
          ],
        },
        selectedSlideoutView: null,
        selectedFormsView: null,
        navLinks: [
          { value: "all", label: "All" },
          { value: "non-internal", label: "Staff Forms" },
          { value: "internal", label: "Admin Forms" },
        ],
      }
    },


    computed: {
      employeeFullName() {
        return `${this.employee.first_name} ${this.employee.last_name}`
      },

      emailBlocked(){
        return Auth.isBlockedContent(this.employee.email)
      },

      blockedContent(){
        return Auth.blockedContentTag()
      },

      employeePath() {
        return this.$routes.employee_path({ id: this.employee.id }) || void(0)
      },

      finishedLoading(){
        return this.groups != null && this.documentAssignments != null
      },

      currentSlideoutView(){
        return this.selectedSlideoutView || this.initialSlideoutView
      },

      displayGroups(){
        return this.currentSlideoutView == "groups"
      },

      displayForms(){
        return this.currentSlideoutView == "forms"
      },

      currentFormsView(){
        return this.selectedFormsView || this.initialFormsView
      },

      displayNonInternalForms(){
        return this.currentFormsView == "non-internal" || this.currentFormsView == "all"
      },

      displayInternalForms(){
        return this.currentFormsView == "internal" || this.currentFormsView == "all"
      },

      nonInternalDocumentAssignments(){
        return this.documentAssignments.filter(da => !da.internal_only)
      },

      internalDocumentAssignments(){
        return this.documentAssignments.filter(da => da.internal_only)
      },
    },

    methods: {
      handleSlideoutViewSelection(selection){
        this.selectedSlideoutView = selection
      },

      handleFormsViewSelection({selection}){
        this.selectedFormsView = selection
      },

      clearViewSelections(){
        this.selectedSlideoutView = null
        this.selectedFormsView = null
      },
    },
  }
</script>
