<template>
  <a
    v-popover="popoverData"
    class="help-popover"
    href="javascript:void(0)"
    role="button"
    tabindex="0"
  >
    <slot v-if="hasSlotContent" />
    <WbIcon
      v-else
      type="question-circle"
    />
  </a>
</template>
<script>
  const DEFAULT_TRIGGER = 'focus'
  import WbIcon from 'components/common/WbIcon'

  export default {
    components: {
      WbIcon,
    },
    props: {
      content: {
        type: String,
      },
      title: {
        type: String,
      },
      placement: {
        type: String,
        default: 'auto top',
      },
      trigger: {
        type: String,
        default: DEFAULT_TRIGGER,
      },
    },
    computed: {
      hasSlotContent() {
        return this.$slots.default
      },
      popoverData () {
        return {
          html: true,
          placement: this.placement,
          title: this.title,
          content: this.content,
          trigger: this.trigger,
        }
      },
    },
  }
</script>
