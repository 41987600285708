<template>
  <div>
    <PageHeader
      title="Update Your Staff"
      lead="Bulk update staff from an Excel Spreadsheet or CSV file"
    />

    <div class="d-flex justify-content-between align-items-end">
      <div class="btn-group my-3">
        <a
          class="btn btn-primary"
          :href="newUserPath"
        >
          <WbIcon type="plus-circle" />
          New Bulk Update
        </a>
      </div>
    </div>

    <Spinner
      v-if="isLoading"
      ref="loader"
    />
    <template v-else-if="hasSufficientData">
      <UserUpdatesTable
        ref="table"
        :bulkUpdates="bulkUpdates"
      />
      <WbListPaginationFooter
        ref="pagination"
        :previousPage="pagination.previous_page"
        :nextPage="pagination.next_page"
        :lastPage="pagination.total_pages"
        :totalItems="pagination.total_items"
        :indexStart="pagination.index_start"
        :indexEnd="pagination.index_end"
        @page-change="handlePageChange"
      />
    </template>
    <AlertMessage
      v-else
      ref="noData"
      title="No Bulk Updates have been created"
      variant="info"
    >
      <p>
        <a :href="newUserPath">Click here</a> to update your Staff.
      </p>
    </AlertMessage>
  </div>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import PageHeader from 'components/common/PageHeader'
  import Spinner from 'components/common/Spinner'
  import UserUpdatesTable from 'components/user_updates/UserUpdatesTable'
  import WbIcon from 'components/common/WbIcon'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import { getRoute } from 'lib/routes'
  import { fetchBulkStaffUpdates } from 'services/admin/BulkStaffUpdatesService'
  import Util from 'lib/util'

  export default {
    name: 'the-user-updates-index-root',
    components: {
      AlertMessage,
      PageHeader,
      Spinner,
      UserUpdatesTable,
      WbIcon,
      WbListPaginationFooter,
    },
    data () {
      return {
        bulkUpdates: null,
        isLoading: false,
        pagination: {},
        index_start: 0,
        index_end: 0,
      }
    },
    computed: {
      hasSufficientData () {
        return this.bulkUpdates?.length > 0
      },
      newUserPath () {
        return getRoute('new_user_update_path')
      },
    },
    created () {
      this.fetchData()
    },
    methods: {
      async fetchData (page = 1) {
        try {
          this.isLoading = true
          const { items, pagination } = await fetchBulkStaffUpdates(page)
          if (items?.length) {
            this.bulkUpdates = items
            this.pagination = pagination
          }
        } catch (error) {
          this.bulkUpdates = null
          this.pagination = {}
          Util.genericAjaxError('An error occured', error)
        } finally {
          this.isLoading = false
        }
      },
      handlePageChange (page) {
        this.fetchData(page)
      },
    },
  }
</script>
