<template>
  <div>
    <p
      v-if="!managedPolicy"
      class="breathe"
    >
      <i class="fa fa-info text-info fa-fw" />
      Groups selected: {{groupNames.join(', ')}}
    </p>
    <h4>
      Add each field you wish to customize. For any fields you don&apos;t add, we&apos;ll use the permissions you set on All other fields.
    </h4>
    <div class="breathe">
      <RepresentableFieldSelect v-on:select="add" />
    </div>
    <table class="table">
      <thead>
        <tr>
          <th width="35%">Field</th>
          <th width="20%">View</th>
          <th width="40%">Other Actions</th>
          <th width="5%">Remove</th>
        </tr>
      </thead>
      <tbody class="rule-table">
        <PermissionsCustomizeFieldRow
          v-for="fieldPermissions in groupedPermissions"
          :key="fieldPermissions.key"
          :permission-set="groupPermissionSet"
          :field-key="fieldPermissions.key"
          :permissions="fieldPermissions.permissions"
          v-on:remove="remove"
        />
      </tbody>
    </table>
    <p>
      <i class="fa fa-info text-info fa-fw" />
      You must give View access before you can configure any Other Actions.
    </p>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import RepresentableFieldSelect from 'components/common/RepresentableFieldSelect'
  import PermissionsCustomizeFieldRow from 'components/permissions/PermissionsCustomizeFieldRow'

  import StepProfileFieldsCustomizePresenter from 'presenters/policy_generator/step_profile_fields_customize_presenter'

  export default {
    name: 'step-profile-fields-customize',

    components: {
      PermissionsCustomizeFieldRow,
      RepresentableFieldSelect,
    },
    extends: BaseStep,

    props: {
      managedPolicy: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        groupPermissionSet: null,
        presenter: null,
      }
    },

    computed: {
      groupedPermissions() {
        return this.presenter.groupedPermissions()
      },
    },

    created() {
      this.groupPermissionSet = this.policy.groupPermissionSet()
      this.presenter = new StepProfileFieldsCustomizePresenter(this.groupPermissionSet)
    },

    mounted() {
      const matchingTemplate = this.presenter.matchingTemplate()
      if (matchingTemplate) { this.$emit('skip') }
    },

    methods: {
      add(field) {
        this.presenter.addPermission(field)
      },

      remove(fieldKey) {
        if (fieldKey) {
          this.presenter.removePermission(fieldKey)
        }
      },
    },
  }
</script>
