<template>
  <div>
    <PageHeader
      title="Downloads"
      lead="Your requested downloads"
    />
    <div class="panel profile">
      <Spinner v-if="isLoading" />
      <div
        v-else-if="hasDownloads"
        ref="downloads-content"
      >
        <ul class="list-group">
          <li
            v-for="(downloadRequest, index) in downloadRequests"
            :key="index"
            class="list-group-item"
          >
            <DownloadRequest
              :downloadRequest="downloadRequest"
              @download="handleDownload"
            />
          </li>
        </ul>
      </div>
      <div
        v-else
        ref="default-content"
        class="text-center"
      >
        <em>You currently do not have any downloads available.</em>
      </div>
    </div>
    <DownloadSlideoutPanel
      :downloadRequest="selectedDownloadRequest"
    />
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import DownloadRequest from 'components/admin/downloads/DownloadRequest'
  import DownloadSlideoutPanel from 'components/admin/downloads/DownloadSlideoutPanel'
  import Spinner from 'components/common/Spinner'
  import PageHeader from 'components/common/PageHeader'

  export default {
    name: 'the-admin-downloads-root',
    components: {
      DownloadRequest,
      DownloadSlideoutPanel,
      PageHeader,
      Spinner,
    },
    data () {
      return {
        isLoading: false,
        selectedDownloadRequest: null,
      }
    },
    computed: {
      ...mapGetters({
        downloadRequests: 'admin_downloads/downloadRequests',
      }),
      hasDownloads () {
        return this.downloadRequests && this.downloadRequests.length
      },
    },
    created () {
      this.fetchData()
    },
    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
      ...mapActions({
        fetchDownloadRequests: 'admin_downloads/fetchDownloadRequests',
      }),
      handleDownload (downloadRequest) {
        this.selectedDownloadRequest = downloadRequest
        this.setPageContextKeys({ show_download_slideout_panel: downloadRequest.id })
      },
      fetchData () {
        this.isLoading = true
        this.fetchDownloadRequests()
          .catch(() => {}) // Fail silently?
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>
