<template>
  <WbModal
    ref="modal"
    title="Schedule Notifications"
    watchPageContextVariable="schedule_onboarding"
    :locked="$wait.is('schedule_onboarding')"
    @cancelled="resetErrors"
  >
    <slot>
      <div
        v-if="hasErrors"
        ref="errors"
        class="alert alert-danger"
      >
        Please make sure that all required staff information is saved before scheduling notifications.
        <dl
          v-for="[errorSource, error] in Object.entries(errors)"
          :key="errorSource"
          class="mt-1 mb-0"
        >
          <dt>{{errorSource}}</dt>
          <dd>
            <ul class="list-unstyled">
              <li
                v-for="(errorItem, index) in error"
                :key="index"
              >
                {{errorItem}}
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      <ScheduleOnboarding
        ref="scheduler"
        v-model="onboarding"
        :label="label"
      />
    </slot>
    <template #footer>
      <button
        class="btn btn-default"
        :disabled="$wait.is('schedule_onboarding')"
        @click="hide"
      >
        Cancel
      </button>
      <LaddaButton
        ref="submit"
        :disabled="!valid"
        @click="submit"
      >
        Submit
      </LaddaButton>
    </template>
  </WbModal>
</template>

<script>
  import { mapActions } from 'vuex'
  import Util from 'lib/util'
  import LaddaButton from 'components/common/LaddaButton'
  import ScheduleOnboarding from 'components/employees/ScheduleOnboarding'
  import WbModal from 'components/common/WbModal'

  export default {
    name: 'schedule-onboading-modal',

    components: {
      LaddaButton,
      ScheduleOnboarding,
      WbModal,
    },

    props: {
      employee: {
        type: Object,
        required: true,
      },

      employment: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        onboarding: { selection: null, date: null },
        errors: null,
      }
    },

    computed: {
      hasErrors () {
        return !!this.errors
      },

      label() {
        return `When would you like ${this.employee.first_name} to begin receiving notifications?`
      },

      valid() {
        return this.onboarding.selection == 'never' // Selected the null option
          || !!this.onboarding.date // Specified a date
      },
    },

    methods: {
      hide() {
        this.resetErrors()
        this.$refs.modal.hide()
      },

      resetErrors () {
        this.errors = null
      },

      submit() {
        this.resetErrors()
        this.$wait.start('schedule_onboarding')

        this.employmentUpdate({
          employment: this.employment,
          data: {
            onboarding_start_date: this.onboarding.date,
          },
        }).then(() => {
          Util.showFlashNotice("Updated the notifications date.")
          setTimeout(this.$refs.modal.hide) // Run after finally callback, which ends loading and unlocks the modal
        }).catch(xhr => {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors
          } else {
            Util.ajaxErrorDialog("An error occurred updating the notifications date.", xhr) // Fall back on generic, something went wrong error
          }
          this.$refs.submit.stop()
        }).finally(() => {
          this.$wait.end('schedule_onboarding')
        })
      },

      ...mapActions({
        employmentUpdate: 'employments/update',
      }),
    },
  }
</script>
