<template>
  <div
    v-if="hasValidFields"
    ref="container"
  >
    <UpdatePayrollModal
      :employeeId="employeeId"
      :internalFields="internalFields"
      :staffFields="staffFields"
      :disableInternalFields="adpInternalFieldsMapped"
      :disableStaffFields="adpStaffFieldsMapped"
    />
    <div class="panel panel-simple">
      <AlertMessage
        v-if="hasSyncError"
        ref="syncError"
        title="Sync Error"
        :message="$locale.t('integrations.adp.sync.syncError')"
        variant="warning"
        class="mb-0"
      />
      <div class="panel-heading with-action-buttons">
        <h3 class="panel-title">Payroll</h3>
        <div class="float-right d-flex">
          <WbButton
            class="pr-2"
            label="Edit"
            context="default"
            permissionModel="employee_seasons"
            :permissionRecordId="employment.id"
            permissionAction="manage"
            @click="openModal"
          >
            <template #prepend>
              <i class="fa fa-edit" />
            </template>
          </WbButton>
          <AdpIntegrationControls
            v-if="adpIntegrationActive"
            ref="adpControls"
            class="pl-2"
            :employeeId="employeeId"
          />
        </div>
      </div>
      <PayrollFieldsGroup :fields="internalFields">
        <template #caption>
          Internal Fields <small>(hidden from staff)</small>
        </template>
      </PayrollFieldsGroup>
      <PayrollFieldsGroup :fields="staffFields">
        <template #caption>
          Staff Fields  <small>(completed by staff)</small>
        </template>
      </PayrollFieldsGroup>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import UpdatePayrollModal from 'components/employees/payroll/UpdatePayrollModal'
  import WbButton from 'components/common/WbButton'
  import PayrollFieldsGroup from 'components/employees/payroll/PayrollFieldsGroup'
  import AdpIntegrationControls from 'components/employees/integrations/AdpIntegrationControls'
  import AlertMessage from 'components/common/AlertMessage'

  export default {
    name: 'payroll-fields-panel',
    components: {
      WbButton,
      PayrollFieldsGroup,
      UpdatePayrollModal,
      AdpIntegrationControls,
      AlertMessage,
    },

    props: {
      employeeId: {
        required: true,
        type: Number,
      },
      adpIntegrationActive: {
        type: Boolean,
      },
      adpInternalFieldsMapped: {
        type: Boolean,
        required: false,
      },
      adpStaffFieldsMapped: {
        type: Boolean,
        required: false,
      },
      adpSyncStatus: {
        type: String,
        required: false,
      },
    },

    computed: {
      hasValidFields () {
        return this.payrollFields && this.payrollFields.length
      },

      internalFields() {
        return this.sortFields(this.payrollFields.filter(f => f.internal))
      },

      // non-internal fields that are requested from staff
      staffFields() {
        return this.sortFields(this.payrollFields.filter(f => !f.internal))
      },

      employment() {
        return this.employmentsFindBy({ employee_id: this.employeeId })[0] || {}
      },

      hasSyncError() {
        return this.adpSyncStatus == "sync_error"
      },

      ...mapGetters({
        employmentsFindBy: 'employments/findBy',
        payrollFields: 'payroll_fields/employeePayrollFields',
      }),
    },

    methods: {
      openModal() {
        this.setPageContextKeys({ edit_payroll: true })
      },

      sortFields(fieldList) {
        return fieldList.sort((a, b) => a.position - b.position)
      },

      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
