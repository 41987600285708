<template>
  <section>
    <span
      v-if="showAuthRepData"
      ref="authRepData"
    >
      by {{ authRepName }}
      <br />
      IP address: {{ remoteIp }}
    </span>
    <span
      v-if="showPhoneNumber"
      ref="authRepPhoneNumber"
    >
      Phone: {{ authRepPhone }}
    </span>
  </section>
</template>

<script>
  export default {
    name: 'submission-audit-log-item-auth-rep-section',
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
    computed: {
      remoteIp () {
        return this.event.request.remote_ip
      },

      showPhoneNumber () {
        return this.event.name === 'employee_submit_auth_rep_phone'
      },

      authRepPhone () {
        return this.event.details.i9rc?.auth_rep_phone || this.event.details.i9_remote_reverify?.auth_rep_phone
      },

      authRepEvents () {
        return [
          'authorized_representative_submit_countersign',
          'authorized_representative_submit_reverify',
          'authorized_representative_submit_countersign_rejected',
          'authorized_representative_auth_rep_certify_identity',
          'authorized_representative_submit_reverify_rejected',
          'authorized_representative_reverify_certify_identity',
        ]
      },

      showAuthRepData () {
        return this.authRepEvents.includes(this.event.name)
      },

      authRepName () {
        return (
          this.event.details.i9rc?.auth_rep_signature.signature_name ||
          this.event.details.i9_remote_reverify?.auth_rep_name
        )
      },
    },
  }
</script>
