<template>
  <a
    class="btn btn-default btn-sm"
    :href="url"
  >
    <i class="fa fa-level-up fa-rotate-270" />
    {{ label }}
  </a>
</template>

<script>
  export default {
    name: 'back-button',

    props: {
      // Label to Display
      label: {
        type: String,
        required: false,
        default: "Back",
      },

      // URL to go to when clicked
      url: {
        type: String,
        required: true,
      },
    },
  }
</script>
