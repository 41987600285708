<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <form
        id="passkey-form"
        class="form-inline"
      >
        <div class="modal-header compact">
          <a
            class="close"
            data-dismiss="modal"
          >&times;</a>
          <h2>Enter Passkey</h2>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <div
              class="form-group" 
              :class="{ 'has-error': error }"
            >
              <input 
                id="passkey"
                v-model="passkey" 
                class="form-control input-lg passkey-input" 
                type="password" 
                name="passkey"
                autocorrect="off"
                autocapitalize="off"
                pattern="[0-9]*" 
                autofocus="autofocus"
                maxlength="6"
                @keydown.enter.prevent="submit"
              />
              <small
                v-show="error"
                class="help-block"
              >{{ error }}</small>
            </div>
          </div>
        </div>
        <div class="modal-footer compact">
          <div class="pull-right">
            <a
              class="btn btn-default"
              data-dismiss="modal"
            >Cancel</a>
            <LaddaButton
              ref="submit"
              class="btn btn-primary"
              @click="submit"
            >
              Submit
            </LaddaButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import LaddaButton from 'components/common/LaddaButton'
  import { isFunction } from 'underscore'

  export default {
    name: 'custom-fields-panel-view',

    components: {
      LaddaButton,
    },

    props: {
      command: {
        type: String,
        default: '#',
      },
      exit: {
        type: Number,
        default: 1,
      },
    },

    data() {
      return {
        passkey: null,
        error: null,
      }
    },

    computed: {
      emptyPasskeyError() { return 'No passkey entered!' },
      invalidPasskeyError() { return 'Invalid passkey!' },
    },

    methods: {
      submit() {
        if (this.passkey) {
          this.$refs.submit.start()

          $.ajax({
            method: 'POST',
            url: this.$routes.check_passkey_kiosk_index_path,
            data: {
              passkey: this.passkey,
              exit: this.exit,
            },
          }).success((json) => {
            if (json.valid) {
              this.error = null
              this.execCommandLater()
            } else {
              this.error = this.invalidPasskeyError
              this.$refs.submit.stop()
            }
          })
        } else {
          this.error = this.emptyPasskeyError
          this.$refs.submit.stop()
        }
      },
      execCommandLater() {
        setTimeout(() => { this.execCommand() }, 100)
      },
      execCommand() {
        if (isFunction(this.command)) {
          // Command is a callback function; call it
          this.command.call()
        } else {
          // Assume command is a URL
          window.location.href = this.command
        }
      },    
    },
  }
</script>
