<template>
  <div
    class="textarea"
    :class="{'has-error' : hasError }"
  >
    <div class="input-wrapper textarea">
      <label>
        <slot />
        {{ label }}
      </label>
      <textarea
        :id="name"
        ref="input"
        v-model="localValue"
        class="text form-control"
        :name="name"
        :placeholder="placeholder"
        @input="$emit('input', localValue)"
      />
    </div>
    <small
      v-if="hasError"
      class="help-block"
    >
      {{ errorMessage }}
    </small>
    <small
      v-if="hint"
      class="help-block"
    >
      {{ hint }}
    </small>
  </div>
</template>

<script>
  import FormField from 'components/common/FormField'

  export default {
    name: 'form-field-text-area',
    mixins: [FormField],
    props: {
      value: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        localValue: undefined,
      }
    },
    watch: {
      value () {
        this.localValue = this.value
      },
    },
    created () {
      this.localValue = this.value
    },
  }
</script>
