<!-- // Slideout panel for displaying E-Verify case details -->
<template>
  <div>
    <SlideoutPanelExtended
      :watchPageContextVariable="showSlideoutPanelExtended"
      size="xl"
    >
      <template #extended-content>
        <component
          :is="extendedComponent"
          v-if="currentId && extendedComponent"
          :everifyCase="everifyCase"
          :caseEmployee="caseEmployee"
          :allowDownload="allowDownload"
          @close="closePanel"
        />
      </template>

      <template
        v-if="currentId"
        #primary-title
      >
        <EverifyCasePanelTitle
          :employeeName="employeeFullName"
        />
      </template>
      <template #primary-content>
        <div class="slideout-content-region">
          <EverifyCasePanelContent
            v-if="currentId"
            :currentId="currentId"
            :everifyCase="everifyCase"
            :caseEmployee="caseEmployee"
          />
        </div>
      </template>
    </SlideoutPanelExtended>

    <SlideoutPanel
      :watchPageContextVariable="showSlideoutPanel"
    >
      <template
        v-if="currentId"
        #title
      >
        <EverifyCasePanelTitle
          :employeeName="employeeFullName"
        />
      </template>
      <EverifyCasePanelContent
        v-if="currentId"
        :currentId="currentId"
        :everifyCase="everifyCase"
        :caseEmployee="caseEmployee"
      />
    </SlideoutPanel>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex"
  import * as mutate from 'vuex/mutation_types'
  import SlideoutPanel from "components/common/SlideoutPanel"
  import SlideoutPanelExtended from "components/common/SlideoutPanelExtended"
  import PhotoMatchingPanel from "components/everify/cases/PhotoMatchingPanel"
  import ReferredPanel from 'components/everify/cases/ReferredPanel'
  import FinalNonconfirmationPanel from 'components/everify/cases/FinalNonconfirmationPanel'
  import CloseCaseAndResubmitPanel from 'components/everify/cases/CloseCaseAndResubmitPanel'
  import UnconfirmedDataPanel from "components/everify/cases/UnconfirmedDataPanel"
  import ScanAndUploadPanel from "components/everify/cases/ScanAndUploadPanel"
  import PendingReferralPanel from "components/everify/cases/PendingReferralPanel"
  import MissingDuplicateContinueReasonPanel from 'components/everify/cases/MissingDuplicateContinueReasonPanel'
  import EverifyCasePanelTitle from 'components/everify/cases/EverifyCasePanelTitle'
  import EverifyCasePanelContent from 'components/everify/cases/EverifyCasePanelContent'
  import { EVERIFY_STATUSES } from 'components/admin/dashboard/EverifyCaseStatusConstants'

  const PANELS_BY_STATUS = {
    photo_match: PhotoMatchingPanel,
    referred: ReferredPanel,
    scan_and_upload: ScanAndUploadPanel,
    unconfirmed_data: UnconfirmedDataPanel,
    pending_referral: PendingReferralPanel,
    final_nonconfirmation: FinalNonconfirmationPanel,
    close_case_and_resubmit: CloseCaseAndResubmitPanel,
  }

  const SUBMISSION_ERROR_PANELS = {
    missing_duplicate_continue_reason: MissingDuplicateContinueReasonPanel,
  }

  export default {
    name: "everify-case-slideout",
    components: {
      SlideoutPanel,
      SlideoutPanelExtended,
      PhotoMatchingPanel,
      UnconfirmedDataPanel,
      ScanAndUploadPanel,
      PendingReferralPanel,
      FinalNonconfirmationPanel,
      CloseCaseAndResubmitPanel,
      MissingDuplicateContinueReasonPanel,
      EverifyCasePanelTitle,
      EverifyCasePanelContent,
    },

    props: {
      everifyCase: {
        type: Object,
      },
      allowDownload: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      // This is WB's EverifyCase id, not the case number assigned by E-Verify
      currentId() {
        return this.everifyCase?.id
      },

      caseEmployee() {
        return this.getEmployee(this.everifyCase.employee_id)
      },

      duplicateContinueReasonRequired() {
        return this.everifyCase.duplicate_continue_reason_required
      },

      employeeFullName() {
        return this.caseEmployee ?
          `${this.caseEmployee.first_name} ${this.caseEmployee.last_name}` : ''
      },

      showSlideoutPanel() {
        return this.showExtendedSlideout ? "" : "everify_case_id"
      },

      showSlideoutPanelExtended() {
        return this.showExtendedSlideout ? "everify_case_id" : ""
      },

      showExtendedSlideout() {
        return EVERIFY_STATUSES.ACTION_REQUIRED_STATES.includes(this.currentStatus) || EVERIFY_STATUSES.ACTION_REQUIRED_ERRORS.includes(this.submissionErrorType)
      },

      currentStatus() {
        if (!this.currentId) { return null }
        return this.everifyCase.case_status
      },

      submissionErrorType() {
        if (!this.currentId) { return null }
        return this.everifyCase.submission_error_type
      },

      extendedComponent() {
        return PANELS_BY_STATUS[this.currentStatus] || SUBMISSION_ERROR_PANELS[this.submissionErrorType]
      },

      ...mapGetters({
        getEmployee: "employees/get",
      }),
    },

    watch: {
      everifyCase() {
        if (!this.everifyCase) {
          this.closePanel()
        }
      },
    },

    methods: {
      closePanel() {
        this.deletePageContextKey('everify_case_id')
      },

      ...mapMutations({
        deletePageContextKey: mutate.DELETE_PAGE_CONTEXT_KEY,
      }),
    },
  }
</script>
