<template>
  <ul class="nav navbar-nav navbar-right">
    <li>
      <div
        id="access-expiration-banner"
        class="hidden"
      />
    </li>
    <li ref="add-staff-member">
      <div
        v-if="isBulkUpdatesEnabled"
        ref="quickStaffActions"
        class="btn-group btn-group-primary navbar-quick-add"
      >
        <WbButton
          class="btn btn-sm btn-primary dropdown-toggle"
          data-toggle="dropdown"
        >
          Quick Staff Actions
          <WbIcon
            type="caret-down"
            class="fa-fw right"
          />
        </WbButton>
        <ul class="dropdown-menu">
          <SecondaryNavItem
            v-if="addStaffItem"
            ref="addStaffItem"
            class="js-new-employee"
            :item="addStaffItem"
          />
          <SecondaryNavItem
            v-if="userImportItem"
            ref="userImportItem"
            :item="userImportItem"
          />
          <SecondaryNavItem
            v-if="userUpdateItem"
            ref="userUpdateItem"
            :item="userUpdateItem"
          />
        </ul>
      </div>
      <div
        v-else
        ref="addStaffMenu"
        class="btn-group btn-group-primary navbar-quick-add"
      >
        <a
          class="js-new-employee btn btn-sm btn-primary"
          :href="addStaffRoute"
        >
          Add Staff Member
        </a>
        <button
          class="btn btn-sm btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-label="More Options"
        >
          <WbIcon
            type="caret-down"
            class="fa-fw right"
          />
        </button>
        <ul class="dropdown-menu">
          <SecondaryNavItem
            v-if="userImportItem"
            ref="userImportItem"
            :item="userImportItem"
          />
        </ul>
      </div>
    </li>
    <li class="dropdown">
      <button
        class="btn btn-link navbar-btn"
        data-toggle="dropdown"
      >
        <WbIcon
          type="user-circle"
          class="fa-fw"
        />
        <span>{{ userFirstName }}</span>
        <WbIcon
          type="caret-down"
          class="fa-fw"
        />
      </button>
      <ul class="dropdown-menu dropdown-menu-pointy">
        <li class="dropdown-header">
          Signed in as
          <strong>{{ currentUser.name }}</strong>
        </li>
        <li class="dropdown-header">
          {{ currentUser.email }}
        </li>
        <li class="divider" />
        <SecondaryNavItem
          v-if="profileItem"
          ref="profileItem"
          :item="profileItem"
        >
          <template #icon>
            <WbIcon
              type="user"
              class="fa-fw"
            />
          </template>
        </SecondaryNavItem>
        <SecondaryNavItem
          v-if="downloadsItem"
          ref="downloadsItem"
          :item="downloadsItem"
        >
          <template #icon>
            <WbIcon
              type="download"
              class="fa-fw"
            />
          </template>
        </SecondaryNavItem>
        <template v-if="kioskItem">
          <li class="divider" />
          <SecondaryNavItem
            ref="kioskItem"
            :item="kioskItem"
          >
            <template #icon>
              <WbIcon
                type="kiosk"
                class="fa-fw"
                iconSet="a4s-icon"
              />
            </template>
          </SecondaryNavItem>
        </template>
        <li class="divider" />
        <SecondaryNavItem
          v-if="helpItem"
          ref="helpItem"
          :item="helpItem"
          target="_blank"
        >
          <template #icon>
            <WbIcon
              type="question-circle"
              class="fa-fw"
            />
          </template>
        </SecondaryNavItem>
        <SecondaryNavItem
          v-if="announcementsItem"
          ref="announcementsItem"
          :item="announcementsItem"
        >
          <template #icon>
            <WbIcon
              type="bullhorn"
              class="fa-fw"
            />
          </template>
        </SecondaryNavItem>
        <li class="divider" />
        <SecondaryNavItem
          v-if="switchAccountItem"
          ref="switchAccountItem"
          :item="switchAccountItem"
        >
          <template #icon>
            <WbIcon
              type="exchange"
              class="fa-fw"
            />
          </template>
        </SecondaryNavItem>
        <li>
          <AppSignoutLink />
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
  import AppSignoutLink from 'components/navbar/AppSignoutLink'
  import SecondaryNavItem from 'components/navbar/SecondaryNavItem'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'admin-navbar-nav',
    components: {
      AppSignoutLink,
      SecondaryNavItem,
      WbButton,
      WbIcon,
    },
    props: {
      currentAccount: {
        type: Object,
        default: () => ({}),
      },
      currentUser: {
        type: Object,
        default: () => ({}),
      },
      items: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      addStaffRoute () {
        return getRoute('new_employee_path')
      },
      announcementsItem () {
        return this.items.announcements
      },
      downloadsItem () {
        return this.items.downloads
      },
      helpItem () {
        return this.items.help
      },
      isBulkUpdatesEnabled () {
        return this.currentAccount.userUpdatesEnabled
      },
      kioskItem () {
        return this.items.kiosk
      },
      profileItem () {
        return this.items.profile
      },
      signOutItem () {
        return this.items.sign_out
      },
      switchAccountItem () {
        return this.items.switch_organization
      },
      userFirstName () {
        return this.currentUser.first_name
      },
      userUpdateItem () {
        return this.items.user_update
      },
      userImportItem () {
        return this.items.user_import
      },
      addStaffItem () {
        return this.items.add_staff
      },
      bulkImportsRoute () {
        return getRoute('new_user_import_path')
      },
      bulkImportsListRoute () {
        return getRoute('user_imports_path')
      },
      bulkUpdatesRoute () {
        return getRoute('user_updates_path')
      },
    },
  }
</script>
