// Spinner (progress indicator)
// Originally sourced from: https://github.com/sergeyloysha/vue-spinner-component
// Modified for simplicity in WorkBright context. Choosing to bring this code in so that we maintain it ourselves versus
// third-party dependency.

<template>
  <div>
    <div
      class="spinner"
      :style="spinnerStyle"
    />
    <transition name="vue-fade">
      <div
        v-show="longWait"
        class="text-center"
      >This may take a few moments.</div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'spinner',
    props: {
      size: {
        type: Number,
        default: 33,
      },
      depth: {
        type: Number,
        default: 3,
      },
      speed: {
        type: Number,
        default: 0.75,
      },
      color: {
        type: String,
        default: '#6589b6',
      },
      // Show a message to keep waiting after a few seconds
      showLongWaitMessage: {
        type: Boolean,
        default: false,
      },
      longWaitMessageTimeout: {
        type: Number,
        default: 10000, //milliseconds
      },
    },
    data() {
      return {
        sizeUnits: 'px',
        timeUnits: 's',
        longWait: false,
      }
    },
    computed: {
      spinnerSize() {
        return this.size + this.sizeUnits
      },
      spinnerDepth() {
        return this.depth + this.sizeUnits
      },
      spinnerSpeed() {
        return this.speed + this.timeUnits
      },
      spinnerStyle() {
        return {
          borderTopColor: this.hexToRGB(this.color, 0.15),
          borderRightColor: this.hexToRGB(this.color, 0.15),
          borderBottomColor: this.hexToRGB(this.color, 0.15),
          width: this.spinnerSize,
          height: this.spinnerSize,
          borderWidth: this.spinnerDepth,
          animationDuration: this.spinnerSpeed,
        }
      },
    },

    mounted() {
      if (this.showLongWaitMessage) {
        setTimeout(() => this.longWait = true, this.longWaitMessageTimeout)
      }
    },
    methods: {
      hexToRGB(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16),
              g = parseInt(hex.slice(3, 5), 16),
              b = parseInt(hex.slice(5, 7), 16)

        if (alpha) {
          return `rgba(${  r  }, ${  g  }, ${  b  }, ${  alpha  })`
        } else {
          return `rgb(${  r  }, ${  g  }, ${  b  })`
        }
      },
    },
  }
</script>

