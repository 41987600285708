<template>
  <DashboardWidget
    widgetTitle="Alerts"
    :errorMsg="errorMsg"
    :lastUpdated="lastUpdated"
    @refresh="fetch(true)"
  >
    <v-wait :for="source">
      <template #waiting>
        <Spinner :showLongWaitMessage="true" />
      </template>
      <p v-if="!hasUrgentAlerts && !hasNonUrgentAlerts">
        Great work, there are no alerts that need your attention.
      </p>

      <template v-if="hasUrgentAlerts">
        <p class="wb-card__section-title wb-card__section-title--danger">
          <i class="fa fa-exclamation-circle mr-2" />
          Needs Immediate Attention
        </p>
  
        <DashboardWidgetAlertItem
          v-if="urgentAlerts.everify_account"
          :alertsMap="alertsContext"
          :countsData="urgentAlerts.everify_account"
        />
  
        <DashboardWidgetAlertItem
          v-if="urgentAlerts.i9"
          :alertsMap="alertsContext"
          :countsData="urgentAlerts.i9"
        />
  
        <DashboardWidgetAlertItem
          v-if="urgentAlerts.everify"
          :alertsMap="alertsContext"
          :countsData="urgentAlerts.everify"
        />
      </template>

      <template v-if="hasNonUrgentAlerts">
        <p class="wb-card__section-title wb-card__section-title--warning">
          <i class="fa fa-exclamation-circle mr-2" />
          Needs Attention
        </p>
  
        <DashboardWidgetAlertItem
          v-if="nonUrgentAlerts.everify"
          :alertsMap="alertsContext"
          :countsData="nonUrgentAlerts.everify"
        />
  
        <DashboardWidgetAlertItem
          v-if="nonUrgentAlerts.i9"
          ref="nonUrgentI9AlertItems"
          :alertsMap="alertsContext"
          :countsData="nonUrgentAlerts.i9"
        />
  
        <DashboardWidgetAlertItem
          v-if="nonUrgentAlerts.inbox"
          :alertsMap="alertsContext"
          :countsData="nonUrgentAlerts.inbox"
        />
      </template>
    </v-wait>
  </DashboardWidget>
</template>

<script>
  import DashboardWidget from 'components/admin/dashboard/DashboardWidget'
  import Spinner from 'components/common/Spinner'
  import DashboardWidgetAlertItem from 'components/admin/dashboard/DashboardWidgetAlertItem'
  import UrlUtil from "lib/util/url"
  import DashboardFetch from 'components/mixins/DashboardFetch'

  const source = 'dashboard_alerts_query'
  const widgetTitle = ''

  export default {
    name: 'alerts-container',
    components: {
      DashboardWidget,
      Spinner,
      DashboardWidgetAlertItem,
    },
    mixins: [DashboardFetch],
    data() {
      return {
        source,
        widgetTitle: widgetTitle,
        alertsContext: {
          // URGENT
          i9_late_missing_rejected: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'missing_rejected',
              expiration: 'late_missing_rejected',
            }),
            model: 'Form I-9',
            message: 'incomplete and past due',
          },
          i9_late_pending_countersign: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'pending_countersign',
              expiration: 'late_pending_countersign',
            }),
            model: 'Form I-9',
            message: 'past due, awaiting completion of Employer Section 2',
          },
          i9_expired_reverify: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'reverify',
              expiration: 'reverify_late',
            }),
            model: 'Form I-9 document',
            message: 'expired, needs Section 2 Updates',
          },
          everify_authentication_failure: {
            path: this.$routes.everify_account_path,
            model: 'E-Verify Account',
            message: 'failed authentication',
          },
          everify_late: {
            path: UrlUtil.urlWithParams(this.$routes.everify_cases_path, {
              deadline_on: 'past_due',
              employment_status: 'active',
            }),
            model: 'E-Verify case',
            message: 'past due',
          },

          // NON-URGENT
          submissions_pending: {
            path: this.$routes.onboarding_inbox_path,
            model: 'form submission',
            message: 'awaiting review',
          },
          i9_due_soon_missing_rejected: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'missing_rejected',
              expiration: 'due_soon_missing_rejected',
            }),
            model: 'Form I-9',
            message: 'incomplete and due within 3 days',
          },
          i9_due_soon_pending_countersign: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'pending_countersign',
              expiration: 'due_soon_pending_countersign',
            }),
            model: 'Form I-9',
            message: 'due soon, missing Employer Section 2',
          },
          i9_due_soon_reverify: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'reverify',
              expiration: 'reverify_soon',
            }),
            model: 'Form I-9 document',
            message: 'expiring soon',
          },
          i9_awaiting_ssn: {
            path: UrlUtil.urlWithParams(this.$routes.onboarding_i9_index_path, {
              filter: 'awaiting_ssn',
            }),
            model: 'Form I-9',
            message: 'awaiting SSN',
          },

          // E-VERIFY alert statuses
          everify_due_soon: {
            path: UrlUtil.urlWithParams(this.$routes.everify_cases_path, {
              deadline_on: 'soon',
              employment_status: 'active',
            }),
            model: 'E-Verify case',
            message: 'due soon',
          },
        },
      }
    },

    computed: {
      urgentAlerts() {
        return this.chartData && this.chartData.urgent
      },

      nonUrgentAlerts() {
        return this.chartData && this.chartData.non_urgent
      },

      // Checks for possible urgent alerts and returns boolean
      hasUrgentAlerts() {
        return (
          this.urgentAlerts &&
          !!Object.values(this.urgentAlerts).filter((a) => a).length
        )
      },

      // Checks for possible alerts and returns boolean
      hasNonUrgentAlerts() {
        return (
          this.nonUrgentAlerts &&
          !!Object.values(this.nonUrgentAlerts).filter((a) => a).length
        )
      },
    },
  }
</script>
