<template>
  <div>
    <Spinner
      v-if="isLoading"
      ref="spinner"
    />
    <div
      v-else-if="hasDeviceAccess"
      class="mb-3"
    >
      <label>
        Video Source:
      </label>
      <select
        ref="videoSources"
        v-model="selectedDeviceIndex"
        name="mediaSource"
        class="form-control"
      >
        <option>Select a device</option>
        <option
          v-for="(device, index) in inputDevices"
          :key="index"
          :value="index"
        >
          {{ device.label }}
        </option>
      </select>
    </div>
    <div
      v-else
      ref="noDeviceAccess"
    >
      Unable to access your device's camera. Please enable use of your camera within your browser and press Retry or press Cancel to upload an image.
      <div class="mt-3">
        <WbButton
          @click="setInputDevices"
        >
          Retry
        </WbButton>
        <WbButton
          context="link"
          @click="handleCaptureCancel"
        >
          Cancel
        </WbButton>
      </div>
    </div>
    <div
      v-if="hasSelectedDevice"
      ref="mediaContainer"
    >
      <canvas
        v-show="isPhotoTaken"
        ref="canvas"
        class="img-responsive mb-3"
      />
      <video
        v-show="!isPhotoTaken"
        ref="camera"
        class="img-responsive mb-3"
        autoplay
        muted
        playsinline
      />
      <Toolbar>
        <div>
          <template
            v-if="isPhotoTaken"
          >
            <WbButton
              ref="recapture"
              context="default"
              size="sm"
              tooltip="Re-capture Photo"
              @click="handleRetakeClick"
            >
              <WbIcon
                type="refresh"
                class="mr-0"
              />
            </WbButton>
            <WbButton
              ref="save"
              context="success"
              size="sm"
              tooltip="Use this photo"
              @click="handleSavePhoto"
            >
              <WbIcon
                type="check"
                class="mr-0"
              />
            </WbButton>
          </template>
          <WbButton
            v-else
            ref="capture"
            context="success"
            size="sm"
            tooltip="Capture Photo"
            @click="handleCaptureImage"
          >
            <WbIcon
              type="camera"
              class="mr-0"
            />
          </WbButton>
        </div>
        <WbButton
          ref="cancel"
          context="danger"
          size="sm"
          tooltip="Cancel"
          @click="handleCaptureCancel"
        >
          <WbIcon
            type="close"
            class="mr-0"
          />
        </WbButton>
      </Toolbar>
    </div>
  </div>
</template>
<script>
  import Spinner from 'components/common/Spinner'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import Toolbar from 'components/common/Toolbar'

  export default {
    components: {
      Spinner,
      Toolbar,
      WbButton,
      WbIcon,
    },
    emits: [
      'cancel',
      'photoTaken',
    ],
    data () {
      return {
        inputDevices: [],
        isLoading: false,
        isPhotoTaken: false,
        selectedDeviceIndex: null,
      }
    },
    computed: {
      hasDeviceAccess () {
        return this.inputDevices.length > 0
      },
      hasSelectedDevice () {
        return this.selectedDeviceIndex !== null
      },
      videoSource () {
        return this.inputDevices[this.selectedDeviceIndex]
      },
    },
    watch: {
      async selectedDeviceIndex () {
        if (this.selectedDeviceIndex !== null) {
          const stream = await this.getMediaStream()
          // eslint-disable-next-line vue/valid-next-tick
          await this.$nextTick(() => {
            this.$refs.camera.srcObject = stream
          })
        } else {
          this.stopCameraStream()
          this.$refs.camera.srcObject = null
        }
      },
    },
    async created () {
      await this.setInputDevices()
    },
    beforeUnmount () {
      this.stopCameraStream()
    },
    methods: {
      async setInputDevices () {
        this.inputDevices = await this.getDeviceOptions()
      },
      async getDeviceOptions () {
        this.isLoading = true
        let devices = []

        try {
          await navigator.mediaDevices.getUserMedia({audio: false, video: true})

          devices = await navigator.mediaDevices.enumerateDevices()

        } catch (e) {
          console.error(e)
        } finally {
          this.isLoading = false
        }

        return (devices || []).filter(device => {
          return device.kind === 'videoinput'
        })
      },
      async getMediaStream() {
        const constraints = {
          audio: false,
          video: { deviceId: this.videoSource },
        }
        return await navigator.mediaDevices.getUserMedia(constraints)
      },
      handleCaptureCancel () {
        this.selectedDeviceIndex = null
        this.isPhotoTaken = false
        this.$emit('cancel')
      },
      async handleCaptureImage () {
        const canvas = this.$refs.canvas
        const camera = this.$refs.camera
        const { height, width } = camera.getBoundingClientRect()
        canvas.width = width
        canvas.height = height

        const context = canvas.getContext('2d')
        context.drawImage(this.$refs.camera, 0, 0, width, height)
        this.isPhotoTaken = true
      },
      handleRetakeClick () {
        this.revokeFileUrl()
        this.isPhotoTaken = false
      },
      handleSavePhoto () {
        this.$refs.canvas.toBlob(blob => {
          this.fileUrl = URL.createObjectURL(blob)
          this.$emit('photoTaken', {url: this.fileUrl, blob: blob})
        })
      },
      revokeFileUrl () {
        if (this.fileUrl) {
          URL.revokeObjectURL(this.fileUrl)
        }
      },
      stopCameraStream() {
        const tracks = this.$refs.camera.srcObject.getTracks()

        tracks.forEach(track => {
          track.stop()
        })
      },
    },
  }
</script>
