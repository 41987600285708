<template>
  <div class="wb-card wb-card--upsell">
    <div class="wb-card__title">
      ADP&reg; Workforce Now is now available
    </div>
    <div class="wb-card__body mt-3">
      <ul>
        <li>Create employees in ADP&reg; Workforce Now and send demographic data from the staff member profile</li>
        <li>Send Federal W4 information like tax withholding, filing status, deductions and more</li>
        <li>Send payroll information including routing, account number, pay type and frequency</li>
      </ul>
      <a
        class="btn btn-primary mt-2"
        :href="$routes.integrations_path"
      >
        Learn more about ADP&reg; Workforce Now
      </a>
    </div>
  </div>
</template>
