<template>
  <div>
    <wb-modal
      ref="modal"
      watchPageContextVariable="activate_employee_ids"
      title="Activate Staff"
      :locked="$wait.is('employees_change_status')"
      @modalHidden="handleModalHidden"
    >
      <v-wait for="employees_change_status">
        <template #waiting>
          <Spinner :showLongWaitMessage="true" />
        </template>
        <p ref="activationNotice">{{ pluralizedStaffMembers }} will be activated.</p>
        <div class="form-group mt-4">
          <label
            class="mb-0"
            for="email-message"
          >
            <strong>Email message (optional)</strong>
          </label>
          <p class="help-block">If you would like to enter an optional message to go out with the email, enter it here:</p>
          <textarea
            id="email-message"
            ref="emailMessageTextarea"
            v-model='message'
            class="form-control w-100"
            rows=4
            maxlength=1000
          />
        </div>
      </v-wait>
      <template #footer>
        <button
          ref="cancelButton"
          class="btn btn-default"
          :disabled="$wait.is('employees_change_status')"
          @click="hide"
        >
          Cancel
        </button>
        <ladda-button
          ref="submitButton"
          @click="submit"
        >
          Submit
        </ladda-button>
      </template>
    </wb-modal>
    <bulk-action-list-alert
      ref="alert"
      :actionMethod="(employee) => employee.employment_status == 'inactive'"
      permissionName="change_status"
      permissionModule="employees"
      collectionModule="employees"
      actionErrorMessage="These staff members <strong>cannot be activated</strong> because they are already active (or unemployed and require rehiring):"
      :displayNameLookupFn="(employee) => employee.first_name + ' ' + employee.last_name"
      :collectionViewName="this.collectionViewName"
      @continue="openModal"
      @cancelled="$emit('hidden')"
    />
  </div>
</template>

<script>
  import BaseBulkChangeStatusContainer from 'components/employees/bulk_change_status/BaseBulkChangeStatusContainer'
  import Locale from 'lib/locale'
  import StringUtil from 'lib/util/string'

  export default {
    name: 'bulk-activate',
    mixins: [
      BaseBulkChangeStatusContainer,
    ],

    computed: {
      pluralizedStaffMembers() {
        return StringUtil.pluralize(this.employees.length, 'staff member')
      },
    },

    methods: {
      submitImpl(success, fail) {
        this.activateEmployees({
          employees: this.employees,
          emailMessage: this.message,
        }).then(() => {

          success(
            'active',
            Locale.t('employees.activate.success', this.employees.length),
          )

          this.$emit('success')

        }).catch((xhr) => {
          fail(xhr)
        })
      },
    },
  }
</script>
