<template>
  <div>
    <div class="dashboard-header">
      <h2 class="dashboard-title">
        Dashboard
      </h2>
      <LaddaButton
        ref="refreshAllBtn"
        @click="refreshAll"
      >
        <i class="fa fa-refresh" />
        Refresh All
      </LaddaButton>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <AlertsContainer ref='alerts' />
        <I9ReverifyContainer />
        <SmsUpsellWidget
          v-if="shouldShowSmsUpsellWidget"
          ref="smsUpsellWidget"
        />
        <AdpUpsellWidget
          v-if="shouldShowADPUpsellWidget"
          ref="adpUpsellWidget"
        />
        <EverifyUpsellWidget
          v-if="shouldShowEverifyUpsellWidget"
          ref="everifyUpsellWidget"
        />
        <RI9UpsellWidget
          v-if="shouldShowRI9UpsellWidget"
          ref="rI9UpsellWidget"
        />
        <DocumentVerificationUpsellWidget
          v-if="shouldShowDocumentVerificationUpsellWidget"
          ref="documentVerificationUpsellWidget"
        />
      </div>
      <div class="col-lg-7">
        <EverifySummaryContainer
          v-if="currentAccount.everifyFeature"
          ref="everifySummary"
        />
        <FormI9SummaryContainer ref="formI9Summary" />
        <FormsSummaryContainer ref="formsSummary" />
      </div>
    </div>
  </div>
</template>

<script>
  import AdpUpsellWidget from 'components/admin/dashboard/widgets/AdpUpsellWidget'
  import AlertsContainer from 'components/admin/dashboard/widgets/AlertsContainer'
  import DocumentVerificationUpsellWidget from 'components/admin/dashboard/widgets/DocumentVerificationUpsellWidget'
  import EverifySummaryContainer from 'components/admin/dashboard/widgets/EverifySummaryContainer'
  import EverifyUpsellWidget from 'components/admin/dashboard/widgets/EverifyUpsellWidget'
  import FormI9SummaryContainer from 'components/admin/dashboard/widgets/FormI9SummaryContainer'
  import FormsSummaryContainer from 'components/admin/dashboard/widgets/FormsSummaryContainer'
  import I9ReverifyContainer from 'components/admin/dashboard/widgets/I9ReverifyContainer'
  import LaddaButton from 'components/common/LaddaButton'
  import RI9UpsellWidget from 'components/admin/dashboard/widgets/RI9UpsellWidget'
  import SmsUpsellWidget from 'components/admin/dashboard/widgets/SmsUpsellWidget'
  import { mapGetters } from 'vuex'

  export default {
    name: 'the-admin-dashboard-root',
    components: {
      AdpUpsellWidget,
      AlertsContainer,
      DocumentVerificationUpsellWidget,
      EverifySummaryContainer,
      EverifyUpsellWidget,
      FormI9SummaryContainer,
      FormsSummaryContainer,
      I9ReverifyContainer,
      LaddaButton,
      RI9UpsellWidget,
      SmsUpsellWidget,
    },

    computed: {
      shouldShowADPUpsellWidget () {
        return this.currentAccount.upsellsEnabled && !this.currentAccount.payrollEnabled
      },

      shouldShowEverifyUpsellWidget () {
        return this.currentAccount.upsellsEnabled && !this.currentAccount.everifyFeature
      },

      shouldShowRI9UpsellWidget () {
        return this.currentAccount.upsellsEnabled && !this.currentAccount.remoteCountersignEnabled
      },

      shouldShowSmsUpsellWidget () {
        return this.currentAccount.upsellsEnabled && !this.currentAccount.onboardingSmsEnabled
      },

      shouldShowDocumentVerificationUpsellWidget () {
        return this.currentAccount.upsellsEnabled && !this.currentAccount.documentVerificationEnabled
      },

      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },

    methods: {
      refreshAll() {
        this.currentAccount.everifyFeature && this.$refs.everifySummary.fetch(true)
        this.$refs.formI9Summary.fetch(true)
        this.$refs.alerts.fetch(true)
        this.$refs.formsSummary.fetch(true)

        // TODO: Fix this to stop spinning when we are actually done with retrieving dashboard data,
        // It currently turns off the ladda immediately since the above methods are asynchronous
        this.$refs.refreshAllBtn.stop()
      },
    },
  }
</script>
