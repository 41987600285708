<template>
  <div class="policy-generator">
    <WbWizard
      :loading="isLoading"
      :nextLabel="nextLabel"
      @next="nextStep"
      @previous="handlePrevStep"
    >
      <template #steps>
        <WbOrderedList>
          <WbOrderedListItem
            v-for="(step, index) in stepList"
            :key="index"
            :active="step.active"
          >
            {{ step.label }}
          </WbOrderedListItem>
        </WbOrderedList>
      </template>
      <template #messages>
        <AlertMessage
          v-if="shoudlShowGenericFormError"
          :message="$locale.t('errors.form.generic')"
        />
      </template>
      <template #body>
        <component
          :is="currentStepComponentName"
          ref="currentStep"
          :policy="localPolicy"
          :managedPolicy="true"
          :isEditing="isEditing"
          @skip="skipStep"
        />
      </template>
    </WbWizard>
    <WbDialog
      class="text-left"
      title="Confirm Update"
      :open="isConfirming"
      @cancelled="cancelConfirm"
      @confirmed="update"
    >
      <p>This will alter the permissions of {{ localPolicy.count }} Admin Users. This change will be effective immediately. Are you sure you'd like to modify this policy?</p>
    </WbDialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import BaseWizard from "components/common/BaseWizard"
  import StepName from "components/admin/managed_access_policy_templates/wizard/StepName"
  import StepProfileFields from "components/permissions/steps/StepProfileFields"
  import StepProfileFieldsCustomize from "components/permissions/steps/StepProfileFieldsCustomize"
  import StepDocuments from "components/permissions/steps/StepDocuments"
  import StepDocumentsCustomize from "components/permissions/steps/StepDocumentsCustomize"
  import StepEmployeeManagement from "components/permissions/steps/StepEmployeeManagement"
  import StepOtherPeople from "components/permissions/steps/StepOtherPeople"
  import StepOtherActions from "components/permissions/steps/StepOtherActions"
  import StepSummary from "components/permissions/steps/StepSummary"
  import AlertMessage from "components/common/AlertMessage"
  import PageHeader from "components/common/PageHeader"
  import Util from "lib/util"
  import WbOrderedList from "components/common/WbOrderedList"
  import WbOrderedListItem from 'components/common/WbOrderedListItem'
  import WbWizard from 'components/common/WbWizard'
  import WbDialog from 'components/common/WbDialog'

  export default {
    name: "managed-access-policy-template-wizard",

    components: {
      StepName,
      StepProfileFields,
      StepProfileFieldsCustomize,
      StepDocuments,
      StepDocumentsCustomize,
      StepEmployeeManagement,
      StepOtherPeople,
      StepOtherActions,
      StepSummary,
      AlertMessage,
      PageHeader,
      WbOrderedList,
      WbOrderedListItem,
      WbWizard,
      WbDialog,
    },

    mixins: [BaseWizard],

    props: {
      policy: {
        type: Object,
        required: true,
      },
      isEditing: {
        type: Boolean,
        required: false,
      },
    },

    data () {
      return {
        localPolicy: this.policy,
        steps: [
          {component: "StepName", label: "Policy Name"},
          {component: "StepProfileFields", label: "Profile Fields"},
          {component: 'StepProfileFieldsCustomize', label: "Profile Fields"},
          {component: "StepDocuments", label: "Forms" },
          {component: "StepDocumentsCustomize", label: "Forms" },
          {component: "StepEmployeeManagement", label: "Employee Management"},
          {component: "StepOtherPeople", label: "Other People" },
          {component: "StepOtherActions", label: "Other Actions" },
          {component: "StepSummary", label: "Summary" },
        ],
        currentStepIdx: 0,
        navDirection: null,
        history: null,
        hasXHRErrors: null,
        isLoading: false,
        isConfirming: false,
      }
    },

    computed: {
      nextLabel() {
        return this.lastStep ? 'Save' : 'Next'
      },

      shoudlShowGenericFormError () {
        return (
          this.localPolicy.errors
          && Object.keys(this.localPolicy.errors).length
          && this.hasXHRErrors
        )
      },
    },

    created () {
      this.loadPermissionsResources()
    },

    methods: {
      ...mapActions({
        fetchPermissionPolicies: 'permissions/fetchPermissionPolicies',
        createTemplate: 'managed_access_policy_templates/create',
        updateTemplate: 'managed_access_policy_templates/update',
      }),

      confirm() {
        if (this.localPolicy.count > 0){
          this.isConfirming = true
        } else {
          this.update()
        }
      },

      cancelConfirm() {
        this.isConfirming = false
      },

      async create() {
        try {
          await this.createTemplate({data: this.localPolicy.toJSON()})
          Util.navigateAndShowFlashNotice(this.$routes.managed_access_policy_templates_path, "Permissions Policy created successfully")
        } catch (xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.handleXHRErrors(xhr.responseJSON.errors)
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        }
      },

      async update() {
        try {
          await this.updateTemplate({id: this.localPolicy.id, data: this.localPolicy.toJSON()})
          Util.navigateAndShowFlashNotice(this.$routes.managed_access_policy_templates_path, "Permissions Policy is being updated, please check back shortly")
        } catch (xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.handleXHRErrors(xhr.responseJSON.errors)
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        }
      },

      done() {
        if (this.isEditing){
          this.confirm()
        } else {
          this.create()
        }
      },

      handleXHRErrors (errors) {
        this.localPolicy.errors = errors
        this.currentStepIdx = 0
        this.hasXHRErrors = true
      },

      async loadPermissionsResources () {
        this.isLoading = true

        try {
          await this.fetchPermissionPolicies()
        } catch (xhr) {
          Util.genericAjaxError(this.$locale.t("errors.ajax.generic"), xhr)
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>
