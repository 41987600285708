<template>
  <div>
    <p ref="accessDetails">
      You are signed in as
      <strong>{{ currentUserEmail }}</strong>
      <span
        v-if="hasSuperadminRole"
        class="label label-success"
      >superadmin</span>
    </p>
    <hr />
    <!-- / FIXME: Move into own container component -->
    <div
      v-if="searchVisible"
      class="tenant-search-container breathe above deep"
    >
      <h4 class="text-center">Search for Organization</h4>
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2">
          <input
            class="tenant-search form-control"
            autofocus="autofocus"
            type="text"
            placeholder="Organization Name or Subdomain"
            @input="setQuery"
          />
        </div>
      </div>
    </div>
    <v-wait for="fetch_associated_accounts">
      <template #waiting>
        <Spinner />
      </template>
      <div
        v-if="shouldShowNoResultsText"
        ref="noResults"
        class="tenant-search-no-results text-center"
      >
        No results found
      </div>
    </v-wait>
    <TenantSelectResultsList :query-results="results" />

    <AccountAccessReason v-if="hasSuperadminRole" />
  </div>
</template>

<script>
  import TenantSelectResultsList from 'components/TenantSelectResultsList'
  import { mapGetters, mapState, mapActions } from 'vuex'
  import Spinner from 'components/common/Spinner'
  import AccountAccessReason from 'components/superadmin/AccountAccessReason'

  export default {
    name: 'the-tenant-select-index-root',
    components: {
      TenantSelectResultsList,
      Spinner,
      AccountAccessReason,
    },

    data: () => {
      return {
        resultIds: [],
        query: '',
      }
    },

    computed: {
      currentUserEmail() {
        return this.pageContext.current_user_email
      },

      hasSuperadminRole() {
        return this.pageContext.current_user_role === "superadmin"
      },

      searchVisible() {
        return this.hasSuperadminRole || this.truncated
      },

      truncated() {
        return this.pageContext.associated_accounts_truncated
      },

      shouldShowNoResultsText() {
        return (this.resultIds.length == 0 &&
          this.query.length >= 3 &&
          !this.$wait.is('fetch_associated_accounts'))
      },

      results() {
        return this.getAccountsBatch(this.resultIds)
      },

      ...mapGetters({
        getAccountsBatch: 'tenant_select_accounts/getBatch',
        collection: 'tenant_select_accounts/collection',
      }),

      ...mapState([
        'pageContext',
      ]),
    },

    watch: {
      query(value) {
        // Search must be at least 3 characters
        if (value.length < 3) {
          this.resultIds = []
          return
        }

        this.retrieveSearchResults(value)
      },
    },

    created() {
      // Set initial result IDs to whatever was loaded with loadInitialData when this component was created
      this.resultIds = Object.keys(this.collection)
    },

    methods: {
      setQuery: _.debounce(function(e) {
        this.query = e.target.value
      }, 200),

      retrieveSearchResults: function(accountNameQuery) {
        this.$wait.start('fetch_associated_accounts')

        this.fetchAssociatedAccounts(accountNameQuery)
          .then((accountIds) => {
            this.resultIds = accountIds
          }).catch(() => {
            this.resultIds = []
          }).finally(() => {
            this.$wait.end('fetch_associated_accounts')
          })
      },

      ...mapActions({
        fetchAssociatedAccounts: 'tenant_select_accounts/fetchAssociatedAccounts',
      }),
    },
  }
</script>
