<template>
  <table
    v-if="hasValidFields"
    ref="container"
    class="table info-table panel-info-table"
  >
    <caption class="info-sub-header">
      <slot name="caption" />
    </caption>
    <tbody>
      <tr
        v-for="field in fields"
        :key="field.id"
        class="info-item anim"
        :class="{'anim-temporary-highlight' : checkHighlightField(field)}"
      >
        <th
          class="col-md-7"
          scope="row"
        >
          {{ field.label }}
        </th>
        <td class="col-md-5 text-right">
          {{getDisplayValueFromField(field)}}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import Constants from 'lib/constants'
  const FORMATS_BY_FIELD = {
    pay_rate_type: 'OrderedChoice',
    pay_frequency: 'OrderedChoice',
    direct_deposit_account_type: 'OrderedChoice',
  }
  const DEF_VALUE = '--'
  export default {
    name: 'payroll-fields-group',
    props: {
      fields: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        updatedFields: [],
      }
    },
    computed: {
      hasValidFields() {
        return this.fields && this.fields.length
      },
      isHighlighted() {
        return false
      },
    },
    watch: {
      fields: {
        handler(newFields, oldFields) {
          this.updatedFields = []
          for (let i = 0; i < newFields.length; i++) {
            if (newFields[i].value !== oldFields[i].value) {
              this.updatedFields.push(newFields[i].key)
            }
          }
          setTimeout(() => {
            this.updatedFields = []
          }, Constants.FADE_OUT_DURATION)
        },
        deep: true,
      },
    },
    methods: {
      checkHighlightField (field) {
        return this.updatedFields.includes(field.key)
      },
      // Find display value for a field. For most fields, this is just the stored string value. But for fields with
      // multiple options, we store the downcased value and need to look up the capitalized display value.
      getDisplayValueFromField (field) {
        const formatType = `format${FORMATS_BY_FIELD[field.key] || 'Def'}`
        return this[formatType](field)
      },
      formatDef (field) {
        const value = field.value
        return value !== null && value !== undefined ? value : DEF_VALUE
      },
      formatOrderedChoice (field) {
        let value = DEF_VALUE
        const choices = field.ordered_choices || []
        for (let i = 0; i < choices.length; i++) {
          if (choices[i][1] === field.value) {
            value = choices[i][0]
            break
          }
        }

        return value
      },
    },
  }
</script>
