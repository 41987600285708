<template>
  <div class="wizard-body">
    <div class="form-horizontal">
      <FormFieldSelect
        :modelValue="localBulkStaffUpdate.employee_id_column_index"
        :value="localBulkStaffUpdate.employee_id_column_index"
        label="WorkBright ID"
        :options="employeeIdColumnOptions"
        orientation="horizontal"
        field-width=""
        class="my-3 pl-1"
        @update:modelValue="handleWorkbrightIdChange"
      />
    </div>
  </div>
  <div class="wizard-footer text-right">
    <WbButton
      ref="nextButton"
      class="next"
      context="primary"
      @click="handleNext"
    >
      Next
    </WbButton>
  </div>
</template>

<script>
  import BaseStep from 'components/user_updates/steps/BaseStep'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import {selectEmployeeId} from 'services/admin/BulkStaffUpdatesService'
  import WbButton from 'components/common/WbButton'
  import Util from 'lib/util'

  export default {
    name: 'select-employee-id-step',
    components: {
      FormFieldSelect,
      WbButton,
    },
    extends: BaseStep,
    props: {
      bulkStaffUpdate: {
        type: Object,
        required: true,
      },
    },
    emits: ['complete'],
    data () {
      return {
        localBulkStaffUpdate: {...this.bulkStaffUpdate},
      }
    },
    computed: {
      employeeIdColumnOptions() {
        return this.localBulkStaffUpdate.columns.map(({index, name})=>({label: name, value: String(index)}))
      },
    },
    methods: {
      handleWorkbrightIdChange(newValue) {
        this.localBulkStaffUpdate.employee_id_column_index = newValue
      },
      handleNext () {
        selectEmployeeId({...this.localBulkStaffUpdate})
          .then((bulkStaffUpdate) => {
            this.$emit('complete', bulkStaffUpdate, true)
          }).catch(xhr => {
            if (xhr.responseJSON?.errors) {
              this.errors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError('An error occurred trying to update this Bulk Staff Update', xhr)
            }
          })
      },
    },
  }
</script>
