<template>
  <div>
    <p>Please choose the form you would like these {{employeeIds.length}} staff to re-submit:</p>
    <div
      v-if="errorMessage"
      class="alert alert-danger"
    >{{errorMessage}}</div>
    <div class="input-wrapper long-field">
      <WbSearch
        ref="select"
        v-model="documentId"
        :options="options"
        placeholder="Search forms by name"
        :responseProcessor="processResponse"
        :route="$routes.Api.search_documents_path"
      />
    </div>
    <p class="pt-3">
      <small>
        A new submission will only be requested from the staff members who are
        <strong>already assigned to that form.</strong>
        If they are not assigned to that form, they will not receive a submission request.
      </small>
    </p>
  </div>
</template>

<script>
  import WbSearch from 'components/common/WbSearch'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    name: 'step-select-document',

    components: {
      WbSearch,
    },

    props: {
      collectionViewName: {
        type: String,
        required: true,
      },

      employeeIds: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        errorMessage: null,
        documentId: null,
        options: [],
      }
    },

    computed: {
      ...mapGetters({
        assignmentsGetBatch: 'document_assignments/getBatch',
        getDocument: 'documents/get',
      }),

      anyAssignments() {
        return !!this.assignments.length
      },

      ...mapState({
        assignments(state) {
          return this.assignmentsGetBatch(state[this.collectionViewName].selected)
        },
      }),
    },

    mounted() {
      if (this.anyAssignments) {
        this.documentId = this.assignments[0].document_id
        this.$emit('skip')
      }
    },

    methods: {
      valid() {
        return !!this.documentId
      },

      beforeAdvance() {
        const document = this.getDocument(this.documentId)
        this.$emit('updateData', { document })
      },

      invalidError() {
        this.errorMessage = 'You must select a document to continue.'
      },

      processResponse (response) {
        const documents = response && response.items || []
        return documents.map(document => {
          this.addDocument(document)
          return {
            label: document.name,
            value: document.id,
          }
        })
      },

      ...mapMutations({
        addDocument: 'documents/SET_RECORD',
      }),
    },
  }
</script>
