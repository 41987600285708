<template>
  <Panel variant="snapshot">
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>{{ $locale.t('everify.status.referred.title') }}</h4>
      </div>
    </template>
    <template v-slot:body>
      <p>
        {{ $locale.t('everify.status.referred.helpText') }}
      </p>
      <template v-if="hasSufficientData">
        <template v-if="allowDownload">
          <div class="mb-2">
            <a
              v-if="fanPath"
              ref="fanLink"
              class="btn btn-default"
              :href="fanPath"
              download
              target="_blank"
            >
              <WbIcon type="download" />
              Download Further Action Notice - English
            </a>
          </div>
          <div class="mb-2">
            <a
              v-if="fanPathSpanish"
              ref="fanLinkSpanish"
              class="btn btn-default"
              :href="fanPathSpanish"
              download
              target="_blank"
            >
              <WbIcon type="download" />
              Download Further Action Notice - Spanish
            </a>
          </div>
          <div class="mb-2">
            <a
              v-if="referralPath"
              ref="referralLink"
              download
              class="btn btn-default"
              :href="referralPath"
              target="_blank"
            >
              <WbIcon type="download" />
              Download Referral Date Confirmation Letter - English
            </a>
          </div>
          <div>
            <a
              v-if="referralPathSpanish"
              ref="referralLinkSpanish"
              download
              class="btn btn-default"
              :href="referralPathSpanish"
              target="_blank"
            >
              <WbIcon type="download" />
              Download Referral Date Confirmation Letter - Spanish
            </a>
          </div>
        </template>
        <i>
          Note: There may be state-specific guidance on the method in which you need to provide these notices. The Further Action Notice below has been delivered to the employee via WorkBright and they were notified via their selected notification method. Please refer to your state-specific guidelines if additional notification outside of WorkBright is required.
        </i>
      </template>
      <div
        v-else
        ref="noLinksAlert"
        class="alert alert-warning"
      >
        {{  $locale.t('everify.status.referred.noData') }}
      </div>
    </template>
  </Panel>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'
  import Panel from 'components/common/Panel'

  export default {
    components: {
      Panel,
      WbIcon,
    },
    props: {
      everifyCase: {
        type: Object,
        required: true,
      },
      allowDownload: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      fanPath () {
        return this.everifyCase.fan_url
      },
      fanPathSpanish () {
        return this.everifyCase.fan_url_spanish
      },
      hasSufficientData () {
        return this.fanPath || this.referralPath
      },
      referralPath () {
        return this.everifyCase.rdc_url
      },
      referralPathSpanish () {
        return this.everifyCase.rdc_url_spanish
      },
    },
  }
</script>
