<template>
  <button
    v-if="isPermitted"
    :class="classes"
    :disabled="isDisabled"
    @click="click"
  >
    <slot name="prepend" />
    <span
      v-if="label"
      v-html="label"
    />
    <slot v-else />
    <span
      v-if="tooltip"
      ref="tooltip"
      :class="tooltipClasses"
    >
      {{ tooltip }}
    </span>
  </button>
</template>

<script>
  import PermissionEnforcement from 'components/mixins/PermissionEnforcement'
  export const SIZES = ['xs', 'sm', 'md', 'lg']
  export const VARIANTS = ['primary', 'success', 'info', 'danger', 'warning', 'default', 'link']
  export const TOOLTIP_POSITIONS = ['top', 'bottom', 'left', 'right']

  export default {
    name: 'wb-button',
    mixins: [PermissionEnforcement],
    props: {
      isLoading: {
        type: Boolean,
      },

      // Label to Display
      label: {
        type: String,
        required: false,
      },

      outline: {
        type: Boolean,
      },

      size: {
        type: String,
        required: false,
        validator: value => {
          return SIZES.includes(value)
        },
      },

      // Button context class (primary/info/warning/etc)
      context: {
        type: String,
        default: 'primary',
        validator: value => {
          return VARIANTS.includes(value)
        },
      },

      disabled: {
        type: Boolean,
        required: false,
      },

      tooltip: {
        type: String,
      },

      tooltipPosition: {
        type: String,
        default: 'bottom',
        validator: value => {
          return TOOLTIP_POSITIONS.includes(value)
        },
      },
    },
    emits: ['click'],

    computed: {
      classes() {
        const classes = ['btn', `btn-${this.context}`]
        if (this.isLoading) {
          classes.push('btn-loading')
        }

        if (this.size) {
          classes.push(`btn-${this.size}`)
        }

        if (this.outline) {
          classes.push('btn-outline')
        }

        return classes
      },

      tooltipClasses () {
        return `btn-tooltip btn-tooltip--${this.tooltipPosition}`
      },
      
      isDisabled() {
        return this.disabled || this.isLoading
      },
    },

    methods: {
      click(event) {
        if (this.disabled) { return }
        this.$emit('click', event)
      },
    },
  }
</script>
