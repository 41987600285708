// E-Verify case title displayed on small slideout panel (used with EverifyCasePanelContent).

<template>
  <div>
    <div
      v-if="isLoading"
      class="row column-divider"
    >
      <Spinner />
    </div>
    <div
      v-else
      ref="everifyDocumentPreview"
      class="row column-divider"
    >
      <div :class="showEverifyPhoto ? 'col-lg-6' : 'col-lg-12'"> 
        <EverifyCasePhoto
          header="WorkBright I-9 Documentation"
          :photoFrontUrl="photoFrontUrl"
          :photoBackUrl="photoBackUrl"
          :shouldShowBack="true"
        />
      </div>
      <div
        v-if="showEverifyPhoto"
        class="col-lg-6"
      >
        <EverifyCasePhoto
          header="E-Verify Documentation"
          :photoFrontUrl="everifyPhotoUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Spinner from 'components/common/Spinner'
  import EverifyCasePhoto from 'components/everify/cases/EverifyCasePhoto'
  import Util from 'lib/util'

  export default {
    name: "everify-case-document-preview",

    components: {
      Spinner,
      EverifyCasePhoto,
    },

    props: {
      caseId: {
        type: Number,
        required: true,
      },
      showEverifyPhoto: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        photoFrontUrl: null,
        photoBackUrl: null,
        everifyPhotoUrl: null,
        isLoading: true,
      }
    },

    created() {
      this.getPhotoUrls()
    },

    methods: {
      getPhotoUrls() {
        this.fetchPhotoUrls({
          caseId: this.caseId,
        }).then((response) => {
          this.isLoading = false
          this.photoFrontUrl = response.wb_front_url
          this.photoBackUrl = response.wb_back_url
          this.everifyPhotoUrl = response.everify_url
        }).catch((xhr) => {
          this.isLoading = false
          Util.ajaxErrorDialog("An error occurred while fetching photos.", xhr)
        })
      },

      ...mapActions({
        fetchPhotoUrls: 'everify_cases/fetchPhotoUrls',
      }),
    },
  }
</script>
