<template>
  <div>
    <h2 class="page">Edit Form {{ documentDisplayName }}</h2>
    <p>{{ editDocumentText }}</p>
    <div class="form-horizontal">
      <FormFieldCheckbox
        v-model="formData.enabled"
        label="Enabled"
        name="enabled"
        :errors="errors"
      />

      <FormFieldCheckbox
        v-model="formData.force_inbox"
        label="All submissions for this form MUST be reviewed in the inbox"
        name="forceInbox"
        :hint="forceInboxHintText"
        :helpPopoverText="helpText"
        :errors="errors"
      />

      <RemoteCountersignSettings
        v-if="showRemoteCountersignSettings"
        ref="remoteCountersignSettings"
        :initialData="remoteCountersignSettings"
        :errors="errors"
        @update="handleSettingsUpdate"
      />

      <RehireOptions
        ref="rehireOptions"
        :initialData="rehireOptions"
        :errors="errors"
        @update="handleSettingsUpdate"
      />

      <Toolbar>
        <div class="btn-sequence">
          <WbButton
            ref="submitBtn"
            :isLoading="isLoading"
            @click="handleSubmit"
          >
            Save
          </WbButton>
          <a
            ref="cancelBtn"
            class="btn btn-default"
            :class="{ disabled: isLoading }"
            :href="documentsPath"
          >
            Cancel
          </a>
        </div>
      </Toolbar>
    </div>
  </div>
</template>

<script>
  import Util from 'lib/util'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import WbButton from 'components/common/WbButton'
  import Toolbar from 'components/common/Toolbar'
  import { getRoute } from 'lib/routes'
  import RemoteCountersignSettings from 'components/admin/documents/RemoteCountersignSettings'
  import RehireOptions from 'components/admin/documents/RehireOptions'
  import { omit, pick } from 'underscore'
  import { ajax } from 'jquery'
  import { mapGetters } from 'vuex'

  export default {
    name: 'the-edit-built-in-document-root',
    components: {
      FormFieldCheckbox,
      WbButton,
      Toolbar,
      RemoteCountersignSettings,
      RehireOptions,
    },

    props: {
      documentId: {
        type: String,
        required: true,
      },

      documentDisplayName: {
        type: String,
        required: true,
      },

      documentSettings: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        formData: {
          ...this.documentSettings,
        },
        isLoading: false,
        errors: {},
      }
    },

    computed: {
      helpText() {
        return this.$locale.t('documents.help_text.force_inbox')
      },

      forceInboxHintText() {
        return this.$locale.t('documents.help_text.force_inbox_hint')
      },

      documentsPath() {
        return this.$routes.documents_path
      },

      remoteCountersignSettings() {
        return omit(this.documentSettings, 'enabled', 'force_inbox')
      },

      rehireOptions () {
        return pick(this.documentSettings, 'request_new_submission_on_rehire')
      },

      editDocumentText() {
        switch (this.documentId) {
          case 'w4':
            return this.$locale.t('documents.edit.built_in.w4')
          case 'i9':
            return this.$locale.t('documents.edit.built_in.i9')
          default:
            return this.$locale.t('documents.edit.built_in.default')
        }
      },

      showRemoteCountersignSettings() {
        return this.documentId === "i9" && this.currentAccount.remoteCountersignEnabled
      },

      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },

    methods: {
      handleSubmit() {
        this.isLoading = true

        ajax({
          method: 'PATCH',
          url: getRoute('document_path', { id: this.documentId }),
          data: {
            document: this.formData,
          },
        }).success((_data, _status, _xhr) => {
          Util.navigateAndShowFlashNotice(this.documentsPath, this.successStr())
        }).fail((xhr) => {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        }).always(() => {
          this.isLoading = false
        })
      },

      handleSettingsUpdate(data) {
        this.formData = {...this.formData, ...data}
      },

      successStr() {
        return this.$locale.t('documents.update.success', this.documentDisplayName)
      },
    },
  }
</script>
