<template>
  <div class="wb-card wb-card--upsell">
    <div class="wb-card__title">
      Activate today: Enhanced I-9 photo capture, type matching, &amp; advanced document verification
    </div>
    <div class="wb-card__body mt-3">
      <ul>
        <li>
          Guided photo capture + OCR: Ensures staff take clear, well-framed photos of supporting documents, automatically scanning and transcribing details into Section 2.
        </li>
        <li>
          Document type matching: Confirms that uploaded documents align with the selected List A, B, or C work authorization type.
        </li>
        <li>
          (Premium only) Comprehensive document verification: Includes work authorization validation, original document checks, and fraud detection for enhanced compliance.
        </li>
      </ul>
      <a
        class="btn btn-primary mt-2"
        :href="documentVerificationsAccountsUrl"
      >
        Learn More about Enhanced Document Verification
      </a>
    </div>
  </div>
</template>

<script>
  import { getRoute } from 'lib/routes'

  export default {
    name: 'document-verification-upsell-widget',

    computed: {
      documentVerificationsAccountsUrl () {
        return getRoute('document_verifications_accounts_path')
      },
    },
  }
</script>
