<template>
  <div
    class="file-upload"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <div :class="{'has-error' : error, 'required': required}">
      <FormLabel
        ref="label"
        for="file"
        label="Spreadsheet (.csv, .xlsx)"
        :required="required"
      />
      <input
        ref="file"
        class="d-none"
        type="file"
        name="file"
        :accept="acceptedFileTypes"
        @change="handleFileChange"
      />
      <template v-if="!isUploading">
        <template v-if="hasFile">
          <div class="file-remove-instructions container-fluid">
            <div class="row p-3">
              <div
                ref="fileUploadedSuccessfully"
                class="col-xs-4"
              >
                <span class="text-success">
                  <WbIcon
                    class="mr-0"
                    type="check"
                  />
                  File uploaded successfully
                </span>
                <p>Made a mistake? No problem, just click Remove to start over:</p>
                <WbButton
                  ref="removeFile"
                  tooltip="Remove"
                  context="danger"
                  size="sm"
                  @click="handleRemoveFile"
                >
                  <WbIcon
                    class="mr-0"
                    type="close"
                  />
                  Remove
                </WbButton>
              </div>
              <div class="col-xs-4">
                <div class="thumbnail">
                  <img
                    ref="thumbnail"
                    :src="thumbnail"
                    alt="Uploaded file thumbnail"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-else
          class="file-upload-instructions"
        >
          <div
            v-if="isDragging"
            ref="dragIndicator"
            class="drag-indicator"
          >
            Release to drop file here.
          </div>
          <div
            v-else
            ref="fileDropper"
            class="text-center"
          >
            Drop file here or
            <u
              class="underline"
              role="button"
              @click="handleOpenFile"
            >
              click here
            </u>
            to upload.
          </div>
        </div>
        <div
          v-if="error"
          class="help-block"
          v-html="error"
        />
      </template>
      <div ref="dropzone">
        <div
          v-if="isUploading"
          class="uploading-instructions text-center"
        >
          <div>{{ uploadInstructions }}</div>
          <div class="dz-progress progress mt-2">
            <div
              class="progress-bar progress-bar-success"
              role="progressbar"
              :style="{width: uploadProgress + '%'}"
            >
              <span class="progress-msg" />
            </div>
          </div>
          <WbButton
            context="warning"
            @click="cancelUpload"
          >
            <WbIcon
              icon-set="fa"
              type="ban"
            />
            Cancel
          </WbButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AWSRequestXHRDecorator from 'decorators/aws_request_xhr_decorator'
  import DropzoneUploadService from 'services/dropzone_upload_service'
  import ErrorService from 'lib/error_service'
  import FormLabel from 'components/common/FormLabel'
  import { getRoute } from 'lib/routes'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'

  const ACCEPTABLE_FILE_TYPES = ['.csv', '.xlsx']
  const THUMBNAILS = [
    {
      url: getRoute('Images.csv'),
      matchString: /csv/,
      extensions: ['csv'],
    },
    {
      url: getRoute('Images.excel'),
      matchString: /excel|spreadsheet/,
      extensions: ['xls', 'xlsx'],
    },
  ]

  export default {
    components: {FormLabel, WbButton, WbIcon},
    props: {
      error: {
        type: String,
      },
      required: {
        type: Boolean,
        default: false,
      },
      uploadServiceClass: {
        type: Function,
        default: DropzoneUploadService,
      },
    },
    emits: [
      'upload-success',
    ],
    data() {
      return {
        isDragging: false,
        isUploading: false,
        file: null,
        fileUrl: null,
        fileKey: null,
        fileContentType: null,
        uploadInstructions: null,
        uploadProgress: 0,
      }
    },
    computed: {
      acceptedFileTypes() {
        return ACCEPTABLE_FILE_TYPES.join(',')
      },
      fileExtension() {
        return this.file.name.split('.').pop()
      },
      hasFile() {
        return !!this.file && !!this.fileUrl
      },
      thumbnail() {
        return THUMBNAILS.find(thumbnail => this.file.type.match(thumbnail.matchString) || thumbnail.extensions.includes(this.fileExtension))?.url
      },
    },
    mounted() {
      this.initializeUploadService()
    },
    methods: {
      cancelUpload () {
        this.uploadService.removeAllFiles(true)
        this.reset()
        this.notifyRemoveFile()
      },
      initializeUploadService() {
        const uploadOptions = {
          previewTemplate: "<div></div>",
          accept: this.handleUploadAccept,
          acceptedFiles: '.csv,.xlsx',
        }

        this.uploadService = new this.uploadServiceClass({element: this.$refs.dropzone, options: uploadOptions})
        this.uploadService.on('complete', this.handleUploadComplete)
        this.uploadService.on('sending', this.handleUploadSending)
        this.uploadService.on('uploadprogress', this.handleUploadProgress)
        this.uploadService.on('success', this.handleUploadSuccess)
        this.uploadService.on('error', this.handleUploadError)
      },
      handleDragOver () {
        if (this.hasFile) { return false }

        this.isDragging = true
      },
      handleDragLeave () {
        if (this.hasFile) { return false }

        this.isDragging = false
      },
      handleDrop (event) {
        if (this.hasFile) { return false }

        this.$refs.file.files = event.dataTransfer.files
        this.handleFileChange()
        this.isDragging = false
      },
      handleFileChange () {
        const file = this.$refs.file.files[0]
        if (file) {
          const fileUrl = this.upload(file)
          if (fileUrl) {
            this.setFile(file, fileUrl)
          }
        }
        this.$refs.file.value = ''
      },
      handleOpenFile () {
        this.$refs.file.click()
      },
      handleRemoveFile () {
        this.cancelUpload()
      },
      handleUploadAccept (file, callback) {
        if (file.size === 0) {
          callback('file_empty')
          return
        }

        this.isUploading = true
        this.uploadProgress = 0
        this.uploadInstructions = "Preparing to upload..."

        this.uploadService.presign(file, callback)
      },
      handleUploadComplete (_file) {
        this.isUploading = false
      },
      handleUploadError (file, errorMessage, xhr) {
        this.uploadService.removeFile(file)

        if (xhr) {
          this.handleXhrError(file, errorMessage, xhr)
        } else {
          this.renderErrorMessage(errorMessage)
        }
      },
      handleUploadSending (file, _xhr, formData) {
        Object
          .entries(file.awsSignatureFormData)
          .forEach(([key, value]) => { formData.append(key, value)})
        this.uploadInstructions = 'Uploading, please wait.'
      },
      handleUploadSuccess (file, response) {
        /**
         * response XML
         *
         * <?xml version="1.0" encoding="UTF-8"?>
         * <PostResponse>
         *  <Location>https://wb-dropzone-dev.s3.amazonaws.com/uploads%2F246a5a7643adc79a651b33e9b9f71dbd00b8%2F1.png</Location>
         *  <Bucket>wb-dropzone-dev</Bucket>
         *  <Key>uploads/246a5a7643adc79a651b33e9b9f71dbd00b8/1.png</Key>
         *  <ETag>"953480de7122a92868cc44c853bc8e95"</ETag>
         * </PostResponse>
        */
        const domParser = new DOMParser()
        const xmlDoc = domParser.parseFromString(response, 'application/xml')
        const awsLocationElement = xmlDoc.querySelector('Location')
        const awsKeyElement = xmlDoc.querySelector('Key')

        this.setFile(file, awsLocationElement?.textContent)
        this.fileKey = awsKeyElement?.textContent
        this.fileContentType = file.type

        this.notifyUploadSuccess()
      },
      handleUploadProgress (_file, progress, _bytesSent) {
        this.uploadProgress = progress
      },
      handleXhrError (file, errorMessage, xhr) {
        new ErrorService('Failed to upload file to DZ bucket', 'AWSUploadError').report({
          dz_file: file,
          dz_error: errorMessage,
          xhr_readyState: xhr.readyState,
          xhr_status: xhr.status,
          xhr_statusText: xhr.statusText,
          aws_error: AWSRequestXHRDecorator(xhr).responseJSON['Error'],
        })

        Util.ajaxErrorDialog('Sorry, there was an issue uploading this file. Please refresh the page and try again.', xhr)
      },
      renderErrorMessage (errorMessage) {
        switch (errorMessage) {
          case 'file_type':
            Util.errorDialog(this.uploadService.getDefaultValue('fileTypeErrorMsg'), "File Type Not Allowed")
            break
          case 'file_too_big':
            Util.errorDialog(`Sorry, your file is too big to upload. Files must be ${this.uploadService.options.maxFilesize} MB or smaller.`, 'File Too Big')
            break
          case 'file_empty':
            Util.errorDialog(`It looks like this file is empty (0 bytes). Please double-check the file by opening it up in a different app (such as a PDF reader or image viewer).<br/><br/>If you're sure the file is OK but you still get this error message, you may need to restart your browser and try again.`, "Empty File")
            break
        }
      },
      setFile (file, fileUrl) {
        this.file = file
        this.fileUrl = fileUrl
      },
      notifyRemoveFile () {
        this.$emit('file-remove')
      },
      notifyUploadSuccess() {
        this.$emit('upload-success', {url: this.fileUrl, key: this.fileKey, contentType: this.fileContentType})
      },
      reset() {
        this.isDragging = false
        this.isUploading = false
        this.file = null
        this.fileUrl = null
        this.fileKey = null
        this.fileContentType = null
        this.uploadInstructions = null
        this.uploadProgress = 0
        this.isUploading = false
      },
      upload (file) {
        this.uploadService.removeAllFiles(true)
        this.uploadService.addFile(file) // Fires up the upload
      },
    },
  }
</script>
