<template>
  <div class="wb-card wb-card--upsell">
    <div class="wb-card__title">
      Text Notifications are Now Available
    </div>
    <div class="wb-card__body mt-3">
      <ul>
        <li>All WorkBright staff notifications can be sent via text message instead of email</li>
        <li>Text messages are 5x more likely to be opened than an email</li>
        <li>Increase your hiring speed, completion rate, and throughput</li>
      </ul>
      <a
        class="btn btn-primary mt-2"
        :href="$routes.text_notifications_accounts_path"
      >
        Learn More about Text Notifications
      </a>
    </div>
  </div>
</template>
