<template>
  <div
    class="form-group"
    :class="{'has-error' : hasError }"
  >
    <div :class="gridColumns">
      <div :class="localWrapperClass">
        <label>
          <input
            type="checkbox"
            :name="name"
            :value="value"
            :checked="value"
            :disabled="disabled"
            @change="$emit('input', $event.target.checked)"
          />
          <slot>
            {{ label }}
          </slot>
          <HelpPopover
            v-if="helpPopoverText"
            ref="popover"
            :title="helpPopoverTitle || label"
            :content="helpPopoverText"
          />
        </label>
      </div>
      <small
        v-if="hasError"
        class="help-block"
      >{{ errorMessage }}</small>
      <small
        v-if="hint"
        class="help-block"
      >{{ hint }}</small>
    </div>
  </div>
</template>

<script>
  import FormField from 'components/common/FormField'
  import HelpPopover from 'components/common/HelpPopover'

  export default {
    name: 'form-field-checkbox',
    components: {
      HelpPopover,
    },
    mixins: [FormField],
    props: {
      value: {},
      gridColumns: {
        type: String,
        default: 'col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9',
      },
      wrapperClass: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    computed: {
      localWrapperClass() {
        return {
          'input-wrapper': true,
          checkbox: true,
          disabled: this.disabled,
          ...this.wrapperClass,
        }
      },
    },
  }
</script>
