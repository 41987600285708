<template>
  <div>
    <div v-if="this.mfaData.configuring">
      <MfaConfigContainer
        :usingAuthApp="this.usingAuthApp"
        :provisioningUri="this.mfaData.provisioningUri"
        @wbClickAuthAppConfig="handleClickAuthAppConfig"
        @wbOtpSentViaSms="handleOtpSentViaSms"
      />
      <div v-if="displaySubmit">
        <hr />
        <p>
          <strong>Step 3: Enter the one-time code the app provides</strong>
        </p>
      </div>
    </div>
    <div v-if="displaySubmit">
      <form @submit="onSubmitOtpForm">
        <form-field-input
          ref="otp"
          v-model="formData.otp"
          name="otp"
          autocomplete="off"
          placeholder="012345"
          :required="true"
          :label="this.otpLabel"
          :errors="formErrors"
        />
        <div class="form-group">
          <ladda-button
            ref="submitButton"
            class="submit"
            @click="handleSubmit"
          >
            {{this.submitText}}
          </ladda-button>
          <span v-if="this.showResendSms">
            <a
              class="btn btn-default ml-2"
              href="#sms"
              @click="handleClickSmsResend"
            >
              Resend code via text
            </a>
          </span>
        </div>
      </form>
    </div>
    <div v-if="showSMSOption">
      <hr />
      <a
        class="btn btn-default"
        href="#sms"
        @click="handleClickSmsConfig"
      >
        Click here if you prefer to configure MFA via Text Message.
      </a>
    </div>
    <div v-if="!mfaData.configuring">
      <div v-if="showLostDeviceMessage">
        <hr />
        <p>
          Lost Device?&nbsp;
          <a
            href="#lost"
            @click="handleClickLostDevice"
          >
            Click here to authenticate with a backup code.
          </a>
        </p>
      </div>
      <div v-else>
        <hr />
        <p>
          You have chosen to authenticate using one of your backup codes.  Please note that a backup code can only be used once.&nbsp;
          <a
            href="#found"
            @click="handleClickFoundDevice"
          >
            Click here if you found your device.
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import MfaMixin from 'components/mfa/MfaMixin'
  import MfaConfigContainer from 'components/mfa/MfaConfigContainer'
  import Routes from 'lib/routes'
  import { mapGetters } from 'vuex'
  import { ajax } from 'jquery'

  export default {
    name: 'mfa-verification-container',

    components: {
      MfaConfigContainer,
    },

    mixins: [MfaMixin],

    props: {
      mfaData: Object,
    },

    data() {
      return {
        usingAuthApp: true,
        formData: {
          phone: '',
          otp: '',
        },
        formErrors: {},
        otpSentViaSms: null,
        submitText: 'Verify Code and Activate',
        showResendSms: false,
        otpLabel: "Enter your One-Time Code",
        showLostDeviceMessage: true,
      }
    },

    computed: {
      displaySubmit() {
        return !this.mfaData.configuring || this.usingAuthApp || this.otpSentViaSms
      },

      // Returns boolean that represents if currentUser is a superadmin
      superadmin() {
        return this.currentUser.role == 'superadmin'
      },

      // Returns boolean that represents if we should show the SMS option to the end user
      showSMSOption() {
        return !this.superadmin && this.mfaData.configuring && this.usingAuthApp
      },

      ...mapGetters({
        currentUser: 'user/current',
      }),
    },

    mounted() {
      this.usingAuthApp = this.mfaData.useAuthApp
      if (!this.mfaData.configuring){
        this.submitText = "Verify"
        if (!this.usingAuthApp) {
          this.handleSendOtpViaSms()
        }

        this.$nextTick(() => {
          this.$refs.otp.focusInput()
        })
      }
    },

    methods: {
      handleClickAuthAppConfig(){
        this.usingAuthApp = true
      },

      handleClickSmsConfig(){
        this.usingAuthApp = false
      },

      handleClickFoundDevice(){
        this.showLostDeviceMessage = true
        this.otpLabel = "Enter your One-Time Code"
      },

      handleClickLostDevice(){
        this.showLostDeviceMessage = false
        this.otpLabel = "Backup Code"
      },

      // user pressed enter in the OTP field
      onSubmitOtpForm(event) {
        event.preventDefault()
        this.handleSubmit()
      },

      handleSubmit() {
        this.formErrors = {}
        // NOTE: this.showLostDeviceMessage defaults to true and is set to
        // false in handleClickLostDevice which is called when the user
        // clicks the "lost device" link.  In this case, the otp is a backup code.
        // Hence, usingBackupCode: !this.showLostDeviceMessage.
        ajax({
          method: 'POST',
          url: Routes.mfa_verification_path,
          data: {
            // ditch camel case going back to rails
            configuring: this.mfaData.configuring,
            using_auth_app: this.usingAuthApp,
            using_backup_code: !this.showLostDeviceMessage,
            ...this.formData,
          },
        }).success( (json) => {
          if (json.success) {
            window.location.href = json.redirect
          } else {
            this.formErrors = json.errors || {}
            this.$refs.submitButton.stop()
          }
        })
      },

      handleOtpSentViaSms(data){
        this.formData.phone = data.phone
        this.otpSentViaSms = true
      },

      handleSendOtpViaSms(){
        this.sendOtpViaSms(this.mfaData.phone).success(() => {
        }).always(() => {
          this.deferredActionPostSms(() => this.showResendSms = true)
        })
      },

      handleClickSmsResend(){
        this.showResendSms = false
        this.handleSendOtpViaSms()
      },
    },
  }
</script>
