<template>
  <div>
    <WbModal
      ref="modal"
      watchPageContextVariable="update_groups_employees_id"
      :title="isAdding ? 'Add Employees to Group' : 'Remove Employees from Group'"
      :locked="$wait.is('employees_update_groups')"
      @modalHidden="handleModalHidden"
    >
      <v-wait for="employees_update_groups">
        <template #waiting>
          <Spinner :showLongWaitMessage="true" />
        </template>
        <div v-if="groupOptions.length > 0">
          <p ref="notification">{{ pluralizedEmployees }} will be {{ isAdding ? 'added to' : 'removed from' }} the group.</p>
          <div class="input-wrapper long-field">
            <WbMultiselect
              v-model="groupId"
              :selectOptions="groupOptions"
              :enableSearchField="true"
              :multiple="false"
              placeholder="Select a group"
            />
          </div>
        </div>
        <div
          v-else
          class="alert alert-danger"
        >
          No Employee Groups have been set up yet.
        </div>
      </v-wait>
      <template #footer>
        <button
          class="btn btn-default"
          :disabled="$wait.is('employees_update_groups')"
          @click="hide"
        >Cancel</button>
        <LaddaButton
          ref="submitButton"
          :disabled="!group"
          @click="submit"
        >
          {{ isAdding ? 'Add to group' : 'Remove from group' }}
        </LaddaButton>
      </template>
    </WbModal>
    <BulkActionListAlert
      ref="alert"
      :actionMethod="(employee) => true"
      permissionName="manage"
      permissionModule="employee_group_memberships"
      collectionModule="employees"
      :actionErrorMessage="changeGroupMessage"
      :displayNameLookupFn="(employee) => employee.first_name + ' ' + employee.last_name"
      :collectionViewName="this.collectionViewName"
      @continue="openModal"
      @cancelled="$emit('hidden')"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import * as mutate from 'vuex/mutation_types'

  import WbModal from 'components/common/WbModal'
  import BulkActionListAlert from 'components/common/BulkActionListAlert'
  import WbMultiselect from 'components/common/WbMultiselect'
  import Spinner from 'components/common/Spinner'
  import LaddaButton from 'components/common/LaddaButton'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import StringUtil from 'lib/util/string'

  export default {
    name: 'bulk-update-employee-groups-container',

    components: {
      BulkActionListAlert,
      LaddaButton,
      WbMultiselect,
      Spinner,
      WbModal,
    },

    props: {
      action: {
        type: String, // add / remove
        required: true,
      },
    },

    data() {
      return {
        groupId: null,
      }
    },

    computed: {
      collectionViewName() {
        return this.pageContext.collectionViewName || ''
      },

      employees() {
        return this.employeesGetBatch(this.pageContext.update_groups_employees_id)
      },

      group() {
        if (!this.groupId) {
          return null
        }
        return this.groups[this.groupId]
      },

      groupOptions() {
        return Object.keys(this.groups).map((id) => {
          return {
            value: id,
            label: this.groups[id].name,
          }
        })
      },

      isAdding() {
        return this.action == 'add'
      },

      changeGroupMessage() {
        return "The group(s) <strong>cannot be changed</strong> for these staff members because the staff members don't exist:"
      },

      pluralizedEmployees() {
        return StringUtil.pluralize(this.employees.length, 'employee')
      },

      ...mapGetters({
        pageContext: 'pageContext',
        employeesGetBatch: 'employees/getBatch',
      }),

      ...mapState({
        groups: state => state['groups'].collection,
      }),
    },

    methods: {
      handleModalHidden() {
        // Reset the data to allow dynamic reuse
        Object.assign(this.$data, this.$options.data())

        // Indicate the modal has closed
        this.$emit('hidden')
      },

      openModal(permittedEmployeeIds) {
        this.setPageContextKeys({ update_groups_employees_id: permittedEmployeeIds })
      },

      hide() {
        this.$refs.modal.hide()
      },

      submit() {
        this.$wait.start('employees_update_groups')

        const success = () => { // success callback
          this.$wait.end('employees_update_groups')

          const message = this.isAdding ?
            Locale.t('employee_group_memberships.add.success', this.employees.length, this.group.name) :
            Locale.t('employee_group_memberships.remove.success', this.employees.length, this.group.name)
          Util.showFlashNotice(message)

          this.$nextTick(() => {
            this.$refs.modal.hide()
          })

          this.$emit('success')
        }

        const fail = (xhr) => { // fail callback
          Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'users'), xhr)
          this.$refs.submitButton.stop()
          this.$wait.end('employees_update_groups')
        }

        const action = this.isAdding ? this.addEmployeesToGroup : this.removeEmployeesFromGroup
        action({
          employees: this.employees,
          group: this.group,
        }).then(success).catch(fail)
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        addEmployeesToGroup: 'groups/addEmployeesToGroup',
        removeEmployeesFromGroup: 'groups/removeEmployeesFromGroup',
      }),
    },
  }
</script>
