<template>
  <div class="list-container">
    <div class="row">
      <div class="col-lg-2">
        <h3>Staff List</h3>
        <br />
        <StaffListFilterContainer
          v-if="groupsLoaded"
          :collectionViewName="collectionViewName"
          :groupsOptions="allGroupsOptions"
        />
      </div>
      <div class="col-lg-10">
        <div class="wb-list staff-list-container">
          <div>
            <StaffListSlideout
              watchPageContextVariable="show_staff_list_slideout"
              :employee="currentEmployee"
              :initialSlideoutView="slideoutView"
              :initialFormsView="slideoutFormsView"
              :initialFormsStatusView="slideoutFormsStatusView"
              :groups="currentEmployeeData.groups"
              :documentAssignments="currentEmployeeData.document_assignments"
            />
          </div>
          <WbListHeader
            :filteredRowIds="filteredRowIds"
            :collectionViewName="collectionViewName"
            searchPlaceholder="Search Staff..."
            :selectable="true"
            :disabled="selectAllDisabled"
          >
            <template #bulkActions>
              <BulkActions
                :selectedEmployees="selectedEmployees"
                :canDownload="canDownload"
              />
            </template>
            <template #staticActions>
              <WbListPaginationFooter
                v-if="anyEmployees"
                :previousPage="pagination.previousPage"
                :nextPage="pagination.nextPage"
                :lastPage="pagination.lastPage"
                :totalItems="pagination.totalItems"
                :indexStart="pagination.indexStart"
                :indexEnd="pagination.indexEnd"
                :collectionViewName="collectionViewName"
              />
            </template>
            <template #selectAll>
              <WbAllYall
                v-if="pagination.totalItems > 0"
                :totalItemCount="pagination.totalItems"
                @selected-all-yall="selectedAllYall"
                @undo-all-yall="undoAllYall"
              />
            </template>
          </WbListHeader>
          <v-wait :for="loadingName">
            <template #waiting>
              <Spinner :showLongWaitMessage="true" />
            </template>
            <p
              v-if="!anyEmployees"
              ref="noStaffMessage"
              class="p-4 m-4 text-center"
            >
              No matching staff members
            </p>
            <ul class="list-group">
              <StaffListEmployeeItem
                v-for="employee in employees"
                :key="employee.id"
                :employee="employee"
                :collectionViewName="collectionViewName"
                :disabled="disableListItems"
                @wb-staff-page-show-slideout="showSlideout"
              />
            </ul>
            <WbListPaginationFooter
              v-if="showFooterPagination"
              :previousPage="pagination.previousPage"
              :nextPage="pagination.nextPage"
              :lastPage="pagination.lastPage"
              :totalItems="pagination.totalItems"
              :indexStart="pagination.indexStart"
              :indexEnd="pagination.indexEnd"
              :collectionViewName="collectionViewName"
            />
          </v-wait>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BulkActions from 'components/common/BulkActions'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import StaffListEmployeeItem from 'components/admin/staff_list/StaffListEmployeeItem'
  import StaffListFilterContainer from 'components/admin/staff_list/StaffListFilterContainer'
  import Spinner from 'components/common/Spinner'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import WbListHeader from 'components/common/WbListHeader'
  import WbAllYall from 'components/common/WbAllYall'
  import Util from 'lib/util'
  import PersistedCollectionViewWrapper from 'lib/persisted_collection_view_wrapper'
  import StaffListSlideout from 'components/admin/staff_list/StaffListSlideout'
  import Constants from 'lib/constants'
  import { debounce } from 'underscore'

  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_staff_list'

  export default {
    name: 'the-staff-list-root',
    components: {
      BulkActions,
      StaffListEmployeeItem,
      StaffListFilterContainer,
      Spinner,
      WbListPaginationFooter,
      WbListHeader,
      WbAllYall,
      StaffListSlideout,
    },

    props: {
      canDownload: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
        employees: [],
        pagination: {},
        loadingName: 'staff-page-employees',
        allStaffIds: [],
        disableListItems: false,
        selectAllDisabled: false,
        currentEmployee: null,
        currentEmployeeData: {},
        slideoutView: "groups",
        slideoutFormsView: "all",
        slideoutFormsStatusView: "all",
        adminRoles: ["admin", "superadmin"],
      }
    },

    computed: {
      filteredRowIds() {
        return (this.allStaffIds.length && this.allStaffIds) || this.employees.map(employee => employee.id)
      },

      selectedEmployees() {
        if (this.allStaffIds.length > 0) {
          return this.allStaffIds
        } else {
          return this.selected
        }
      },

      showFooterPagination() {
        return this.anyEmployees && this.selected.length < 1
      },

      anyEmployees() {
        return this.employees && this.employees.length > 0
      },

      selectedGroupIds() {
        return this.groupsLoaded ? this.filterValues.selected_groups : []
      },

      // The dropdown options for the list of groups
      allGroupsOptions() {
        return this.allGroups.map(group => { return { value: group.id, label: group.name }})
      },

      groupsLoaded() {
        return this.pageContext.groupsLoaded
      },

      ...mapGetters({
        pageContext: 'pageContext',
        allGroups: 'groups/all',
        filterValues: `${VUEX_COLLECTION_VIEW_NAME}/filterValues`,
        selected: `${VUEX_COLLECTION_VIEW_NAME}/selected`,
        sortOrder: `${VUEX_COLLECTION_VIEW_NAME}/sortOrder`,
        pageNumber: `${VUEX_COLLECTION_VIEW_NAME}/page`,
      }),
    },

    created () {
      this.$store.registerModule(this.collectionViewName, CollectionViewStore)

      // Bind the CollectionViewStore to PersistedPageState to sync filter values with url params
      this.persistedPageWrapper = new PersistedCollectionViewWrapper(this.$store, this.collectionViewName, [
        'employment_status',
        'groups',
        'onboarding_progress',
        'internal_onboarding_progress',
        'search',
        'sort',
        'last_login',
        'groups_filter_toggle',
      ])

      // Fetch all groups in the customer's account.
      this.fetchAllGroups().then(() => {
        this.setPageContextKeys({ groupsLoaded: true })
      }).catch((xhr) => {
        Util.genericAjaxError(this.$locale.t('errors.ajax.fetch', 'groups'), xhr)
      })
    },

    beforeUnmount() {
      // Clear the store selections so we have no issues and unregister to module as we don't need it any longer
      this.$store.unregisterModule(this.collectionViewName)

      // We also need to unsubscribe from the store, otherwise we will get duplicate requests to fetch employees when this component mounts again
      // https://vuex.vuejs.org/api/#subscribe
      this.unsubscribeFromStore()

      this.persistedPageWrapper.unsubscribe()
    },

    mounted () {
      this.setDefaultFilterValues()
      this.fetchEmployees()

      // Refresh the list whenever filters or page number change
      // A store subscription returns an unsubscribe function we can call on destroy so we store the reference to it here
      // https://vuex.vuejs.org/api/#subscribe
      this.unsubscribeFromStore = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case `${this.collectionViewName}/SET_FILTER_VALUE`:
            this.debounceFetchEmployees()
            break
          case `${this.collectionViewName}/SET_SORT_ORDER`:
          case `${this.collectionViewName}/SET_PAGE_NUMBER`:
          case `${this.collectionViewName}/CLEAR_FILTER_VALUES`:
            this.fetchEmployees()
            break
        }
      })
    },

    methods: {
      fetchEmployees() {
        this.startLoading()

        this.fetchAllStaff({
          filters: {
            ...this.filterValues,
            sort: this.sortOrder,
            page: this.pageNumber,
          },
        })
          .then((response) => {
            this.employees = response.employees

            // TODO: move pagination formatting to employees.js (similar to everify_cases.js)?
            this.pagination = {
              previousPage: response._links.previous ? this.parsePageNum(response._links.previous.href) : null,
              lastPage: (response._links.last && response._links.last.href) ? this.parsePageNum(response._links.last.href) : null,
              nextPage: (response._links.next && response._links.next.href) ? this.parsePageNum(response._links.next.href) : null,
              indexEnd: response._index_end,
              indexStart: response._index_start,
              itemsThisPage: response._items_this_page,
              page: response._page,
              totalItems: response._total_items,
            }

            // If any sort or filter happens, reset the All Y'all state
            this.undoAllYall()
          })
          .finally(this.endLoading)
      },

      // used to get all employee IDs (for bulk actions for "all y'all")
      fetchEmployeeIds() {
        this.startLoading()

        this.fetchAllStaffIds({
          filters: {
            ...this.filterValues,
          },
        }).then((response) => {
          this.allStaffIds = response.ids
          this.$store.dispatch(`${this.collectionViewName}/selectAll`, this.allStaffIds)

        }).finally(this.endLoading())
      },

      selectedAllYall() {
        this.disableListItems = true
        this.selectAllDisabled = true
        this.fetchEmployeeIds()
      },

      undoAllYall() {
        this.disableListItems = false
        this.selectAllDisabled = false
        this.allStaffIds = []
        this.$store.dispatch(`${this.collectionViewName}/selectAll`, [])
      },

      // Wait 200 milliseconds before submitting request for a new batch of employees.
      //
      // This helps reduce the number of requests sent to the API. For instance, if the user is typing something into
      // the search form, this prevents a request from being sent after every character. Instead, it waits until there
      // is a 500 millisecond delay (presumably when the user has finished typing) and then fetches the results.
      debounceFetchEmployees: debounce(function() {
        this.fetchEmployees()
      }, Constants.DEFAULT_REQUEST_THROTTLE),

      // FIXME: DRY this up
      parsePageNum(link) {
        // find page number in a url that looks like: /api/employees/staff_list?page=123&sort=ABC&search=KEYWORD
        const pageNum = link.match(/(\/api\/employees\/staff_list.*)(page=)(\d*)(.*)/)[3]
        return parseInt(pageNum, 10)
      },

      startLoading() {
        this.$wait.start(this.loadingName)
      },

      endLoading() {
        this.$wait.end(this.loadingName)
      },

      showSlideout({employeeID, slideoutView, formsView, section}){
        // NOTE: currentEmployee and currentEmployeeData are passed to the slideout as props.
        this.currentEmployee = this.employees.find(e => e.id == employeeID)
        this.currentEmployeeData = {}

        this.slideoutView = slideoutView //groups or forms
        this.slideoutFormsView = formsView // non-internal or internal
        if (section)
        {this.slideoutFormsStatusView = section.legend_key} //breakout chart section

        this.fetchStaffListSlideoutData({employeeID}).then((response) => {

          this.currentEmployeeData = response
        })
        // show the slideout/modal
        this.setPageContextKeys({ show_staff_list_slideout: employeeID })
      },

      setDefaultFilterValues() {
        // Set default filter to show active staff only if no employment status has been selected
        // - also resets page number to 1
        if (!this.filterValues.employment_status) {
          this.$store.dispatch(`${this.collectionViewName}/setFilterValue`, {
            filter_key: 'employment_status',
            value: 'active',
          })
        }

        // Set default sort option to last_name if no sort option has been selected
        // - also resets page number to 1
        if (!this.sortOrder) {
          this.$store.dispatch(`${this.collectionViewName}/setSortOrder`, { sortOrder: 'last_name' })
        }
      },

      ...mapActions({
        fetchAllStaffIds: 'employees/fetchAllStaffIds',
        fetchAllStaff: 'employees/fetchAllStaff',
        fetchStaffListSlideoutData: 'employees/fetchStaffListSlideoutData',
        fetchAllGroups: 'groups/fetchAll',
      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
