<template>
  <div
    v-tooltip.top="tooltipMsg"
    class="wb-breakdown-chart__section"
    :class="`wb-breakdown-chart__section--${this.variant}`"
    :style="sectionStyle"
    @click="handleSectionClick"
  />
</template>

<script>
  import { BREAKDOWN_VARIANTS } from 'components/admin/dashboard/BreakdownChartConstants'

  export default {
    name: 'wb-breakdown-chart-section',
    props: {
      legend_key: {
        type: String,
        required: true,
      },

      width: {
        type: Number,
        required: true,
        default: 0,
      },

      // The color and type of this section
      variant: {
        type: String,
        required: true,
        validator: value => {
          return BREAKDOWN_VARIANTS.ALL.includes(value)
        },
      },

      tooltipMsg: {
        type: String,
        required: false,
      },
    },

    computed: {
      sectionStyle () {
        return {
          width: `${this.width}%`,
        }
      },
    },

    methods: {
      handleSectionClick(e) {
        // NOTE: stop propogation so that any parent component (e.g. ListItem) doesn't handle this event.
        e.stopPropagation()
        this.$emit('click', {
          legend_key: this.legend_key,
        })
      },
    },
  }
</script>
