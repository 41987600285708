import Routes from 'lib/routes'
import RemoteFormValidation from 'lib/remote_form_validation'
import FileView from 'views/common/file_view'
import AttachedFile from 'models/attached_file'
import FormsUtil from 'lib/util/forms'
import ImageEditorView from 'views/common/image_editor_view'
import ErrorService from 'lib/error_service'
import Locale from 'lib/locale'


// View used on new I-9 submissions to upload an attachment.
//
// model - (I9Attachment) the actual attachment with documentation_key prefilled. Note that model MUST
//         have its docSetChoice defined. If a legacy I9 is being displayed, a dummy (null object) docSetChoice
//         must be set.
//
// Options
//    context (Backbone.Model) - REQUIRED. application context (wizard / parent container). Attributes referenced are:
//      submitButton - jQuery reference to primary submit button
//      reviewMode - boolean whether or not this is in review mode
//
export default class EditAttachmentView extends Backbone.Marionette.LayoutView.extend({
  template: '#i9-edit-attachment-tmpl',
  className: 'i9-documentation i9-attachment',

  ui: {
    form: 'form',
    allAsterix: 'label abbr[title=required]',
    inputs: 'input',
    receiptContainer: '.form-group.i9_attachment_receipt',
    receiptCheckbox: '#i9_attachment_receipt',
    documentTitleSelect: 'select[name="i9_attachment[document_title]"]',
    issuingAuthoritySelect: 'select[name="i9_attachment[issuing_authority]"]',
    documentNumberInput: 'input[name="i9_attachment[document_number]"]',
    expirationDateInput: 'input[name="i9_attachment[expiration_date]"]',
    expirationDateHint: '.form-group.i9_attachment_expiration_date p.help-block',

    fileFrontPreviewContainer: '.file-front-preview',
    fileBackPreviewContainer: '.file-back-preview',
    replaceFilesLink: '.replace-files',

    attachedFilePreviewElements: '.attached-file-preview',
    attachedFileUploaderElements: '.attached-file-uploader',

    documentTitle: '[name="i9_attachment[document_title]"]',
    issuingAuthority: '[name="i9_attachment[issuing_authority]"]',
  },

  bindings: {
    // NOTE: intentionally omitting 'input' prefix on the first 2 selectors so that bindings work out of the box for
    // both text-based and select-based inputs.
    '[name="i9_attachment[document_title]"]': 'document_title',
    '[name="i9_attachment[issuing_authority]"]': 'issuing_authority',
    'input[name="i9_attachment[document_number]"]': 'document_number',
    'input[name="i9_attachment[expiration_date]"]': 'expiration_date',
    'input:checkbox[name="i9_attachment[receipt]"]': 'receipt',

    'input[name="i9_attachment[file_front_url]"]': 'file_front_url',
    'input[name="i9_attachment[file_front_cache]"]': 'file_front_cache',
    'input[name="i9_attachment[file_front_content_type]"]': 'file_front_content_type',

    'input[name="i9_attachment[file_back_url]"]': 'file_back_url',
    'input[name="i9_attachment[file_back_cache]"]': 'file_back_cache',
    'input[name="i9_attachment[file_back_content_type]"]': 'file_back_content_type',
  },

  events: {
    'click @ui.replaceFilesLink': '_replaceFiles',
  },

  modelEvents: {
    'change:receipt': 'receiptChanged',
    'change:document_title': '_checkOptionalDocumentSelect',
  },

  templateHelpers: function() {
    return {
      docSetChoice: this.model.docSetChoice,
    }
  },
}) {
  // Constructor
  //
  initialize() {
    this.context = this.getOption('context')
    this.employerReviewMode = this.context.get('reviewMode')
    this.skipVeriff = gon.skip_veriff_status // load from gon instead of backbone setting via Rails view

    // Only set value if we don't have one, otherwise this will get reset to defaults when end user clicks Next and Back
    if (!this.model.has("skip_document_verify")) {
      // If we are in employer review or skip veriff is true, do not validate, otherwise default is to not skip
      this.model.set("skip_document_verify", this.employerReviewMode || this.skipVeriff || false)
    }

    // Safety check: this view expects docSetChoice. Alert if we missed a corner case somewhere.
    if (!this.model.docSetChoice) {
      new ErrorService("Expected to find docSetChoice but none present", "MissingDocSetChoice").report({
        model: this.model,
      })
    }
  }

  onRender() {
    $.runInitializers(this.$el)

    this.$el.addClass(`doc-${this.model.get('documentation_key') || 'unknown'}`)

    // Re-initialize Document Title and Issuing Authority as <select> inputs if an Array of values is supplied
    // in the original I9DocumentationChoice. This lets the user choose between several pre-defined values for each
    // field.
    //
    this._renderDocumentTitleInput()
    this._renderIssuingAuthorityInput()
    this._renderDocumentNumberInput()
    this._renderExpirationDateInput()
    this._renderExpirationDateHint()

    this._checkOptionalDocumentSelect()

    this.stickit()

    // HACKFIX: Some I-9s were submitted with invalid Issuing Authorities.
    // See this PR for background: https://github.com/WorkBright/Rails-App/pull/68
    //
    // In edit mode (admin reivew mode), when this view is rendered, the model data could mismatch what the
    // <SELECT> shows as the selected option. That is:
    //
    //    this.model.get('issuing_authority') => ["Social Security Administration", "U.S. Dept. of Health and Human Services", "Social Security Board", "Dept. of Health, Education and Welfare"]
    //
    //        whereas:
    //
    //    this.ui.issuingAuthoritySelect.val() => "Social Security Administration"
    //
    //        and:
    //
    //    the UI will show "Social Security Administration" (the first option) as being selected
    //
    // In order to fix this, we simply trigger a 'change' on the issuing authority <SELECT> so that stickit sets the
    // value on the model to reflect what the UI is showing. This issue only affected Issuing Authority and not
    // Document Title.
    if (this.model.docSetChoice.hasMultiplePrefilledValues('issuing_authority') &&
        (this.ui.issuingAuthoritySelect.val() !== this.model.get('issuing_authority')))
    {
      this.ui.issuingAuthoritySelect.trigger('change')
    }

    if (!this._shouldRenderReceiptCheckbox) {
      this.ui.receiptCheckbox.trigger('click').trigger('change')
      this.ui.receiptContainer.parent().removeClass('d-flex').hide()
    }
  }

  onShow() {
    if (this.employerReviewMode) {
      this._showReviewMode()
    } else {
      // In non-review mode we always render the uploader components
      this._renderUploadComponents()
    }

    new RemoteFormValidation({
      resourceName: 'i9_attachment',
      model: this.model,
      timeout: 30000, // in ms
      formEl: this.ui.form,
      validations: {
        'i9_attachment[file_front_cache]': { excluded: false, validators: { wbRemote: { dropzone: true } } },
        'i9_attachment[file_back_cache]': { excluded: false, validators: { wbRemote: { dropzone: true } } },
        'i9_attachment[document_title]': { validators: { wbRemote: {} } },
        'i9_attachment[issuing_authority]': { validators: { wbRemote: {} } },
        'i9_attachment[document_number]': { validators: { wbRemote: {} } },
        'i9_attachment[expiration_date]': { validators: { wbRemote: {} } },
      },
      successCallback: this.onRemoteValidateSuccess.bind(this),
      failedCallback: this.onRemoteValidateFailed.bind(this),
      errorCallback: this.onRemoteValidateError.bind(this),
      path: Routes.validate_attachment_i9_submissions_path,
      extraData: { i9_ssn_card_back_file_optional: this.getOption("i9_ssn_card_back_file_optional") },
    })

    if (this.doDocumentValidation()) {
      if (!this.model.get("show_ocr_fields_anyway")) { // show_ocr_fields_anyway is override flag
        $(".remote-countersign-ocr-fields").hide()
      }
    }
  }

  onBeforeDestroy() {
    if (this._frontFileView) {
      this._frontFileView.destroy()
    }

    if (this._backFileView) {
      this._backFileView.destroy()
    }
  }

  validate(validationCompleteCallback) {
    this.validationCompleteCallback = validationCompleteCallback
    this.ui.form.submit()
  }

  onRemoteValidateSuccess(response) {
    FormsUtil.clearFormInvalidNotification(this.ui.form)

    // only populate the fields if they are present
    if (response.responseJSON) {
      // Not sure if this is the best way but this does populate these fields with the JSON response values
      this.ui.documentNumberInput.val(response.responseJSON.i9_attachment["document_number"]).trigger('change')
      this.ui.expirationDateInput.val(response.responseJSON.i9_attachment["expiration_date"]).trigger('change')
    }

    this.validationCompleteCallback(true)
  }

  onRemoteValidateFailed(response) {
    const options = {}
    if (response.display_hidden_fields) {  // set true in I9DocumentValidationService#invalid_response
      $(".remote-countersign-ocr-fields").show() // show all hidden fields

      this.model.set("skip_document_verify", true) // it failed once, let's not try again
      this.model.set("show_ocr_fields_anyway", true) // needed to figure out hide/show of OCR fields

      const doc = response.i9_attachment

      if (doc) {
        this.model.set("document_number", doc.document_number)
        this.model.set("expiration_date", doc.expiration_date)
      }
      options.message = Locale.t('errors.form.ocr')

    }

    this.validationCompleteCallback(false)
    FormsUtil.showFormInvalidNotification(this.ui.form, options)
  }

  onRemoteValidateError() {
    this.validationCompleteCallback(false)
  }

  // only do document validation if these three are true
  doDocumentValidation() {
    return (gon.current_account.remoteCountersignOn && this.model.get('validation_enabled')) && !this.model.get("skip_document_verify")
  }

  // Callback when the 'receipt' attribute changes. This is only available in admin review mode.
  receiptChanged() {
    this._renderDocumentTitleInput()
    this._renderDocumentNumberInput()
    this._renderExpirationDateInput()
    this._renderExpirationDateHint()
  }

  // Initializes DZOperator uploaders
  _renderUploadComponents() {
    this.ui.form.dzoperator({
      submitButton: this.context.get('submitButton'),
      dzOptions: {
        presignURL: Routes.presign_uploads_path,
        previewTemplate: $("#dropzone-template").html(),
        imageEditorView: ImageEditorView,
      },
    })
  }

  // Render proper UI for Employer Review mode
  _showReviewMode() {
    // If an admin chose to Change Documentation, when this view is re-rendered, we will want the user to be able to
    // upload files for the newly-selected attachment. Otherwise, if the model is already filled out correctly, we
    // render a simplified "Preview" view.
    if (this._uploadsNeeded) {
      this._renderUploadComponents()
    } else {
      this._renderFilePreviewComponents()
    }

    // HACKFIX: In review mode, we may be rendering multiple attachments on the same screen. Because of how the
    // Underscore templates work, the IDs of the various input and select tags will not be unique per attachment, and so
    // the label for="" attributes will be ambiguous. Clicking on a label will always put focus on the first attachment.
    // This is especially bad in the case of checkboxes, where the user is much more likely to click on the label rather
    // than the box itself (thus causing the wrong checkbox to be selected!)
    //
    // This hackfix appends the unique attachment index (provided via childViewOptions in EmployerUpdateDocumentationView)
    // to the IDs of the both the input elements as well as the for="" attributes of their respective labels. This should
    // be safe IF we always use `name` attributes to reference inputs for validation, this.ui, etc. rather than the `id`.
    const viewIndex = this.getOption('attachmentViewIndex')
    this.$('input:not([type="hidden"]):not([type="file"]),select').each(function() {
      $(this).attr('id', `${$(this).attr('id')}${viewIndex}`)
    })
    this.$('label').each(function() {
      $(this).attr('for', `${$(this).attr('for')}${viewIndex}`)
    })
  }

  // Review mode only: renders file preview components instead of the Uploader components. These views are hidden,
  // and uploader views are subsequently shown, based on the presence of the class `.attached-file-preview-only`.
  _renderFilePreviewComponents() {
    this.$el.addClass('attached-file-preview-only')

    if (!this._frontFileView){
      const fileFront = new AttachedFile(this.model.fileFront())
      this._frontFileView = new FileView({ el: this.ui.fileFrontPreviewContainer, model: fileFront })
      this._frontFileView.render()
    }

    if (!this._fileBackView && this._hasBackFile()) {
      const fileBack = new AttachedFile(this.model.fileBack())
      this._fileBackView = new FileView({ el: this.ui.fileBackPreviewContainer, model: fileBack })
      this._fileBackView.render()
    }
  }

  // In Employer Review mode, if the admin simply wants to replace the files for a given attachment,
  // we clear out the file data and switch into Upload Mode. Note that once the user is switched into this mode, there
  // is no way to go back into 'Preview' mode.
  _replaceFiles() {
    this.$el.removeClass('attached-file-preview-only')

    this.model.clearFiles()

    setTimeout(() => { this._renderUploadComponents() }) // setTimeout allows clearFiles event to propagate to hidden fields
  }

  // Whether or not the I9Attachment has a back file
  //
  // Returns - Boolean
  _hasBackFile() {
    const fileBackUrl = this.model.get('file_back_url')
    return (!!fileBackUrl && fileBackUrl.length > 0)
  }

  // Checks the value of the Document Title select and if 'N/A' is selected for an optional document, it disables and
  // autofills values for the rest of the fields on the screen.
  _checkOptionalDocumentSelect() {
    // Short-circuit if not an optional doc to begin with
    if (!this.model.docSetChoice.isOptional) {
      return
    }

    const isDisabled = (this.ui.documentTitleSelect.val() == 'N/A')
    const formFields = this.ui.form.find('input:not([type="file"]):not([type="hidden"]):not([type="checkbox"])')

    this.ui.allAsterix.toggle(!isDisabled)

    if (isDisabled) {
      formFields.attr('disabled', 'disabled').val('N/A').trigger('change')

      this.ui.form.find('.attached-file-uploader .control-label').each(function() {
        $('<SPAN>').text("No upload is necessary if document is not applicable.")
          .attr('class', 'upload-na')
          .appendTo(this)
      })
    } else {
      formFields.removeAttr('disabled')

      // If going from N/A to something else, clear out the N/A values. Otherwise, leave user-typed values in place.
      formFields.each((_, el) => {
        const $el = $(el)
        if ($el.val() == 'N/A') {
          $el.val('').trigger('change')
        }
      })

      this.ui.form.find('.attached-file-uploader .control-label > span.upload-na').remove()
    }

    formFields.toggleClass('disabled', isDisabled)
    this.ui.form.find('.attached-file-uploader').toggleClass('disabled', isDisabled)
  }

  // Renders the various states of the expiration date input
  //
  // Review mode:
  //  - Enabled always
  //
  // Non-review mode:
  //  - Enabled if receipt checkbox is selected
  //  - Disabled if the docSetChoice says it's not necessary
  //  - It will already be prefilled with N/A from code that sets up this view
  _renderExpirationDateInput() {
    const docSetChoice = this.model.docSetChoice
    const isReceipt = this.model.get('receipt')

    if (this.employerReviewMode) {
      this.ui.expirationDateInput.removeAttr('disabled').removeClass('disabled')
    } else {
      // If expiration date is `false` (strictly Boolean), we can lock the field.
      if (!docSetChoice.isExpirationDateRequired) {
        this.ui.expirationDateInput.attr('disabled', 'disabled').addClass('disabled')
      }

      // If receipt is selected, we always enable the expiration date input
      if (isReceipt) {
        this.ui.expirationDateInput.removeAttr('disabled').removeClass('disabled')
      }
    }
  }

  // Renders the various states of the expiration date hint text. Since only admins are able to indicate that the
  // document is a receipt, isReceipt also implies review mode.
  //--
  // FIXME: This is rendered mostly by model changes. Good candidate for moving into stickit.
  _renderExpirationDateHint() {
    const isReceipt = this.model.get('receipt')
    if (isReceipt) {
      // save original so that we can restore original Ruby-provided hint if user unchecks receipt box
      this.originalExpirationDateHintText = this.ui.expirationDateHint.text()
      this.ui.expirationDateHint.text(`
        Enter the date the receipt expires. If the receipt does not show an expiration date, enter 90 days from
        the staff member's Start Date.
      `)
      return
    }

    const docSetChoice = this.model.docSetChoice

    if (!docSetChoice.isExpirationDateRequired) {
      const expDateHintSuffix = this.employerReviewMode ? ' Enter N/A instead.' : ''
      this.ui.expirationDateHint.text(`You do not need to enter an expiration date for this document.${expDateHintSuffix}`)
    } else if (this.originalExpirationDateHintText) {
      this.ui.expirationDateHint.text(this.originalExpirationDateHintText)
    }
  }

  // Renders or re-renders the Document Title dropdown with the proper values from the I9DocumentationChoice when it is
  // an Array.
  _renderDocumentTitleInput() {
    const docSetChoice = this.model.docSetChoice
    const isReceipt = this.model.get('receipt')
    const hasMultipleDocumentTitles = docSetChoice.hasMultiplePrefilledValues('document_title')

    if (hasMultipleDocumentTitles) {
      // HACKFIX iOS Webkit - On certain versions (tested on iOS 10.3), webkit renders an empty <optgroup> when no
      // initial <option>s are present in the <select>. This conflicts with the dynamically-created options below
      // causing issues with rendering and stickit bindings. Simply removing any <optgroup>s which are present seems
      // to resolve it:
      this.ui.documentTitleSelect.children('optgroup').remove()

      // Clear existing values because, unlike Issuing Authority, these titles may get re-rendered if in Employer Review
      // mode and the admin checks the Receipt checkbox
      this.ui.documentTitleSelect.children('option').remove()

      for (let value of docSetChoice.get('document_title')) {
        value = `${isReceipt && this.model.docSetChoice.shouldPrependReceiptText ? 'Receipt - ' : ''}${value}`

        this.ui.documentTitleSelect.append(new Option(value, value, false, false))
      }

      // ensure dropdown reflects model value since we rendered choices above
      this.ui.documentTitleSelect.val(this.model.get('document_title'))
    }

    this.ui.documentTitle.prop('disabled', this._shouldDisableDocumentTitle)
  }

  // Applies Document Number input mask (if docSetChoice specifies one).
  _renderDocumentNumberInput() {
    const docSetChoice = this.model.docSetChoice

    if (!docSetChoice.documentNumberInputMask) {
      return
    }

    const isReceipt = this.model.get('receipt')
    if (isReceipt) {
      this.ui.documentNumberInput.inputmask('remove')
    } else {
      this.ui.documentNumberInput.inputmask({
        mask: docSetChoice.documentNumberInputMask,
        placeholder: '',
      })
    }

    this.ui.documentNumberInput.trigger('change')  // keeps model in sync
  }

  // Sets up the Issuing Authority dropdown with the proper values from the I9DocumentationChoice.
  _renderIssuingAuthorityInput() {
    const docSetChoice = this.model.docSetChoice

    if (docSetChoice.hasMultiplePrefilledValues('issuing_authority')) {
      // HACKFIX iOS Webkit - On certain versions (tested on iOS 10.3), webkit renders an empty <optgroup> when no
      // initial <option>s are present in the <select>. This conflicts with the dynamically-created options below
      // causing issues with rendering and stickit bindings. Simply removing any <optgroup>s which are present seems
      // to resolve it:
      this.ui.issuingAuthoritySelect.children('optgroup').remove()

      for (const value of docSetChoice.get('issuing_authority')) {
        this.ui.issuingAuthoritySelect.append(new Option(value, value, false, false))
      }

      // ensure dropdown reflects model value since we rendered choices above
      this.ui.issuingAuthoritySelect.val(this.model.get('issuing_authority'))
    }

    this.ui.issuingAuthority.prop('disabled', docSetChoice.get('key') === "under18_none")
  }

  // Determines whether the user should be expected to upload Front and/or Back files for this attachment. For
  // an employee submitting a new I-9,
  //
  // Returns - Boolean
  get _uploadsNeeded() {
    // Uploads are never needed if the front_file is not required
    if (!this.model.docSetChoice.isFrontFileRequired) {
      return false
    }

    // Uploads are needed if either of these conditions aren't met:
    //  - Front file is missing
    //  - Back file is required + missing

    const frontFileOK = !!this.model.fileFront()
    const backFileOK = this.model.docSetChoice.isBackFileRequired ? !!this.model.fileBack() : true

    return !(frontFileOK && backFileOK)
  }

  /**
   * Determines whether the document title field should be disabled
   *
   * Should be enabled when employment_auth_by_dhs document key was selected
   * Should be disabled when under18_none document key was selected
   * Should be disabled when field is a text input in staff mode
   *
   * In all other situations, it should be enabled
   *
   * @returns {boolean}
   */
  get _shouldDisableDocumentTitle() {
    const docSetChoice = this.model.docSetChoice
    const docSetChoiceKey = docSetChoice.get('key')
    const hasMultipleDocumentTitles = docSetChoice.hasMultiplePrefilledValues('document_title')
    const isUnder18None = docSetChoiceKey === 'under18_none'
    const isEmploymentAuthByDhs = docSetChoiceKey === 'employment_auth_by_dhs'
    const isOtherAcceptableReceipt = docSetChoiceKey === 'other_acceptable_receipt'
    const textInputNotEditable = !this.employerReviewMode && !hasMultipleDocumentTitles && !isEmploymentAuthByDhs && !isOtherAcceptableReceipt

    return isUnder18None || textInputNotEditable
  }

  /**
   * Determines whether the receipt checkbox should be visible or not
   *
   * Should be always visible to the admin
   * Should not be visible to the staff if document_key is other_acceptable_receipt
   *
   * @returns {boolean}
   */
  get _shouldRenderReceiptCheckbox() {
    const docSetChoiceKey = this.model.docSetChoice.get('key')

    if (this.employerReviewMode) {
      return true
    }

    return docSetChoiceKey !== 'other_acceptable_receipt'
  }
}
