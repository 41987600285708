<template>
  <div class="row">
    <div class="col-sm-4 mb-2">
      <div class="d-flex align-items-center">
        <i
          ref="status-icon"
          class="fa pr-3"
          :class="isComplete ? 'fa-check text-success' : 'fa-clock-o text-muted'"
        />
        <div>
          <strong>{{downloadRequest.name}}</strong>
          <div ref="download-text">{{completeText}}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <p v-if="downloadRequest.description">{{ downloadRequest.description }}</p>
    </div>
    <div
      ref="status"
      class="col-sm-4 text-right"
    >
      <button
        ref="download"
        class="btn btn-link"
        @click="handleDownloadClick"
      >
        View download details
      </button>
    </div>
  </div>
</template>
<script>
  import DateTime from 'lib/util/datetime'
  export default {
    name: 'download-request',
    props: {
      downloadRequest: {
        type: Object,
        required: true,
      },
    },
    computed: {
      createdDateDisplay () {
        return DateTime.formatDate(this.downloadRequest.created_at)
      },
      completeCount () {
        return isNaN(this.downloadRequest.complete_downloads_count) ? '--' : this.downloadRequest.complete_downloads_count
      },
      completeText () {
        return this.isComplete ? 'All items are ready to download' : `${this.completeCount} of ${this.totalCount} items are ready to download`
      },
      isComplete () {
        return this.totalCount === this.completeCount && this.totalCount !== 0 && this.totalCount !== '--'
      },
      totalCount () {
        return isNaN(this.downloadRequest.downloads_count) ? '--' : this.downloadRequest.downloads_count
      },
    },
    methods: {
      handleDownloadClick () {
        this.$emit('download', this.downloadRequest)
      },
    },
  }
</script>
