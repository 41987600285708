<template>
  <div
    v-if="hasSufficientData"
    class="mt-4"
  >
    <ul
      ref="logContent"
      class="list-group wb-list"
    >
      <ListItem
        :secondaryActions="false"
      >
        <div class="wb-list__content">
          <div class="wb-list__title-slideout">
            <div class="mb-3">
              <WbSimpleDateTime
                ref="logDate"
                :value="resolvedDate"
              />
            </div>
            <div>
              <strong ref="logStatus">
                {{ verificationStatus }}
              </strong>
            </div>
            <div
              v-if="adminName"
              ref="adminName"
            >
              by {{ adminName }}
            </div>
            <div
              v-if="comments"
              ref="comments"
              class="mt-2"
            >
              Comments: {{ comments }}
            </div>
          </div>
        </div>
      </ListItem>
    </ul>
  </div>
</template>
<script>
  import Constants from 'lib/constants'
  import ListItem from 'components/common/ListItem'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import {
    getDocumentVerificationLabel,
  } from "decorators/document_verification_decorator"

  export default {
    components: {
      ListItem,
      WbSimpleDateTime,
    },

    props: {
      submission: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        dateFormat: Constants.DATE_EXT_NO_WEEKDAY_FORMAT,
        isLoading: false,
      }
    },

    computed: {
      adminName () {
        return this.submission.document_verification_resolution?.actor_name
      },

      resolvedDate () {
        return this.submission.document_verification_resolution?.resolved_at
      },

      comments () {
        return this.submission.document_verification_resolution?.comments
      },

      hasSufficientData () {
        return !!this.adminName && !!this.resolvedDate
      },

      verificationStatus () {
        return getDocumentVerificationLabel(this.submission.document_verification_result)
      },
    },
  }
</script>
