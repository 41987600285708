<template>
  <WbCard
    ref="form"
    class="form-horizontal"
  >
    <div class="required">
      <FormFieldInput
        ref="name"
        v-model="formData.name"
        placeholder="Staff submissions"
        name="name"
        orientation="horizontal"
        label="Name"
        :required="true"
        :errors="errors"
      />
      <FormFieldInput
        ref="url"
        v-model="formData.url"
        placeholder="https://website.com/webhooks"
        name="url"
        orientation="horizontal"
        label="URL"
        :required="true"
        :errors="errors"
      />
      <FormFieldRadio
        ref="enabledFieldOptions"
        v-model="formData.enabled"
        name="enabled"
        orientation="horizontal"
        label="Enabled"
        :required="true"
        :errors="errors"
        :options="enabledOptions"
      />
    </div>
    <div>
      <h4 class="text-strong">
        Authentication (optional)
      </h4>
      <p class="help-block">
        Add additional security to your webhook with a username and password.
      </p>
      <hr />
      <FormFieldInput
        ref="authUsername"
        v-model="formData.auth_username"
        name="auth_username"
        orientation="horizontal"
        label="Username"
        :errors="errors"
      />
      <FormFieldInput
        ref="authPassword"
        v-model="formData.auth_password"
        name="auth_password"
        type="password"
        orientation="horizontal"
        label="Password"
        :errors="errors"
      />
    </div>
    <div>
      <h4 class="text-strong">
        Event Notifications
      </h4>
      <p class="help-block">
        Select the event notifications you would like your webhook endpoint to receive by selecting from the checkboxes below:
      </p>
      <hr />
      <div
        ref="events"
        class="form-group"
      >
        <FormLabel
          label="Events"
          orientation="horizontal"
        />
        <FormFieldCheckbox
          v-for="option in systemEventsOptions"
          :id="option.value"
          :key="option.value"
          name="events"
          :label="option.label"
          :errors="errors"
          :value="isEventChecked(option)"
          @input="handleCheckboxChange(option)"
        />
        <FormFieldDropdown
          v-if="submittedFormEventSelected"
          v-model="formData.form_submitted_scope"
          name="form_submitted_scope"
          :errors="errors"
          :options="activeFormOptions"
          label="Employee Submitted a Form: Select a form"
          orientation="horizontal"
        />
      </div>
    </div>
    <Toolbar reverse>
      <div class="btn-sequence">
        <WbButton
          ref="saveButton"
          label="Save"
          :isLoading="isLoading"
          @click="handleSubmit"
        />
        <WbButton
          ref="cancelButton"
          context="default"
          label="Cancel"
          @click="handleCancel"
        />
      </div>
    </Toolbar>
  </WbCard>
</template>

<script>
  import WbCard from 'components/common/WbCard'
  import FormLabel from 'components/common/FormLabel'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import WbButton from 'components/common/WbButton'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldDropdown from 'components/common/FormFieldDropdown'
  import Toolbar from 'components/common/Toolbar'
  import { mapActions } from 'vuex'
  import Util from 'lib/util'

  export default {
    name: "webhook-form",
    components: {
      FormFieldDropdown,
      FormFieldCheckbox,
      WbButton,
      FormFieldRadio,
      FormFieldInput,
      FormLabel,
      Toolbar,
      WbCard,
    },
    props: {
      webhook: Object,
      isEdit: Boolean,
      systemEventsOptions: Array,
      documentsForAccount: Array,
    },
    data() {
      return {
        errors: {},
        formData: {
          id: this.webhook?.id,
          name: this.webhook?.name,
          url: this.webhook?.url,
          auth_username: this.webhook?.auth_username,
          auth_password: this.webhook?.auth_password,
          events: this.webhook?.events || [],
          enabled: this.webhook?.enabled,
          form_submitted_scope: this.webhook?.form_submitted_scope || "",
        },
        isLoading: false,
        enabledOptions: [
          {label: 'Yes', value: true},
          {label: 'No', value: false},
        ],
      }
    },
    computed: {
      payload() {
        return {
          webhook: {
            name: this.formData.name,
            url: this.formData.url,
            auth_username: this.formData.auth_username,
            auth_password: this.formData.auth_password,
            events: this.formData.events,
            enabled: this.formData.enabled,
            form_submitted_scope: this.formData.form_submitted_scope,
          },
        }
      },
      submittedFormEventSelected() {
        return this.formData?.events.includes('employee.form_submitted')
      },
      activeFormOptions() {
        return [{label: 'Any', value: ""}, ...this.documentsForAccount]
      },
    },
    methods: {
      ...mapActions({
        createWebhook: 'webhooks/create',
        updateWebhook: 'webhooks/update',
      }),
      handleCancel() {
        window.location.href = this.$routes.webhooks_path
      },
      isEventChecked(systemEvent) {
        return this.formData.events.includes(systemEvent.value)
      },
      handleCheckboxChange(systemEvent) {
        const events = this.formData.events
        if (events.includes(systemEvent.value)) {
          const eventIdx = events.indexOf(systemEvent.value)

          events.splice(eventIdx, 1)
        } else {
          events.push(systemEvent.value)
        }
      },
      async handleSubmit() {
        const action = this.isEdit ? this.sendUpdate : this.sendCreate

        try {
          this.isLoading = true
          await action()

          Util.navigateAndShowFlashNotice(this.$routes.webhooks_path, `Webhook successfully ${this.isEdit ? 'updated' : 'created'}`)
        } catch (xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError(`An error occurred trying to ${this.isEdit ? 'update' : 'create'} this webhook`, xhr)
          }
        } finally {
          this.isLoading = false
        }
      },
      sendUpdate () {
        return this.updateWebhook({ id: this.formData.id, data: this.payload })
      },
      sendCreate () {
        return this.createWebhook({ data: this.payload })
      },
    },
  }
</script>
