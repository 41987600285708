<template>
  <div>
    <div class="row mt-4">
      <div class="col-xs-4">
        <a
          class="past-imports-link"
          :href="this.$routes.user_imports_path"
        >
          <i class="fa fa-long-arrow-left" />
          View Past Imports
        </a>
      </div>
      <div class="col-xs-4 text-center">
        <h2 class="page mt-0">
          Bulk Staff Import
        </h2>
      </div>
    </div>
    <ul class="nav-wizard pl-0">
      <li :class="{ active: isActive('upload') }">
        <span>
          1. Upload File
        </span>
      </li>
      <li :class="{ active: isActive('columns') }">
        <span>
          2. Match Columns
        </span>
      </li>
      <li :class="{ active: isActive('review') || isActive('validate') }">
        <span>
          3. Review
        </span>
      </li>
      <li :class="{ active: isActive('import') }">
        <span>
          4. Import
        </span>
      </li>
      <li :class="{ active: isActive('complete') }">
        <span>
          5. Complete
        </span>
      </li>
    </ul>
  </div>
</template>

<script lang="babel">
  export default {
    name: 'user-import-page-header',
    props: {
      wizardStep: {
        type: String,
        required: true,
        validator: (value) => {
          return ['upload', 'columns', 'review', 'validate', 'import', 'complete'].indexOf(value) > -1
        },
      },
    },

    methods: {
      // Returns boolean if step matches or not
      isActive(currentStep) {
        if (this.wizardStep !== currentStep) {
          return false
        }

        return true
      },
    },
  }
</script>
