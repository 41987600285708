<template>
  <div>
    <h4>First, what groups should {{firstName}} have access to?</h4>

    <div
      v-if="errorMessage"
      ref="errorMessage"
      class="alert alert-danger"
    >
      {{errorMessage}}
    </div>

    <div
      v-if="noGroups"
      ref="noGroups"
    >
      <span>
        Please <a :href="$routes.new_employee_group_path">create a group</a> in order to create permissions.
      </span>
    </div>

    <form class="form-horizontal">
      <div class="form-group">
        <GroupSelectCheckbox
          v-for="(selectableGroup, index) in selectableGroups"
          :key="index"
          :group="selectableGroup"
          v-on:groupUpdated="updateScopeSelection"
        />
      </div>
    </form>
    <p class="breathe above">
      <em>You&apos;ll be able to define exactly what {{firstName}} can and cannot do within these groups in a moment.</em>
    </p>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import GroupSelectCheckbox from 'components/permissions/GroupSelectCheckbox'

  import { mapState } from 'vuex'

  export default {
    name: 'step-select-groups',

    components: {
      GroupSelectCheckbox,
    },
    extends: BaseStep,

    data: function() {
      return {
        selectableGroups: [],
        errorMessage: null,
      }
    },

    computed: {
      noGroups() {
        return Object.keys(this.groups).length == 0
      },

      noGroupsSelected() {
        return this.policy.groupPermissionSet().scope.value.length == 0
      },

      ...mapState({
        groups: state => state['groups'].collection,
      }),
    },

    watch: {
      // Monitor changes in groups and rebuild the selection list. This can occur when the PermissionsWizard loads groups in
      // via ajax- the list might not be loaded when this step initializes.
      groups: {
        handler() {
          this.initSelectedGroups()
        },
        deep: true,
      },
    },

    created() {
      this.initSelectedGroups()
    },

    methods: {
      valid() {
        return !this.noGroupsSelected
      },

      updateScopeSelection() {
        const permissionSet = this.policy.groupPermissionSet()
        const selectedGroupIds = this.selectableGroups.filter((g) => g.selected).map((g) => g.id)
        permissionSet.scope.value = selectedGroupIds

        this.errorMessage = null
      },

      invalidError() {
        if (this.noGroupsSelected) {
          this.errorMessage = "You must select groups to continue"
        }
      },

      // This builds up a list of "Selected Groups": { group_id: integer, selected: boolean } which we use to track groups
      // that have been selected.
      initSelectedGroups() {
        const permissionSet = this.policy.groupPermissionSet()
        const selectedGroupIds = permissionSet.scope.value

        this.selectableGroups = Object.keys(this.groups).map((id) => this.groups[id])
          .map((g) => Object.assign({selected: false}, g))

        this.selectableGroups.sort((a, b) => a.name.localeCompare(b.name, 'en', {sensitivity: 'base'}))

        this.selectableGroups.forEach((g) => {
          if (selectedGroupIds.indexOf(g.id) != -1 )
          {g.selected = true}
        })
      },
    },
  }
</script>
