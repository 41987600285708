<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="col-sm-12">
    <div class="checkbox">
      <label>
        <input
          ref="checkbox"
          v-model="group.selected"
          type="checkbox"
          @click="toggle"
        />
        {{ group.name }}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'group-select-checkbox',

    props: {
      group: {
        type: Object,
        required: true,
      },
    },

    methods: {
      toggle() {
        // eslint-disable-next-line vue/no-mutating-props
        this.group.selected = this.$refs.checkbox.checked
        this.$emit('groupUpdated')
      },
    },
  }
</script>
