<template>
  <div class="callout-legal">
    <strong>{{title}}</strong>
    <div v-text="statement" />
  </div>
</template>

<script>
  export default {
    name: 'perjury-statement',
    props: {
      title: {
        type: String,
        required: false,
      },
      statement: {
        type: String,
        required: true,
      },
    },
  }
</script>
