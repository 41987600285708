<template>
  <div class="wizard-body">
    <div class="row">
      <div class="col-md-7">
        <MatchColumnsTable
          :sortedColumnsMap="sortedColumnsMap"
          @field-key-changed="handleFieldKeyChange"
          @field-ignore-changed="handleFieldIgnoreChange"
        />
      </div>
      <div class="col-md-5">
        <TableValidator
          :sortedColumnsMap="sortedColumnsMap"
          :employeeIdColumnIndex="localBulkStaffUpdate.employee_id_column_index"
          @next-step="handleNextStep"
        />
      </div>
    </div>
  </div>
  <div class="wizard-footer text-left">
    <WbButton
      ref="backButton"
      class="back"
      context="default"
      :isLoading="isTransitioningBack"
      @click="$emit('back-requested')"
    >
      Back
    </WbButton>
  </div>
</template>

<script>
  import BaseStep from 'components/user_updates/steps/BaseStep'
  import MatchColumnsTable from 'components/user_updates/steps/match_columns_step/MatchColumnsTable'
  import TableValidator from 'components/user_updates/steps/match_columns_step/TableValidator'
  import { matchColumns } from 'services/admin/BulkStaffUpdatesService'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'match-columns-step',
    components: {
      MatchColumnsTable,
      TableValidator,
      WbButton,
    },
    extends: BaseStep,
    props: {
      bulkStaffUpdate: {
        type: Object,
        required: true,
      },
      isTransitioningBack: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['complete', 'back-requested'],
    data() {
      return {
        localBulkStaffUpdate: {...this.bulkStaffUpdate},
      }
    },
    computed: {
      sortedColumnsMap() {
        return Object.keys(this.localBulkStaffUpdate.columns_map)
          .map(key => ({
            position: key,
            name: this.bulkStaffUpdate.columns_map[key].name,
            field_key: this.bulkStaffUpdate.columns_map[key].field_key,
            ignored: this.bulkStaffUpdate.columns_map[key].ignored,
            sample: this.bulkStaffUpdate.columns_map[key].sample,
          }))
          .sort((a, b) => parseInt(a.position) - parseInt(b.position))
      },
    },
    methods: {
      handleFieldKeyChange(position, newFieldKey) {
        if (this.localBulkStaffUpdate?.columns_map) {
          this.localBulkStaffUpdate.columns_map[position].field_key = newFieldKey
        }
      },
      handleFieldIgnoreChange(position, newValue) {
        if (this.localBulkStaffUpdate?.columns_map) {
          if (newValue) {
            this.localBulkStaffUpdate.columns_map[position].field_key = null
          }
          this.localBulkStaffUpdate.columns_map[position].ignored = newValue
        }
      },
      handleNextStep() {
        matchColumns({...this.localBulkStaffUpdate})
          .then((bulkStaffUpdate) => {
            this.$emit('complete', bulkStaffUpdate, true)
          }).catch(xhr => {
            if (xhr.responseJSON?.errors) {
              this.errors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError('An error occurred trying to match columns for this Bulk Staff Update', xhr)
            }
          })
      },
    },
  }
</script>
