/*
 * Container for displaying a WorkBright table of Staff document assignments
 *
 * Required Vuex Modules:
 *   documents
 *   document_assignments
 *
 */
<template>
  <div class="row">
    <div class="col-md-3">
      <h4 class="text-strong">
        Sort by
      </h4>
      <RadioToggle
        :title="sortBy.title"
        :value="sortBy.value"
        :options="sortBy.options"
        buttonSize="btn-sm"
        @selected="(selection) => {this.sortBy.value = selection}"
      />
      <ListFilterSet
        :filters="filters"
        :collectionViewName="collectionViewName"
        class="pt-4 mt-4"
      />
    </div>
    <div class="col-md-9">
      <div class="wb-list assignment-list">
        <WbListHeader
          :filteredRowIds="filteredAssignmentIdsForBulkActions"
          :collectionViewName="collectionViewName"
          searchPlaceholder="Search Form Name..."
          :selectable="true"
        >
          <template #bulkActions>
            <button
              class="btn btn-md btn-primary"
              @click.stop.prevent="handleReassign"
            >
              Request New Submission(s)
            </button>
          </template>
        </WbListHeader>
        <p
          v-if="activeAssignments.length > 0 && filteredAssignments.length == 0"
          ref="noMatchingAssigned"
          class="m-4"
        >
          No matching assigned forms
        </p>
        <p
          v-if="activeAssignments.length == 0"
          ref="noAssigned"
          class="m-4"
        >
          No assigned forms exist for this staff member
        </p>
        <ul
          ref="filteredAssignments"
          class="list-group"
        >
          <DocumentAssignmentListItem
            v-for="assignment in filteredAssignments"
            :key="assignment.id"
            :assignment="assignment"
            :selectable="true"
            :collectionViewName="collectionViewName"
          />
        </ul>
        <div class="my-4">
          <p>
            <span class="lead">
              Other Forms on File
            </span>
            <WbButton
              ref="btnUpload"
              class="btn-sm ml-4"
              context='info'
              label="Upload"
              :disabled="!employed"
              @click="clickUploadSubmission"
            />
            <PopoverIcon
              v-if='!employed'
              title='Past-season staff'
              message='Submissions cannot be uploaded for past-season staff'
            />
          </p>
        </div>
        <p
          v-if="otherAssignments.length > 0 && filteredOtherAssignments.length == 0"
          ref="noMatchingOther"
          class="m-4"
        >
          No matching other forms
        </p>
        <p
          v-if="otherAssignments.length == 0"
          ref="noOther"
          class="m-4"
        >
          No other forms exist for this staff member
        </p>
        <ul
          ref="filteredOtherAssignments"
          class="list-group"
        >
          <DocumentAssignmentListItem
            v-for="assignment in filteredOtherAssignments"
            :key="assignment.id"
            :assignment="assignment"
            :collectionViewName="collectionViewName"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import DocumentAssignmentListItem from './DocumentAssignmentListItem'
  import { mapGetters, mapMutations, mapState } from 'vuex'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import * as mutate from 'vuex/mutation_types'
  import WbListHeader from 'components/common/WbListHeader'
  import ListFilterSet from 'components/common/ListFilterSet'
  import RadioToggle from 'components/common/RadioToggle'
  import WbButton from 'components/common/WbButton'
  import PopoverIcon from 'components/common/PopoverIcon'

  // Vuex CollectionViewStore module name, stores metadata about collection (selected, filters, etc)
  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_document_assignments'

  export default {
    name: 'document-assignment-list-container',
    components: {
      DocumentAssignmentListItem,
      WbListHeader,
      ListFilterSet,
      RadioToggle,
      WbButton,
      PopoverIcon,
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
        sortBy: {
          title: '',
          options: [
            { value: 'presentation', label: 'Presentations', icon: 'fa-sort-numeric-asc', ariaLabel: 'Sort presentation' },
            { value: 'alphabetical', label: 'Alphabetical', icon: 'fa-sort-alpha-asc', ariaLabel: 'Sort alphabetically' },
          ],
          value: 'presentation',
        },
        filters: [
          {
            type: 'multiselect',
            title: 'Status',
            filter_key: 'status',
            placeholder: 'Any',
            options: [
              { value: 'missing', label: 'Missing' },
              { value: 'pending', label: 'Pending Review' },
              { value: 'employee_review', label: 'Staff Member Review' },
              { value: 'accepted', label: 'Accepted' },
              { value: 'rejected', label: 'Rejected' },
            ],
          },
          {
            type: 'select',
            title: 'Optional',
            filter_key: 'optional',
            options: [
              { value: null, label: 'Any' },
              { value: true, label: 'Optional Only' },
              { value: false, label: 'Required Only' },
            ],
          },
          {
            type: 'select',
            title: 'Internal',
            filter_key: 'internal',
            options: [
              { value: null, label: 'Any' },
              { value: true, label: 'Internal Only' },
              { value: false, label: 'Non-Internal Only' },
            ],
          },
        ],
      }
    },

    computed: {
      ...mapGetters({
        pageContext: 'pageContext',
        assignmentsSortedAlpha: 'document_assignments/sortedAlpha',
        assignmentsSortedPresentation: 'document_assignments/sortedPresentation',
        getDocument: 'documents/get',
        getEmployee: `employees/get`,
        filterValues: `${VUEX_COLLECTION_VIEW_NAME}/filterValues`,
      }),

      ...mapState({
        documentOrderings: state => state['document_orderings'].collection,
        documents: state => state['documents'].collection,
        selected(state) {
          return state[this.collectionViewName].selected
        },
      }),

      activeAssignments() {
        const documentAssignments = this.sortBy.value == 'alphabetical' ?
          this.assignmentsSortedAlpha(this.documents) :
          this.assignmentsSortedPresentation(this.documentOrderings, this.documents)

        return _.where(
          documentAssignments,
          {
            active: true,
            employee_id: this.pageContext.employee.id,
          })
      },

      otherAssignments() {
        // Find DocumentAssignments for this staff member that are inactive and have submissions
        return _.filter(
          _.where(
            this.$store.state.document_assignments.collection,
            {
              active: false,
              employee_id: this.pageContext.employee.id,
            },
          ),
          (da) => { return !!da.current_submission_id },
        )
      },

      filteredOtherAssignments() {
        return this.otherAssignments.filter(a => {
          return this.filterAssignment(a)
        })
      },

      filteredAssignments() {
        return this.activeAssignments.filter(a => {
          return this.filterAssignment(a)
        })
      },

      filteredAssignmentIdsForBulkActions() {
        return this.filteredAssignments.map(assignment => assignment.id)
      },

      // Employee is actively employed?
      employed() {
        const employee = this.getEmployee(this.pageContext.employee.id)
        return employee ? !!employee.employment : false
      },
    },

    created: function(){
      if (!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }
    },

    beforeUnmount() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      filterAssignment(assignment) {
        // NOTE: Using a ref here instead of a data attribute doesn't exist when this method is called / cached within computed
        const searchStr = this.filterValues.search
        if (searchStr && searchStr.length > 0 &&
          this.getDocument(assignment.document_id).name
            .toLowerCase()
            .indexOf(searchStr.toLowerCase()) == -1) {
          return false
        }

        const status = this.filterValues.status
        if (status && status.length > 0 && status.indexOf(assignment.status) == -1
        ) {
          return false
        }

        const optional = this.filterValues.optional
        if (optional != null && assignment.optional != optional) {
          return false
        }

        const internal = this.filterValues.internal
        if (internal != null && this.getDocument(assignment.document_id).internal != internal) {
          return false
        }

        return true
      },

      clickUploadSubmission() {
        this.setPageContextKeys({ show_upload_submission: true })
      },

      handleReassign() {
        this.setPageContextKeys({
          bulkResetAssignmentsCollectionName: this.collectionViewName,
        })
      },
    },
  }

</script>
