import Util from 'lib/util'
import ImageEditorView from 'views/common/image_editor_view'
import ApiOperation from 'lib/api_operation'
import Routes from 'lib/routes'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'
import * as mutate from 'vuex/mutation_types'
import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
import DocumentSubmissionStatusChangedAt from 'components/document_submissions/DocumentSubmissionStatusChangedAt'
import CurrentSubmissionBadge from 'components/document_submissions/CurrentSubmissionBadge'
import HistoricalSubmissionAlert from 'components/document_submissions/HistoricalSubmissionAlert'
import AdminInitiateReverifyButton from 'components/admin/document_submissions/AdminInitiateReverifyButton'
import AdminRejectButton from 'components/admin/document_submissions/AdminRejectButton'
import SupplementBButton from 'components/admin/document_submissions/SupplementBButton'
import SupplementBModal from 'components/admin/document_submissions/SupplementBModal'
import RejectReasonModal from 'components/admin/document_submissions/RejectReasonModal'
import RejectionReasonBlockquote from 'components/admin/document_submissions/RejectionReasonBlockquote'
import DocumentVerificationTable from 'components/document_submissions/DocumentVerificationTable'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default {
  ui: {
    gotoInboxButton: '.js-goto-inbox',

    acceptButtonGroup: '.accept-group',
    acceptButtonDropdown: '.accept-group > .dropdown-toggle',
    acceptButtonMenu: '.accept-group > .dropdown-menu',
    acceptButton: '.js-accept',
    acceptNextButton: '.js-accept-next',

    rejectButtonGroup: '.reject-group',
    rejectButtonDropdown: '.reject-group > .dropdown-toggle',
    rejectButtonMenu: '.reject-group > .dropdown-menu',
    rejectButton: '.js-reject',
    rejectNextButton: '.js-reject-next',

    statusDetailsInfo: '.status-details-info',
    rejectionReasonBlockquote: '.rejection-reason-blockquote',

    leftRightNavButtonGroup: '.left-right-nav-group',
    gotoPrevButton: '.js-goto-prev',
    gotoNextButton: '.js-goto-next',

    tagsSelect: '.tags-select',
    tagsStatusIcon: '.tags-status-icon',
    editInternalFieldsButton: '.js-edit-internal-fields',
    inboxSubmissionActions: '.inbox-submission-actions',
    downloadAttachmentButtons: '.download-attachment-btn',
    countersignButton: '.btn-countersign',
    supplementBButton: '.supplement-b-button',
    adminRejectButton: '.admin-reject-button',
    adminRejectReasonModal: '.admin-reject-reason-modal',
    adminInitiateReverifyButton: '.admin-initiate-reverify-button',
    supplementBModal: '.supplement-b-modal',
    openHistoricalSubmissionsLink: '.js-open-historical-submissions',
    documentVerificationTable: '.vue-document-verification-table',
  },

  bindings: {
    '.status-row': {
      observe: 'status',
      visible: val => {
        // The status row contains the accept/reject byline and timestamp. It should be hidden if the submission
        // is still Pending Review and shown otherwise.
        return (val !== 'pending')
      },
      updateView: false,
    },
    '.status-details-info': 'status_details_html',
    '.inbox-submission-actions': {
      classes: {
        hidden: {
          observe: 'is_current_submission_of_assignment',
          onGet: function (value) {
            return value !== true
          },
        },
      },
    },
    '.tags-row': {
      classes: {
        hidden: {  // FIXME: This hides the entire tags row and not just the ability to edit
          observe: 'is_current_submission_of_assignment',
          onGet: function (value) {
            return value !== true
          },
        },
      },
    },
    '.img-ed .admin-options': {
      classes: {
        hidden: {
          observe: 'is_current_submission_of_assignment',
          onGet: function(value) {
            return value !== true
          },
        },
      },
    },
    '.js-edit-internal-fields': {
      classes: {
        hidden: {
          observe: 'is_current_submission_of_assignment',
          onGet: (value) => {
            return value !== true
          },
        },
      },
    },
    '.btn-countersign': {
      classes: {
        hidden: {
          observe: 'countersignable',
          onGet: (countersignable) => {
            return !countersignable
          },
        },
      },
    },
    '.btn-countersign .btn-title': {
      observe: 'countersignable',
      onGet: (countersignable, options) => {
        if (!countersignable) { return null }

        return options.view.model.get('certify_status') == 'uncertified' ?
          '<i class="a4s-icon a4s-icon-signature-pen" /> Complete I-9 Employer Section' :
          '<i class="fa fa-repeat" /> Redo I-9 Employer Section'
      },
    },
    '.accept-group': {
      classes: {
        hidden: {
          observe: 'accepted',
          onGet: (accepted) => {
            if (accepted === null) {
              return false
            }
            return accepted
          },
        },
      },
    },
    '.reject-group': {
      classes: {
        hidden: {
          observe: 'accepted',
          onGet: (accepted) => {
            if (accepted === null) {
              return false
            }
            return !accepted
          },
        },
      },
    },
    '.confirmed-ok-at': {
      classes: {
        hidden: {
          observe: 'confirmed_ok_at',
          onGet: (confirmed_ok_at) => {
            if (confirmed_ok_at) {
              return false
            } else {
              return true
            }
          },
        },
      },
    },
    '.completed-by': {
      observe: 'certify_status',
      onGet: (certifyStatus, options) => {
        if (certifyStatus != 'certify_complete') {
          return false
        }

        const model = options.view.model
        return `I-9 Employer Section completed by ${model.escape('employer_first_name')} ${model.escape('employer_last_name')}`
      },
    },
  },
  events: {
    'click @ui.acceptButton': 'clickAccept',
    'click @ui.rejectButton': 'clickReject',
    'click @ui.acceptNextButton': 'clickAcceptNext',
    'click @ui.rejectNextButton': 'clickRejectNext',
  },

  triggers: {
    'click @ui.gotoInboxButton': 'goto:inbox:action',
    'click @ui.gotoNextButton': 'goto:next:action',
    'click @ui.gotoPrevButton': 'goto:prev:action',
    'click @ui.editInternalFieldsButton': 'editInternalFields:action',
    'click @ui.countersignButton': 'countersign:action',
    'change @ui.tagsSelect': 'update:tags',
    'click @ui.openHistoricalSubmissionsLink': 'openHistoricalSubmissions',
  },

  modelEvents: {
    change: 'updateVuex',
    'change:accepted': 'updateStatusInfo',
  },

  renderUI: function() {
    // Set up bindings for each of the custom fields in model.fields.
    const fieldsCollection = this.model.get('fields')
    if (fieldsCollection && fieldsCollection.length > 0) {
      fieldsCollection.each(function(fieldValueEntry, _index) {
        this.addBinding(fieldValueEntry, `#udf_${fieldValueEntry.id}`, {
          observe: 'value_decorated',
          escape: false,  // value_decorated is sanitized server-side
          updateMethod: 'html',
        })
      }, this)
    }

    this.updateStatusInfo() // must call this on load so UI default is current according to model state
    this.renderLeftRightNavState()
    this.renderHistoricalSubmissionsState()
    setTimeout(function(){ this.renderTagsSelect() }.bind(this))

    let imgEdIdx = 0
    this.allImgEditors = _.map(this.$el.find('.attachment-img'), (imageEl) => {
      const downloadButton = this.ui.downloadAttachmentButtons[imgEdIdx]
      const imgView = new ImageEditorView({
        el: $(imageEl).parent().prev('.img-ed'),
        imgSrc: imageEl.src,
        adminMode: true,
        submission: this.model,
        updateCallback: (options) => {
          // Update the Download File button with new URL
          downloadButton.href = options.updatedUrl
        },
      })

      imgView.render()

      ++imgEdIdx

      return imgView
    })

    this.stickit()

    $.runInitializers(this.$el)

    this.renderCreatedAt()
    this.renderConfirmedAt()
    this.renderStatusChangedAt()
    this.renderCountersignedAt()
    this.renderCurrentSubmissionAlert()
    this.renderCurrentSubmissionBadge()
    this.renderSupplementBButton()
    this.renderRejectReasonsModal()
    this.renderRejectionReasonBlockquote()
    this.renderAdminRejectButton()
    this.renderAdminInitiateReverifyButton()
    this.renderSupplementBModal()
    this.renderDocumentVerificationTable()

    // In order for this view to know what is going on within the Historical Submissions Slideout, we listen to the vuex store
    this.vuexSubscription = window.vuexStore.subscribe((mutation, _state) => {
      if (mutation.type === "document_assignments/SET_RECORD") {
        // Update the backbone model with latest current_submission info
        this.model.set('is_current_submission_of_assignment', mutation.payload.current_submission_id === this.model.id)
      }
    })
  },

  onGotoInboxAction: function() {
    App.vent.trigger("inbox:show")
  },

  onBeginAcceptReject: function(laddaBtn) {
    if (laddaBtn) { laddaBtn.start() }; // hackfix

    this.ui.inboxSubmissionActions.find('.btn').addClass('disabled')
  },

  clickAcceptNext: function(e) {
    e.preventDefault()
    e.stopPropagation()

    this.acceptSubmission({ redirectOnAccept: 'next', laddaButton: e.currentTarget })
  },

  clickAccept: function(e) {
    e.preventDefault()
    e.stopPropagation()
    this.acceptSubmission({ laddaButton: e.currentTarget })
  },

  clickReject: function(e) {
    e.preventDefault()
    e.stopPropagation()
    this.rejectSubmission({ laddaButton: e.currentTarget })
  },

  clickRejectNext: function (e) {
    e.preventDefault()
    e.stopPropagation()
    this.rejectSubmission({ redirectOnReject: 'next', laddaButton: e.currentTarget })
  },

  acceptSubmission: function(options) {
    const _options = _.extend({}, options)
    const redirectToNext = options.redirectOnAccept === "next"
    const laddaButton = $(_options.laddaButton).data('ladda')

    this.onBeginAcceptReject(laddaButton)

    new ApiOperation({
      model: this.model,
      path: Routes.Api.accept_submission_path({ id: this.model.id }),
      httpMethod: 'PATCH',
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't mark this submission as accepted.",
      },
    }).done((_data, _status, xhr) => {
      this.onEndAcceptReject(laddaButton)

      if (redirectToNext) {
        this.triggerMethod('goto:next:action')
      }

      Util.showAjaxFlashNotice(xhr)
    })
      .fail((xhr) => {
        this.ajaxConflictDialog(xhr)
        this.onEndAcceptReject(laddaButton)
      })
  },

  onEndAcceptReject: function(laddaButton) {
    if (laddaButton){
      laddaButton.stop() // Hackfix
      window.location.reload()
    }
    this.ui.inboxSubmissionActions.find('.btn').removeClass('disabled')
  },

  updateStatusInfo: function() {
    const accepted = this.model.get('accepted') && !this.model.get('auth_rep_rejected')
    if (accepted == null) {
      return
    }

    // This mimics a kind of 'flash' effect when the status changes and the user stays on the page
    if (this.ui.statusDetailsInfo.is(':visible')) {
      this.ui.statusDetailsInfo.fadeOut('fast')
    }
    this.ui.statusDetailsInfo.fadeIn('fast')
  },

  renderHistoricalSubmissionsState: function () {
    if (this.options.historical_submissions_count == 0) {
      this.ui.openHistoricalSubmissionsLink.parent('li').addClass('disabled')
    } else {
      // Update the badge
      this.ui.openHistoricalSubmissionsLink.find('.badge').text(
        this.options.historical_submissions_count,
      )
    }
  },

  onGotoPrevAction: function() {
    if (this.options.modelBefore !== null) {
      App.vent.trigger("inbox:submission:show", this.options.modelBefore)
    } else {
      this.triggerMethod('goto:inbox:action')
    }
  },

  onGotoNextAction: function() {
    if (this.options.modelAfter !== null) {
      App.vent.trigger("inbox:submission:show", this.options.modelAfter)
    } else {
      this.triggerMethod('goto:inbox:action')
    }
  },

  renderLeftRightNavState: function() {
    this.ui.leftRightNavButtonGroup.toggle(this.options.modelBefore != null || this.options.modelAfter != null)
    this.ui.gotoPrevButton.toggleClass('disabled', this.options.modelBefore == null)
    this.ui.gotoNextButton.toggleClass('disabled', this.options.modelAfter == null)
  },

  renderTagsSelect: function() {
    this.ui.tagsSelect.select2({
      placeholder: 'Click to add a tag',
      tokenSeparators: [","],
      tags: true,
      multiple: true,
      language: {
        noResults: function() {
          return "No existing tags found"
        },
      },
    })
  },

  onUpdateTags: function() {
    this.ui.tagsStatusIcon.stop(true, true).removeClass('fa-tags fa-check text-success').addClass('fa-spinner fa-spin')

    const csvTags = this.ui.tagsSelect.val()

    new ApiOperation({
      model: this.model,
      path: Routes.admin_submission_path({ id: this.model.id }),
      httpMethod: 'PATCH',
      data: {
        tags: csvTags,
      },
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we had a problem updating tags for this submission. Your last tag may have not been saved.",
      },
    }).done((_data, _status, _jqXHR) => {
      // FIXME: extract this animation into a generic utility, it's pretty rad.
      this.ui.tagsStatusIcon.removeClass('fa-spinner fa-spin').addClass('fa-check text-success').delay(1000).fadeOut('fast', function() {
        $(this).removeClass('fa-check text-success').addClass('fa-tags').fadeIn('fast')
      })
    })
      .fail((xhr) => {
        this.ui.tagsStatusIcon.removeClass('fa-spinner fa-spin').addClass('fa-tags')
        this.ajaxConflictDialog(xhr)
      })
  },

  onEditInternalFieldsAction: function() {
    App.vent.trigger('submission:edit:internal_fields', this.model)
  },

  onDestroy: function () {
    if (!this.allImgEditors) { return };

    // Destroy all ImgEditors!
    _.each(this.allImgEditors, function(editor) { editor.destroy() })

    // Stop listening
    this.vuexSubscription()
  },

  onCountersignAction: function() {
    /* Triggers the i9:sign event defined in document_submissions_sector.js
        Args:
          ids: Array of IDs of the submissions to be signed
          isEdit: Boolean indicating whether the action is a section 2 edit
          isSingle: Boolean indicating whether the action applies to a single submission

        When isSingle is true, the user will be redirected to the submission page after signing.
    */
    App.vent.trigger('i9:sign', [this.model.id], false, true)
  },

  ajaxConflictDialog: function (xhr) {
    const xhrDecorated = WBRequestXHRDecorator(xhr)
    if (xhrDecorated.status == 409) {
      App.Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
    }
  },

  onOpenHistoricalSubmissions: function() {
    this.openHistoricalSubmissionsSlideout({ show_historical_submissions_assignment_id: this.options.assignment.id })
  },

  openHistoricalSubmissionsSlideout(pageContext) {
    if (this.options.historical_submissions_count > 0) {
      window.vuexStore.commit(mutate.SET_PAGE_CONTEXT_KEYS, pageContext)
    }
  },

  renderCreatedAt() {
    pureOrphanVue(WbSimpleDateTime, "component.vue-submission-created-at", {
      props: {
        value: this.model.get('submitted_at'),
        includeTimeago: true,
      },
    })
  },

  renderConfirmedAt() {
    // Component requires that value exists
    if (!this.model.get('confirmed_ok_at')) {
      return
    }

    pureOrphanVue(WbSimpleDateTime, "component.vue-submission-confirmed-ok-at", {
      props: {
        value: this.model.get('confirmed_ok_at'),
        includeTimeago: true,
      },
    })
  },

  renderStatusChangedAt() {
    pureOrphanVue(DocumentSubmissionStatusChangedAt, "component.vue-document-submission-status-changed-at", {
      props: {
        submissionId: this.model.id,
      },
    })
  },

  renderCountersignedAt() {
    if (this.model.get('certify_status') != 'certify_complete') {
      return
    }

    pureOrphanVue(WbSimpleDateTime, "component.vue-submission-countersigned-at", {
      props: {
        value: this.model.get('employer_signed_at'),
      },
    })
  },

  renderCurrentSubmissionBadge() {
    if (!this.model.has('is_current_submission_of_assignment')) {
      return
    }

    pureOrphanVue(CurrentSubmissionBadge, 'component.vue-document-submission-current-submission-badge', {
      props: {
        submissionId: this.model.id,
      },
    })
  },

  renderCurrentSubmissionAlert() {
    // We don't use the model's is_current_submission attribute as we want to let the component handle showing/hiding itself instead
    // of letting Marionette/Backbone handle conditional rendering
    pureOrphanVue(HistoricalSubmissionAlert, 'component.vue-historical-submission-alert', {
      props: {
        submissionId: this.model.id,
        permissionModel: 'documents',
        permissionRecordId: this.model.get('document_id'),
        permissionAction: 'upload_submission',
      },
    })

  },

  renderSupplementBButton() {
    // this.ui.supplementBButton {jQuery} Check the prescense of the component on the page.
    if (this.ui.supplementBButton.length) {
      pureOrphanVue(SupplementBButton, 'component.supplement-b-button', {
        props: {
          submissionId: this.model.id,
          i9DocumentationChoices: gon.documentation_choices,
          documentSubmission: gon.document_submission,
        },
      })
    }
  },

  renderRejectReasonsModal() {
    // this.ui.adminRejectButton {jQuery} Check the presence of the component on the page.
    if (this.ui.adminRejectReasonModal.length) {
      pureOrphanVue(RejectReasonModal, '.admin-reject-reason-modal', {
        ref: 'rejectReasonModal',
        props: {
          employeeName: this.model.attributes.employee_name,
          submissionId: this.model.id,
          documentId: this.model.get('document_id'),
          freeFormRejectionEnabled: window.gon.current_account.freeFormRejectionEnabled,
          i9RejectionHideAdditionalDetailsFieldEnabled: window.gon.current_account.i9RejectionHideAdditionalDetailsFieldEnabled,
          modalKey: 'rejectionReasonModalKey',
          onSubmit: () => {
            window.location.reload()
          },
        },
      }, {
        removeElementOnDestroy: true,
      })
    }
  },

  renderAdminRejectButton() {
    // this.ui.adminRejectButton {jQuery} Check the presence of the component on the page.
    if (this.ui.adminRejectButton.length) {
      const visible = this.model.attributes.accepted === true || this.model.attributes.accepted === null

      pureOrphanVue(AdminRejectButton, 'component.admin-reject-button', {
        props: {
          visible: visible,
          onClick: this.rejectSubmission,
        },
      })
    }
  },

  renderRejectionReasonBlockquote() {
    // this.ui.rejectionReasonBlockquote {jQuery} Check the presence of the component on the page.
    if (this.ui.rejectionReasonBlockquote.length) {
      pureOrphanVue(RejectionReasonBlockquote, 'component.rejection-reason-blockquote', {
        props: {
          accepted: this.model.attributes.accepted,
          employeeName: this.model.attributes.employee_name,
          rejectionReason: this.model.attributes.rejection_reason,
          rejectionReasons: this.model.attributes.rejection_reasons,
        },
      })
    }
  },

  renderAdminInitiateReverifyButton() {
    // this.ui.supplementBButton {jQuery} Check the prescense of the component on the page.
    if (this.ui.adminInitiateReverifyButton.length) {
      pureOrphanVue(AdminInitiateReverifyButton, 'component.admin-initiate-reverify-button', {
        props: {
          documentSubmission: gon.document_submission,
          isRemoteReverificationI9Enabled: gon.remote_reverification_i9_enabled,
        },
      })
    }
  },

  renderSupplementBModal() {
    if (this.ui.supplementBModal.length) {
      pureOrphanVue(SupplementBModal, 'component.supplement-b-modal', {
        props: {
          i9DocumentationChoices: gon.documentation_choices,
          documentSubmission: gon.document_submission,
        },
      })
    }
  },

  updateVuex(model) {
    // REVIEW: are we doing this twice? Here and in document_submissions_controller.js
    window.vuexStore.commit('document_submissions/SET_RECORD', model.attributes)
  },

  renderDocumentVerificationTable() {
    if (this.ui.documentVerificationTable.length) {
      pureOrphanVue(DocumentVerificationTable, 'component.vue-document-verification-table', {
        props: {
          status: this.model.attributes.document_verification_result,
          submission: this.model.attributes,
        },
      })
    }
  },
}
