<template>
  <div>
    <p>
      Updates can take a few minutes or several hours depending on the quantity of updates volume in the current requests. You can view the status of the update on the <a :href="viewPastUpdatesPath">Past Updates</a> page.
    </p>
    <Spinner
      v-if="isLoading"
      ref="loader"
    />
  </div>
</template>

<script>
  import BaseStep from 'components/user_updates/steps/BaseStep'
  import Spinner from 'components/common/Spinner'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'processing-step',
    components: {
      Spinner,
    },
    extends: BaseStep,
    props: {
      isLoading: {
        type: Boolean,
        required: true,
        default: true,
      },
      bulkStaffUpdate: {
        type: Object,
        required: true,
      },
    },
    emits: ['complete'],
    computed: {
      viewPastUpdatesPath() {
        return getRoute('user_updates_path')
      },
    },
  }
</script>
