<template>
  <ul class="nav-wizard pl-0">
    <li
      v-for="(step, index) in steps"
      :key="index"
      :class="{active: index === currentStepIdx}"
    >
      {{ step.label }}
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'wb-wizard-steps',
    props: {
      steps: {
        type: Array,
        default: () => [],
      },
      currentStepIdx: {
        type: Number,
        default: 0,
        required: false,
      },
    },
  }
</script>
