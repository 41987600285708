<template>
  <FormField
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :required="required"
    :errors="errors"
    :hint="hint"
  >
    <div class="input-wrapper">
      <div class="input-group">
        <span class="input-group-addon">
          <i class="fa fa-calendar" />
        </span>
        <div :class="fieldWidth">
          <input
            ref="input"
            class="form-control"
            :name="name"
            type='text'
            :placeholder='placeholder'
            :value="value"
            autocomplete="off"
            @change="$emit('input', $event.target.value)"
          />
        </div>
      </div>
    </div>
  </FormField>
</template>

<script>
  import Constants from 'lib/constants'
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'
  import Pikaday from 'pikaday'

  export default {
    name: 'date-field',
    components: {
      FormField,
    },
    mixins: [FormFieldConcern],

    props: {
      value: {
        type: String,
        required: false,
      },
      minDate: {
        type: Date,
        required: false,
        default: null,
      },
      fieldWidth: {
        type: String,
        default: 'medium-field',
        validator: value => {
          return [
            '', // full-width of container
            'short-field',
            'medium-field',
            'long-field',
          ].includes(value)
        },
      },
    },

    data() {
      return {
        picker: null,
      }
    },

    mounted() {
      this.picker = new Pikaday({
        field: this.$refs.input,
        format: Constants.DATE_FORMAT,
        yearRange: 100,
        minDate: this.minDate,
      })
    },
  }
</script>
