<template>
  <div>
    <WbWizardSteps
      :steps="steps"
      :currentStepIdx="currentStepIdx"
    />
    <h2>{{ currentStep.title }}</h2>
    <p>{{ currentStep.subTitle }}</p>
    <component
      :is="currentStepComponentName"
      ref="currentStep"
      :bulk-staff-update="localBulkStaffChange"
      :isLoading="isLoading"
      :pubSubChannel="pubSubChannel"
      :is-transitioning-back="isTransitioningBack"
      @complete="handleStepCompleted"
      @back-requested="handleStepBack"
    />
  </div>
</template>

<script>
  import WbWizardSteps from "components/common/WbWizardSteps"
  import BaseWizard from 'components/common/BaseWizard'
  import FileUploadStep from 'components/user_updates/steps/FileUploadStep'
  import SelectEmployeeIdStep from 'components/user_updates/steps/SelectEmployeeIdStep'
  import MatchColumnsStep from 'components/user_updates/steps/MatchColumnsStep'
  import ReviewUpdateStep from 'components/user_updates/steps/ReviewUpdateStep'
  import ProcessingStep from 'components/user_updates/steps/ProcessingStep'
  import SummaryStep from 'components/user_updates/steps/SummaryStep'

  import { getRoute } from 'lib/routes'
  import PubSub from 'lib/pub_sub'
  import { transitionBack } from 'services/admin/BulkStaffUpdatesService'
  import Util from 'lib/util'

  export default {
    name: 'bulk-staff-update-wizard',
    components: {
      BaseWizard,
      FileUploadStep,
      SelectEmployeeIdStep,
      MatchColumnsStep,
      ReviewUpdateStep,
      ProcessingStep,
      SummaryStep,
      WbWizardSteps,
    },
    mixins: [BaseWizard],
    props: {
      bulkStaffChange: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        steps: [
          {
            component: 'FileUploadStep',
            label: '1. Upload File',
            title: 'Upload Your Spreadsheet',
            subTitle: 'Make bulk updates to your staff in WorkBright using an Excel or CSV file.',
            stateKey: 'uploaded',
          },
          {
            component: 'SelectEmployeeIdStep',
            label: '2. Select WorkBright ID',
            title: 'Select WorkBright ID',
            subTitle: 'The WorkBright Staff ID field is required. This ID will be used to match the updates to the correct staff member. Please make sure there is a column mapped to this field to proceed.',
            stateKey: 'employee_id_select',
          },
          {
            component: 'MatchColumnsStep',
            label: '3. Match Columns',
            title: 'Match Columns',
            subTitle: 'Map the columns to their respective WorkBright fields.',
            stateKey: 'column_match',
          },
          {
            component: 'ReviewUpdateStep',
            label: '4. Review',
            title: 'Review your data',
            subTitle: 'Please make sure that the count of rows that will be affected by this bulk update is correct before proceeding.',
            stateKey: 'reviewing',
          },
          {
            component: 'ProcessingStep',
            label: '5. Processing',
            title: 'Processing Data',
            subTitle: '',
            stateKey: 'processing',
          },
          {
            component: 'SummaryStep',
            label: '6. Complete',
            title: 'Update Summary',
            stateKey: 'complete',
          },
        ],
        localBulkStaffChange: null,
        pubSubChannel: null,
        isLoading: true,
        isTransitioningBack: false,
      }
    },
    computed: {
      currentStep() {
        return this.steps[this.currentStepIdx]
      },
    },
    watch: {
      bulkStaffChange () {
        this.localBulkStaffChange = this.bulkStaffChange
      },
      localBulkStaffChange () {
        this.currentStepIdx = this.getStepIndexFromState(this.localBulkStaffChange)
      },
      currentStepIdx () {
        if (this.currentStep.stateKey === 'reviewing') { // ReviewUpdateStep
          this.handleSubscription()
        }
      },
    },
    created () {
      this.localBulkStaffChange = this.bulkStaffChange

    },
    methods: {
      handleStepCompleted(newRecord, goToNextStep) {
        this.localBulkStaffChange = newRecord
        if (goToNextStep) { this.nextStep() }
      },
      getStepIndexFromState (bulkStaffChange) {
        const currentState = bulkStaffChange?.current_state
        const stepIndex = this.steps.findIndex(step => step.stateKey === currentState)
        return stepIndex === -1 ? this.steps.length - 1 : stepIndex
      },
      handleStepBack() {
        this.isTransitioningBack = true
        transitionBack({...this.localBulkStaffChange})
          .then((bulkStaffUpdate) => {
            this.localBulkStaffChange = bulkStaffUpdate
          }).catch(xhr => {
            if (xhr.responseJSON?.error) {
              Util.showFlashNotice(xhr.responseJSON?.error, {alertType: 'danger'})
            } else {
              Util.genericAjaxError('An error occurred trying to update this Bulk Staff Update', xhr, {forceParseResponse: true})
            }
          }).finally(() => {
            this.isTransitioningBack = false
          })
      },
      handleSubscription () {
        this.pubSubChannel = PubSub.subscribeToUserChannel({
          topic: 'bulk_user_updates',
        })

        this.pubSubChannel.bind('complete', this.bulkUpdateSuccessCallback.bind(this))
        this.pubSubChannel.bind('failed', this.bulkUpdateFailCallback.bind(this))
      },

      bulkUpdateSuccessCallback(bulkStaffChange) {
        this.isLoading = false
        window.location = getRoute('user_update_path', bulkStaffChange)
      },

      bulkUpdateFailCallback() {
        Util.errorDialog(this.$locale.t("staff.bulkUpdate.fail"), this.$locale.t("errors.ajax.generic"))
      },
    },
  }
</script>
