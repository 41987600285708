<template>
  <div class="wizard">
    <div class="wizard-body">
      <AlertMessage
        ref="rowCount"
        variant="success"
        :message="rowCountText"
      />
      <WbButton
        ref="uploadDifferentFileButton"
        class="previous"
        context="info"
        @click="handleUploadDifferentFile"
      >
        Upload a Different File
      </WbButton>
    </div>
    <div class="wizard-footer">
      <WbButton
        ref="backButton"
        class="back"
        context="default"
        :isLoading="isTransitioningBack"
        @click="$emit('back-requested')"
      >
        Back
      </WbButton>
      <WbButton
        ref="nextButton"
        class="next"
        context="primary"
        @click="handleNext"
      >
        Start Update
      </WbButton>
    </div>
  </div>
</template>

<script>
  import AlertMessage from 'components/common/AlertMessage'
  import WbButton from 'components/common/WbButton'
  import BaseStep from 'components/user_updates/steps/BaseStep'
  import { startProcessing, destroy } from 'services/admin/BulkStaffUpdatesService'
  import Util from 'lib/util'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'review-update-step',
    components: {
      AlertMessage,
      WbButton,
    },
    extends: BaseStep,
    props: {
      bulkStaffUpdate: {
        type: Object,
        required: true,
      },
      pubSubChannel: {
        type: Object,
        required: true,
      },
      isTransitioningBack: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['complete', 'back-requested'],
    data () {
      return {
        localBulkStaffUpdate: {...this.bulkStaffUpdate},
      }
    },
    computed: {
      rowCountText() {
        return `${this.localBulkStaffUpdate.sheet_data.row_count} Staff ready to update.`
      },
    },
    methods: {
      handleNext () {
        startProcessing({ ...this.localBulkStaffUpdate, pubsub_channel: this.pubSubChannel.name })
          .then((bulkStaffUpdate) => {
            this.$emit('complete', bulkStaffUpdate, true)
          }).catch(xhr => {
            if (xhr.responseJSON?.errors) {
              this.errors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError('An error occurred trying to start processing this Bulk Staff Update', xhr)
            }
          })
      },
      handleUploadDifferentFile () {
        destroy({ ...this.localBulkStaffUpdate })
          .then(() => {
            this.goToNewUserUpdate()
          }).catch(xhr => {
            if (xhr.responseJSON?.errors) {
              this.errors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError('An error occurred trying to restart this Bulk Staff Update', xhr)
            }
          })
      },
      goToNewUserUpdate () {
        window.location = getRoute('new_user_update_path')
      },
    },
  }
</script>
