<template>
  <SlideoutPanelExtended
    ref="slideout"
    size="xl"
    :watchPageContextVariable="slideoutKey"
    @modal-show="handleModalShow"
    @modal-hidden="handleModalHidden"
  >
    <template #extended-content>
      <SupportingDocumentation :documentationSet="attachments" />
    </template>
    <template #primary-title>
      <div class="panel-title">
        <h3>Document Verification</h3>
      </div>
    </template>
    <template #primary-content>
      <Spinner
        v-if="isLoading"
        ref="loader"
      />
      <template
        v-else-if="hasSufficientData"
      >
        <div
          v-if="canMarkAsReviewed"
          ref="markAsReviewedButton"
          class="mb-2"
        >
          <AlertMessage
            ref="canReviewMessage"
            :message="canReviewMessage"
            variant="warning"
          />
          <FormFieldTextArea
            ref="comments"
            v-model="comments"
            class="mb-2"
            label="Comments (optional):"
          />
          <Toolbar reverse>
            <div class="btn-sequence">
              <WbButton
                label="Mark as Reviewed"
                @click="handleReview"
              />
            </div>
          </Toolbar>
        </div>
        <AlertMessage
          v-else
          ref="checkMessage"
          :message="checkMessage"
          variant="warning"
        />
        <InquiryAuditLog
          v-for="(attachment, index) in attachments"
          :key="index"
          :inquiry="attachment.document_verification"
          :documentName="attachment.document_title"
        />
        <DocumentVerificationLog
          v-if="isVerified"
          :submission="submission"
        />
      </template>
      <AlertMessage
        v-else
        ref="noDataMessage"
        message="Unable to retrieve verification data"
      />
    </template>
  </SlideoutPanelExtended>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import InquiryAuditLog from 'components/document_submissions/i9/InquiryAuditLog'
  import SupportingDocumentation from 'components/document_submissions/i9/SupportingDocumentation'
  import SlideoutPanelExtended from 'components/common/SlideoutPanelExtended'
  import Spinner from 'components/common/Spinner'
  import Toolbar from 'components/common/Toolbar'
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import { fetchSubmissionAttachments } from 'services/admin/documentSubmissionsService'
  import { resolveDocumentVerification } from 'services/admin/documentVerificationService'
  import DocumentVerificationLog from 'components/document_submissions/DocumentVerificationLog'
  import { VERIFIED_STATUSES } from 'decorators/document_verification_decorator'
  import { mapActions } from 'vuex'
  import SystemEvents from 'lib/system_events'
  import AdminDocumentVerificationReviewedEvent from 'models/system_events/admin_document_verification_reviewed_event'

  export default {
    components: {
      AlertMessage,
      InquiryAuditLog,
      SupportingDocumentation,
      SlideoutPanelExtended,
      Spinner,
      WbButton,
      FormFieldTextArea,
      Toolbar,
      DocumentVerificationLog,
    },

    props: {
      submission: {
        type: Object,
        required: true,
      },
    },

    emits: [
      'update', 'modalHidden',
    ],

    data () {
      return {
        isLoading: false,
        comments: null,
        attachments: [],
      }
    },

    computed: {
      submissionId () {
        return this.submission.id
      },
      hasSufficientData () {
        return this.attachments.length > 0
      },
      slideoutKey() {
        return `documentVerificationSlideout-${this.submissionId}`
      },
      canMarkAsReviewed() {
        return this.hasAnyFailedVerification
      },
      hasAnyFailedVerification() {
        return this.attachments.filter(attachment => attachment.document_verification.status === 'failed').length > 0
      },
      checkMessage () {
        return `It is up to you, as the Employer of Record to make the ultimate decisions about identity and/or work authorization documents presented by this employee. You can review which checks were performed on the documents provided by clicking the drop down 'Show Checks' below the document(s).`
      },
      canReviewMessage () {
        return `It is up to you, as the Employer of Record to make the decision to accept any document(s) provided.
          Any checks that did not pass may or may not be an indication of fraud, mistake, or misrepresentation.
          Please review any associated checks and select "Mark as Reviewed" and include any optional comments.
          It is at your discretion whether to proceed with employment, request a new submission, or terminate employment
          in consultation with your own internal counsel and the <a target="_blank" href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274">USCIS Handbook for Employers</a>.`
      },
      isVerified () {
        return this.attachments.some(attachment => VERIFIED_STATUSES.includes(attachment.document_verification.status))
      },
    },

    methods: {
      ...mapActions({
        updateAssignmentSubmission: 'i9_inbox_assignments/updateAssignmentSubmission',
      }),
      fetchData () {
        this.isLoading = true
        fetchSubmissionAttachments(this.submissionId)
          .then(response => {
            this.attachments = response.verified_attachments
          })
          .catch(() => {
            this.attachments = []
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      handleModalShow() {
        if (!this.hasSufficientData) {
          this.fetchData()
        }
      },
      handleReview () {
        this.resolve('review')
      },
      resolve (action) {
        this.isLoading = true

        resolveDocumentVerification(this.submissionId, action, this.comments)
          .then(({ submission, verified_attachments }) => {
            this.updateAssignmentSubmission(submission)
            this.attachments = verified_attachments || []

            this.$emit('update', this.submissionId)
            this.notifySystemEvent(new AdminDocumentVerificationReviewedEvent())
          })
          .catch(xhr => {
            Util.genericAjaxError('An error occurred', xhr)
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      handleModalHidden () {
        this.$emit('modalHidden')
      },
      notifySystemEvent(systemEvent) {
        SystemEvents.notify(systemEvent.payload())
      },
    },
  }
</script>
