// E-Verify case title displayed on small slideout panel (used with EverifyCasePanelContent).

<template>
  <div>
    <strong class="pt-1">{{ header }}</strong>
    <div class="w-100 text-center pb-4">
      <div>
        <strong class="mb-1">Front</strong>
        <FileViewer
          v-if="photoFrontUrl"
          :fileUrl="photoFrontUrl"
        />
        <div
          v-else
          class="img-not-available"
        >
          Not Available
        </div>
      </div>
      <div v-if="shouldShowBack">
        <strong class="mb-1">Back</strong>
        <FileViewer
          v-if="photoBackUrl"
          :fileUrl="photoBackUrl"
        />
        <div
          v-else
          class="img-not-available"
        >
          Not Available
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FileViewer from 'components/common/FileViewer'

  export default {
    name: "everify-case-photo",

    components: {
      FileViewer,
    },

    props: {
      header: {
        type: String,
      },
      photoFrontUrl: {
        type: String,
      },
      photoBackUrl: {
        type: String,
      },
      shouldShowBack: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
