/*
*
* Radio button field that will display Other option field if exists
*
*/
<template>
  <FormField
    :label="label"
    :name="name"
    :orientation="orientation"
    :required="required"
    :errors="errors"
    :hint="hint"
  >
    <select
      v-model="selected"
      class="form-control"
      :disabled="disabled"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-dropdown',
    components: {
      FormField,
    },
    mixins: [FormFieldConcern],

    props: {
      value: {
        type: String,
        required: false,
      },

      options: {
        type: Array,
        required: true,
      },
    },

    emits: ['input'],

    computed: {
      selected: {
        get() { return this.value },
        set(v) { this.$emit('input', v) },
      },
    },
  }
</script>
