<template>
  <div>
    <BackButton
      ref="backButton"
      label="Back to Dashboard"
      :url="dashboardPath"
    />
    <PageHeader
      ref="PageHeader"
      title="Staff Action Display (Tentative Non-Confirmation)"
    />
    <EverifyTncReferredViewer
      v-if="isReferred"
      ref="everifyTncReferredViewer"
      :url="rdcUrl"
    />
    <div
      v-else-if="isPending"
      class="pb-4"
    >
      <h3>What is E-Verify</h3>

      <p>{{ $locale.t('everify.staff.tnc.whatIsEverify') }}</p>

      <h3>Instructions</h3>

      <p>{{ $locale.t('everify.staff.tnc.instructions') }}</p>

      <div>
        <img
          :src="$routes.Images.everify_tnc_header"
          class="img-responsive m-auto"
          alt="Everify Tentative Non-Confirmation Process"
        />
      </div>

      <h3>Further Information</h3>

      <p>
        For more information about E-Verify and the Tentative Non-Confirmation process, please
        <a href="https://www.e-verify.gov/employees/tentative-nonconfirmation-mismatch-overview">
          click here.
        </a>
      </p>
      <p>
        If you have any other questions,
        <span
          v-if="this.administratorEmail"
          ref="administratorEmail"
        >
          <a :href="'mailto:'+this.administratorEmail+'?Subject=Question about my E-Verify case'">
            contact your E-Verify Program Administrator.
          </a>
        </span>
        <span
          v-else
          ref="noAdministratorEmail"
        >
          contact your company's HR representative.
        </span>
      </p>
    </div>
    <div class="row pt-4">
      <div class="col-xs-12 col-md-7">
        <FileViewer
          :fileUrl="everifyCase.fan_url"
        />
      </div>
      <div class="col-xs-12 col-md-5">
        <EverifyTncReviewPanel
          v-if="isPending"
          ref="everifyTncReviewPanel"
          :everify-case="everifyCase"
          :options="tncReviewOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
  // import Util from 'lib/util'
  import PageHeader from 'components/common/PageHeader'
  import FileViewer from 'components/common/FileViewer'
  import BackButton from 'components/common/BackButton'
  import EverifyTncReviewPanel from 'components/everify/staff/EverifyTncReviewPanel'
  import EverifyTncReferredViewer from 'components/everify/staff/EverifyTncReferredViewer'

  export default {
    name: 'the-everify-tnc-viewer-root',
    components: {
      PageHeader,
      FileViewer,
      BackButton,
      EverifyTncReviewPanel,
      EverifyTncReferredViewer,
    },
    props: {
      everifyCase: {
        type: Object,
        required: true,
      },
      tncReviewOptions: {
        type: Array,
        required: true,
      },
      administratorEmail: {
        type: String,
        required: false,
      },
    },
    computed: {
      dashboardPath() {
        return this.$routes.staff_dashboard_path
      },

      isPending() {
        return this.everifyCase.case_status == "pending_referral"
      },

      isReferred() {
        return this.everifyCase.case_status == "referred"
      },

      rdcUrl() {
        return this.everifyCase.rdc_url
      },
    },
  }
</script>
