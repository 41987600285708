<template>
  <tr>
    <td>
      <WbSimpleDateTime :value="bulkUpdateItem.created_at" />
    </td>
    <td
      ref="createdByName"
    >
      {{ createdByUserName }}
    </td>
    <td
      ref="submittedByName"
    >
      {{ submittedByUserName }}
    </td>
    <td
      ref="staffCount"
    >
      {{ userCountText }}
    </td>
    <td
      ref="status"
      class="text-strong"
    >
      {{ status }}
    </td>
    <td class="text-right">
      <a
        ref="viewLink"
        :href="resumeUpdatePath"
        :class="buttonClasses"
        class="btn btn-sm"
      >
        {{ buttonLabel }}
      </a>
    </td>
  </tr>
</template>

<script>
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'user-update-table-row',
    components: {
      WbSimpleDateTime,
    },
    props: {
      bulkUpdateItem: {
        type: Object,
        required: true,
      },
    },
    computed: {
      status () {
        return this.isComplete ? 'Complete' : 'Incomplete'
      },
      userCountText () {
        const { staff_updated_count } = this.bulkUpdateItem
        return staff_updated_count ? `${staff_updated_count} staff` : '--'
      },
      createdByUserName () {
        return this.bulkUpdateItem.created_by_user_name || '--'
      },
      submittedByUserName () {
        return this.bulkUpdateItem.submitted_by_user_name || '--'
      },
      resumeUpdatePath () {
        return getRoute('user_update_path', { id: this.bulkUpdateItem.id })
      },
      isComplete () {
        return this.bulkUpdateItem.current_state === 'complete'
      },
      buttonClasses () {
        return this.isComplete ? 'btn-default' : 'btn-primary'
      },
      buttonLabel () {
        return this.isComplete ? 'View Summary' : 'Resume Update'
      },
    },
  }
</script>
