<template>
  <div>
    <Toolbar>
      <ToolbarTitle
        v-if="hasEmployeeData"
        ref="EmployeeFullNameLink"
      >
        <a :href="employeePath">{{ employeeFullName }}</a>
      </ToolbarTitle>
      <div class="btn-sequence">
        <a
          class="btn btn-link"
          :href="submissionDetailsPath"
        >
          View Submission Details
        </a>
        <WbButton
          ref="acceptBtn"
          label="Accept"
          context="success"
          :is-loading="isAcceptingSubmission"
          @click="accept"
        />
        <WbButton
          ref="rejectBtn"
          label="Reject"
          context="danger"
          :is-loading="isRejectingSubmission"
          @click="showRejectReasonModal"
        />
        <WbButton
          ref="skipBtn"
          label="Skip"
          context="default"
          @click="skip"
        />
      </div>
      <RejectReasonModal
        ref="rejectReasonModal"
        :employee-name="employeeFullName"
        :submission-id="submission.id"
        :document-id="submission.document_id"
        modal-key="rejectionReasonModalKey"
        :on-submit="handleRejectReasonSubmit"
        :free-form-rejection-enabled="currentAccount.freeFormRejectionEnabled"
        :i9-rejection-hide-additional-details-field-enabled="currentAccount.i9RejectionHideAdditionalDetailsFieldEnabled"
      />
    </Toolbar>
    <AlertMessage
      v-if="shouldAlertStartDateMissing"
      ref="startDateMissingAlert"
      variant="warning"
      title="Start Date Missing"
    >
      <div>
        Please visit the
        <a
          :href="employmentPath"
          target="_blank"
        >
          Employment page
        </a> to add the employee's start date to add the employee's start date
      </div>
    </AlertMessage>
    <AlertMessage
      v-if="shouldAlertRemoteReverificationRequested"
      ref="remoteReverificationRequestedAlert"
      variant="warning"
      title="Remote Reverification Requested"
    >
      <div>
        Instructions to complete Remote Reverification have been sent to staff
      </div>
    </AlertMessage>
    <div>
      Submitted
      <WbSimpleDateTime
        ref="SubmissionDateTime"
        :value="submission.submitted_at"
      />
    </div>
    <div ref="primaryDocuments">
      <FileViewer
        v-for="(file, index) in files"
        :key="index"
        allow-download
        :file-url="getFileUrl(file)"
        class="mb-3"
      />
    </div>
    <div
      v-if="hasDocumentationSet"
      ref="SupportingDocuments"
    >
      <h4>Supporting Documentation</h4>
      <SupportingDocumentation :documentation-set="documentationSet" />
    </div>
    <FormResponses
      v-if="hasFormResponses"
      ref="formResponses"
      :responses="formResponses"
    />
  </div>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import FileViewer from 'components/common/FileViewer'
  import FormResponses from 'components/document_submissions/FormResponses'
  import SupportingDocumentation from 'components/document_submissions/i9/SupportingDocumentation'
  import RejectReasonModal from 'components/admin/document_submissions/RejectReasonModal'
  import Toolbar from 'components/common/Toolbar'
  import ToolbarTitle from 'components/common/ToolbarTitle'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import WbButton from 'components/common/WbButton'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import Util from 'lib/util'

  export default {
    components: {
      AlertMessage,
      FileViewer,
      FormResponses,
      Toolbar,
      ToolbarTitle,
      SupportingDocumentation,
      WbButton,
      WbSimpleDateTime,
      RejectReasonModal,
    },
    props: {
      submission: {
        type: Object,
        required: true,
      },
      employee: {
        type: Object,
        required: true,
      },
      rejectionReasonModalKey: {
        type: String,
        required: true,
      },
      allowDownload: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['reviewed'],
    data() {
      return {
        isAcceptingSubmission: false,
        isRejectingSubmission: false,
      }
    },

    computed: {
      hasFormResponses () {
        return this.formResponses && this.formResponses.length
      },
      hasDocumentationSet () {
        return this.documentationSet && this.documentationSet.length
      },
      shouldAlertStartDateMissing () {
        return this.submission.missing_start_date &&
          !this.submission.countersigned
      },
      shouldAlertRemoteReverificationRequested () {
        return this.submission.reverify_in_progress
      },
      documentationSet () {
        return this.submission.documentation_set || []
      },
      files () {
        return this.submission.files || []
      },
      formResponses () {
        return this.submission.fields
      },
      employeePath () {
        return this.$routes.employee_path({ id: this.employee.id })
      },
      employmentPath () {
        return this.$routes.employee_employment_path({ id: this.employee.id })
      },
      employeeFullName () {
        return `${this.employee.first_name} ${this.employee.last_name}`
      },
      hasEmployeeData () {
        return !!this.employee
      },
      submissionDetailsPath () {
        return this.$routes.admin_employee_submission_path({
          employee_id: this.employee.id,
          id: this.submission.id,
        })
      },
      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },
    methods: {
      ...mapActions({
        acceptSubmission: 'onboarding_submissions/acceptSubmission',
        rejectSubmission: 'onboarding_submissions/rejectSubmission',
      }),
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
      getFileUrl (file) {
        const DEFAULT_EXTENSION = 'pdf'
        const hasExtension = file.split('.').length > 1
        if (!hasExtension) {
          file = `${file}.${DEFAULT_EXTENSION}`
        }
        return file
      },
      async accept() {
        this.isAcceptingSubmission = true

        try {
          await this.acceptSubmission({
            submissionId: this.submission.id,
          })

          Util.showFlashNotice('Submission accepted successfully')
          this.goToNextSubmission(this.submission.id)
        } catch (xhr) {
          Util.genericAjaxError('An error occurred accepting this submission.', xhr)
        } finally {
          this.isAcceptingSubmission = false
        }
      },
      showRejectReasonModal () {
        this.setPageContextKeys({
          rejectionReasonModalKey: true,
        })
      },
      skip() {
        this.goToNextSubmission(this.submission.id)
      },
      handleRejectReasonSubmit() {
        this.goToNextSubmission(this.submission.id)
      },
      goToNextSubmission(currentSubmissionId) {
        this.$emit('reviewed', currentSubmissionId)
      },
      hasDocumentBack (document) {
        return !!document.file_back_url
      },
    },
  }
</script>
