<template>
  <div
    class="form-group"
    :class="{'has-error' : hasError, 'required' : required }"
  >
    <FormLabel
      v-if="label"
      :label="label"
      :name="name"
      :required="required"
      :orientation="orientation"
      :helpPopoverText="helpPopoverText"
    />
    <div :class="{ 'col-sm-7 col-md-7' : horizontal }">
      <slot />
      <small
        v-if="hint"
        class="help-block"
      >{{ hint }}</small>
      <small
        v-if="hasError"
        class="help-block"
      >{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script>
  import FormLabel from 'components/common/FormLabel'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field',
    components: {
      FormLabel,
    },
    mixins: [FormFieldConcern],

    computed: {
      hasError() {
        return this.errors && this.errors[this.name] && this.errors[this.name].length > 0
      },

      errorMessage() {
        if (!this.hasError) { return null };
        return this.errors[this.name].join(' ')
      },
    },
  }
</script>
