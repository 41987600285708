<template>
  <div>
    <PageHeader
      title="Admin Permissions"
      lead="Manage the administrators permission policies for your company's WorkBright account. Create or modify administrator policies to customize access."
    />
    <div class="btn-group my-3">
      <a
        class="btn btn-primary"
        :href="$routes.managed_access_policy_template_new_path"
      >
        <WbIcon type="plus-circle" />
        New Policy
      </a>
    </div>
    <Spinner
      v-if="isLoading"
      ref="loader"
    />
    <template v-else>
      <template v-if="hasSufficientData">
        <ManagedAccessPolicyTemplatesTable
          :policies="policies"
          @policySelected="handlePolicySelected"
        />
        <WbListPaginationFooter
          ref="pagination"
          :previousPage="pagination.previous_page"
          :nextPage="pagination.next_page"
          :lastPage="pagination.total_pages"
          :totalItems="pagination.total_items"
          :indexStart="indexStart"
          :indexEnd="indexEnd"
          @pageChange="handlePageChange"
        />
      </template>
      <AlertMessage
        v-else
        ref="noDataMessage"
        alertType="info"
        message="There are currently no policies to display"
      />
    </template>
    <ManagedAccessPolicyTemplatesSlideout
      ref="ManagedAccessPolicyTemplatesSlideout"
      :shown="shouldShowModal"
      :policy="selectedPolicy"
      @hidden="handleModalHiddden"
    />
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import AlertMessage from 'components/common/AlertMessage'
  import ManagedAccessPolicyTemplatesSlideout from 'components/admin/managed_access_policy_templates/ManagedAccessPolicyTemplatesSlideout'
  import ManagedAccessPolicyTemplatesTable from 'components/admin/managed_access_policy_templates/ManagedAccessPolicyTemplatesTable'
  import PageHeader from 'components/common/PageHeader'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'
  import WbIcon from 'components/common/WbIcon'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'

  export default {
    name: 'the-managed-access-policy-templates-root',
    components: {
      AlertMessage,
      ManagedAccessPolicyTemplatesSlideout,
      ManagedAccessPolicyTemplatesTable,
      PageHeader,
      Spinner,
      WbIcon,
      WbListPaginationFooter,
    },
    data () {
      return {
        isLoading: false,
        page: 1,
        policyId: null,
      }
    },
    computed: {
      ...mapGetters({
        policies: 'managed_access_policy_templates/collection',
        pagination: 'managed_access_policy_templates/pagination',
      }),
      hasSufficientData () {
        return this.policies?.length
      },
      indexEnd () {
        let val = 0
        if (this.pagination) {
          val = Math.min(this.indexStart + this.pagination.per_page - 1, this.pagination.total_items)
        }
        return val
      },
      indexStart () {
        let val = 0
        if (this.pagination) {
          val = (this.pagination.page - 1) * this.pagination.per_page + 1
        }
        return val
      },
      selectedPolicy () {
        return this.policies?.find(policy => policy.id === this.policyId)
      },
      shouldShowModal () {
        return this.policyId !== null
      },
    },
    created () {
      this.requestData()
      this.loadPermissionsResources()
    },
    methods: {
      ...mapActions({
        fetchManaged: 'managed_access_policy_templates/fetch',
        fetchPermissionPolicies: 'permissions/fetchPermissionPolicies',
      }),
      handlePageChange (page) {
        this.page = page
        this.requestData()
      },
      handleModalHiddden () {
        this.policyId = null
      },
      handlePolicySelected (policyId) {
        this.policyId = policyId
      },
      async loadPermissionsResources () {
        const loadingName = 'permissions_resources'
        
        try {
          this.$wait.start(loadingName)
          await this.fetchPermissionPolicies()
        } catch (xhr) {
          Util.genericAjaxError(this.$locale.t("errors.ajax.generic"), xhr)
        } finally {
          this.$wait.end(loadingName)
        }
      },
      async requestData () {
        this.isLoading = true

        try {
          await this.fetchManaged({
            page: this.page,
          })
        } catch (xhr) {
          Util.genericAjaxError('An error occurred fetching managed policies.', xhr)
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>
