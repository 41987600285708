<template>
  <ListGroupItem>
    <WbSimpleDateTime
      v-if="date"
      :value="date"
    />
    <div class="h4">{{ documentName }}</div>
    <DefinitionList inline>
      <template #term>Status:</template>
      <template #definition>
        <span class="text-capitalize">{{ status }}</span>
      </template>
    </DefinitionList>
    <div class="accordion">
      <div
        role="button"
        class="mb-1"
        @click="isExpanded = !isExpanded"
      >
        <WbIcon
          :type="isExpanded ? 'caret-up' : 'caret-down'"
          class="mr-1"
        />
        {{ isExpanded ? 'Hide' : 'Show' }} Checks
      </div>
      <ListGroup
        v-if="requiredChecks.length"
        class="collapse"
        :class="{ show: isExpanded }"
      >
        <ListGroupItem
          v-for="(check, index) in requiredChecks"
          :key="index"
        >
          <WbIcon
            :type="getCheckIcon(check)"
            :class="getCheckClassName(check)"
          />
          <strong>{{ getCheckLabel(check) }}</strong>
          <div class="text-small">
            {{ getCheckDescription(check) }}
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
  </ListGroupItem>
</template>
<script>
  import DefinitionList from 'components/common/DefinitionList'
  import ListGroup from 'components/common/ListGroup'
  import ListGroupItem from 'components/common/ListGroupItem'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import WbIcon from 'components/common/WbIcon'
  import PersonaLocale from 'locales/persona.en.yml' // eslint-disable-line import/extensions

  export default {
    components: {
      DefinitionList,
      ListGroup,
      ListGroupItem,
      WbIcon,
      WbSimpleDateTime,
    },

    props: {
      inquiry: {
        type: Object,
        required: true,
      },
      documentName: {
        type: String,
      },
    },

    data () {
      return {
        isExpanded: false,
      }
    },

    computed: {
      date () {
        return this.inquiry.external_data.updated_at
      },
      hasSufficientData () {
        return !!this.inquiry
      },
      requiredChecks () {
        return this.inquiry.external_data.checks
      },
      status () {
        return this.inquiry.status
      },
    },

    methods: {
      getCheckLabel (check) {
        return PersonaLocale.en[check.name]?.label || check.name
      },
      getCheckDescription (check) {
        return PersonaLocale.en[check.name]?.description
      },
      getCheckClassName (check) {
        return check.status === 'passed' ? 'text-success' : 'text-danger'
      },
      getCheckIcon (check) {
        return check.status === 'passed' ? 'check' : 'close'
      },
    },
  }
</script>
