// Generic Component for Filtering
<template>
  <div class="filters">
    <h4 class="text-strong">
      Filter
      <small
        v-if="anyFiltersActive"
        class="label label-success ml-1"
      >
        ON
      </small>
    </h4>
    <div
      v-for="(filter, index) in filters"
      :key="index"
      class="pb-2"
    >
      <SelectFilter
        v-if="filter.type == 'select'"
        :collectionViewName="collectionViewName"
        :filter="filter"
      />
      <MultiSelectFilter
        v-if="filter.type == 'multiselect'"
        :collectionViewName="collectionViewName"
        :filter="filter"
      />
    </div>
    <div class="mt-4">
      <button
        v-if="anyFiltersActive"
        class="btn btn-sm btn-info cancel-action"
        @click="handleClickClearFilters"
      >
        <i class="fa fa-times-circle" />
        Clear All Filters
      </button>
    </div>
  </div>
</template>

<script>
  import SelectFilter from 'components/common/collection_view_filters/SelectFilter'
  import MultiSelectFilter from 'components/common/collection_view_filters/MultiSelectFilter'
  import { CLEAR_FILTER_VALUES } from 'vuex/modules/collection_view/mutation_types'

  export default {
    name: 'list-filter-set',
    components: {
      SelectFilter,
      MultiSelectFilter,
    },
    props: {
      // Array with filter definitions:
      // (actual keys may vary with specific filter type)
      // [
      //   {
      //     type: 'select/multiselect',
      //     label: 'Internal Only',
      //     filter_key: 'internal',
      //     options: [
      //       { value: 'x', label: 'Internal Only' },
      //       ...
      //     ]
      //   },
      //   ...
      // ]
      filters: {
        type: Array,
        required: true,
      },
      collectionViewName: {
        type: String,
        required: true,
      },
    },
    computed: {
      anyFiltersActive() {
        return this.$store.getters[`${this.collectionViewName}/filtersActive`]
      },
    },

    methods: {
      handleClickClearFilters() {
        this.$store.commit(`${this.collectionViewName}/${CLEAR_FILTER_VALUES}`)
      },
    },
  }
</script>
