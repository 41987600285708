<template>
  <div class="panel gray-bg p-3">
    <AlertMessage
      v-if="authenticationFailure"
      ref="authFailure"
      title="Authentication Failure"
      :message="$locale.t('everify_account.authFailure', companyName, helpDeskLink)"
    />

    <h3>
      Configure E-Verify
    </h3>
    <p>This information can be found on the E-Verify MOU you signed.</p>

    <form>
      <FormFieldInput
        v-model="formData.company_id"
        label="Company ID"
        name="company_id"
        placeholder="3048309"
        :required="true"
        :errors="formErrors"
      />

      <FormFieldInput
        v-model="formData.program_administrator_id"
        label="E-Verify Program Administrator ID"
        name="program_administrator_id"
        placeholder="KSDFJ3498"
        :required="true"
        :errors="formErrors"
      />

      <FormField
        class="mb-0"
        label="E-Verify Program Administrator Name"
        :required="true"
      />
      <div class="row">
        <div class="col-md-6">
          <FormFieldInput
            v-model="formData.program_administrator_first_name"
            name="program_administrator_first_name"
            placeholder="First Name"
            :required="true"
            :errors="formErrors"
          />
        </div>
        <div class="col-md-6">
          <FormFieldInput
            v-model="formData.program_administrator_last_name"
            name="program_administrator_last_name"
            placeholder="Last Name"
            :required="true"
            :errors="formErrors"
          />
        </div>
      </div>

      <FormFieldInput
        v-model="formData.program_administrator_email"
        label="E-Verify Program Administrator Email"
        name="program_administrator_email"
        placeholder="james@smith.com"
        :required="true"
        :errors="formErrors"
      />

      <FormFieldInput
        v-model="formData.program_administrator_phone"
        label="E-Verify Program Administrator Phone"
        name="program_administrator_phone"
        placeholder="123-456-7890"
        :required="true"
        :errors="formErrors"
      />

      <FormField
        label="Web Services Credentials"
        :required="true"
      >
        <div v-if="modifyingWSC">
          <div class="row">
            <div class="col-md-6">
              <FormFieldInput
                v-model="formData.web_service_username"
                name="web_service_username"
                placeholder="Username"
                :required="true"
                :errors="formErrors"
              />
            </div>
            <div class="col-md-6">
              <FormFieldInput
                v-model="formData.web_service_password"
                class="mb-1"
                name="web_service_password"
                placeholder="Password"
                type="password"
                :required="true"
                :errors="formErrors"
              />
              <div class="mx-2">
                <small v-if="!isNewAccount">
                  The existing password will be used until changed.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="well">
            <strong>
              Web Services are currently set up for user {{formData.web_service_username}}.
              <br />
              <a
                href="javascript:void(0)"
                @click="modifyCredentials"
              >
                Update Credentials
              </a>
            </strong>
          </div>
        </div>
      </FormField>

      <div
        ref="startDateMessage"
        class="alert alert-info"
      >
        {{ $locale.t('everify_account.startDateMessage') }}
      </div>

      <div class="form-group text-right">
        <LaddaButton
          ref="submitButton"
          class="submit"
          @click="handleSubmit"
        >Submit</LaddaButton>
      </div>
    </form>
  </div>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldInput from 'components/common/FormFieldInput'
  import LaddaButton from 'components/common/LaddaButton'
  import Routes from 'lib/routes'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import PubSub from 'lib/pub_sub'
  import AlertMessage from 'components/common/AlertMessage'

  export default {
    name: 'everify-account-form-container',

    components: {
      FormField,
      FormFieldInput,
      LaddaButton,
      AlertMessage,
    },

    props: {
      everifyAccount: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        formData: {
          company_id: '',
          program_administrator_id: '',
          program_administrator_first_name: '',
          program_administrator_last_name: '',
          program_administrator_email: '',
          program_administrator_phone: '',
          web_service_username: '',
          web_service_password: '',
        },
        formErrors: {},
        modifyingWSC: true,
      }
    },

    computed: {
      isNewAccount() {
        return this.everifyAccount.id === null
      },

      // Does the account have valid Web Services credentials (in Fritz)?
      isAuthenticated() {
        return this.everifyAccount.everify_authenticated === true
      },

      authenticationFailure() {
        return !this.isNewAccount && !this.isAuthenticated
      },

      companyName() {
        return this.everifyAccount.company_name || 'this account'
      },

      helpDeskLink() {
        return this.$routes.External.everify_web_services_password_helpdesk_url
      },

      successStr() {
        if (this.isNewAccount) {
          return Locale.t('everify_account.create.success')
        } else {
          return Locale.t('everify_account.update.success')
        }
      },
    },

    mounted() {
      this.subscribeToPubSubEvents()
    },

    created() {
      this.formData = { ...this.everifyAccount }

      // If a username is already set, indicate that we're not modifying the web service credentials
      this.modifyingWSC = !this.everifyAccount.web_service_username
    },

    methods: {
      handleSubmit() {
        this.success = false
        this.formErrors = {}

        $.ajax({
          method: 'POST',
          url: Routes.everify_account_path,
          data: {
            everify_account: this.formData,
          },
          wbGenericFailureMsg: Locale.t("everify_account.create.fail"),
        }).success( (createdAccount) => {
          this.$store.commit('everify_accounts/SET_RECORD', createdAccount)
          Util.showFlashNotice(this.successStr)
        }).error( (xhr) => {
          if (xhr && xhr.responseJSON) { this.formErrors = xhr.responseJSON.errors || {} }
        }).always(() => {
          this.$refs.submitButton.stop()
        })
      },

      modifyCredentials() {
        this.modifyingWSC = true
      },

      subscribeToPubSubEvents() {
        this.updateChannel = PubSub.subscribeToUserChannel({
          topic: 'everify_account',
          unique: true,
        })

        this.updateChannel.bind('update', (data) => {
          this.$store.commit('everify_accounts/MERGE_INTO_RECORD', data)
        })
      },
    },
  }
</script>
