<template>
  <header :class="headerClassName">
    <h2 v-if="title || $slots.title">
      <template v-if="$slots.title">
        <slot name="title" />
      </template>
      <template v-else>
        {{ title }}
      </template>
    </h2>
    <h4 v-if="lead || $slots.lead">
      <template v-if="$slots.lead">
        <slot name="lead" />
      </template>
      <template v-else>
        {{ lead }}
      </template>
    </h4>
  </header>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
      },
      lead: {
        type: String,
      },
      variant: {
        type: String,
        validator: value => {
          return ['bordered'].includes(value)
        },
      },
    },
    computed: {
      headerClassName () {
        const values = ['page-header']

        if (this.variant) {
          values.push(`page-header-${this.variant}`)
        }
        return values
      },
    },
  }
</script>
