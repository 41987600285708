<template>
  <div>
    <WbModal
      id="historical-submission-set-as-current-notice-modal"
      ref="modal"
      title="This submission will be accepted"
      :watchPageContextVariable="modalKey"
    >
      <p>
        This submission is rejected. In order to set it as the current submission, you must accept it.
      </p>
      <p>
        Do you wish to proceed?
      </p>

      <template #footer>
        <WbButton
          context="default"
          @click="hideModal"
        >
          Cancel
        </WbButton>
        <WbButton
          @click="handleNoticeAccept"
        >
          Accept
        </WbButton>
      </template>
    </WbModal>

    <div
      v-if="!this.isCurrentSubmission"
      class="pull-right historical-submission-alert"
    >
      <div
        class="alert alert-warning"
        role="alert"
      >
        <p>
          <WbIcon
            type="archived-file"
            iconSet="a4s-icon"
          />
          You are viewing a historical submission.
        </p>

        <div class="btn-sequence mt-3">
          <WbButton
            context="info"
            class="js-open-historical-submissions mr-3"
          >
            View all submissions
          </WbButton>
          <WbButton
            ref="setAsCurrentSubmissionBtn"
            :disabled="disabled"
            :tooltip="setAsCurrentTooltip"
            :isLoading="isLoading"
            @click="handleSetAsCurrent"
          >
            Set as Current Submission
          </WbButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Locale from 'lib/locale'
  import PermissionEnforcement from 'components/mixins/PermissionEnforcement'
  import Util from 'lib/util'
  import WbModal from 'components/common/WbModal'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS, DELETE_PAGE_CONTEXT_KEY }  from 'vuex/mutation_types'

  export default {
    name: 'historical-submission-alert',
    components: {
      WbButton,
      WbModal,
      WbIcon,
    },
    mixins: [PermissionEnforcement],

    props: {
      submissionId: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        isLoading: false,
      }
    },

    computed: {
      disabled() {
        if (!this.submission) {
          return true
        }

        return !this.isPermitted
      },

      submission() {
        return this.getSubmission(this.submissionId)
      },

      isCurrentSubmission() {
        const assignments = this.assignmentFindBy({ current_submission_id: this.submissionId })
        return !!assignments[0]
      },

      setAsCurrentTooltip () {
        return !this.isPermitted ? 'You are not permitted to perform this action.' : null
      },

      // Finds the document assignment from the Vuex store, it does not matter if this submission is current or not,
      // which is then used to set the assignment as current
      //
      // Returns single matching assignment record.
      assignment() {
        return this.assignmentFindBy({
          document_id: this.submission.document_id,
          employee_id: this.submission.employee_id,
        })[0]
      },

      modalKey () {
        return "historical-submission-set-as-current-notice-modal"
      },

      ...mapGetters({
        getSubmission: 'document_submissions/get',
        assignmentFindBy: 'document_assignments/findBy',
        getDocument: 'documents/get',
      }),
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
        deletePageContextKey: DELETE_PAGE_CONTEXT_KEY,
      }),
      ...mapActions({
        setAsCurrent: 'document_assignments/setAsCurrent',
      }),

      hideModal() {
        this.deletePageContextKey(this.modalKey)
      },

      handleSetAsCurrent() {
        // If already current, we don't continue
        if (this.isCurrentSubmission) {
          return
        }

        if (this.submission.status === 'accepted') {
          this.callSetAsCurrent()
        } else {
          this.setPageContextKeys({[this.modalKey]: true})
        }
      },

      handleNoticeAccept() {
        this.callSetAsCurrent()
        this.hideModal()
      },

      callSetAsCurrent() {
        this.isLoading = true
        this.setAsCurrent({
          auto_accept_historical_rejection: true,
          document_assignment: this.assignment,
          new_current_submission: this.submission,
        }).then(assignment => {
          const documentName = this.getDocument(assignment.document_id).name
          Util.navigateAndShowFlashNotice(window.location.href, Locale.t('assignments.setAsCurrent.success', documentName))
        }).catch(xhr => {
          Util.genericAjaxError(Locale.t('assignments.setAsCurrent.fail'), xhr)
        }).finally(() => {
          this.isLoading = false
        })
      },
    },
  }
</script>
