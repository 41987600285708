<template>
  <div class="navbar-header">
    <button
      class="navbar-toggle"
      type="button"
      data-toggle="collapse"
      data-target=".navbar-main-collapse"
    >
      <span class="sr-only">Toggle navigation</span>
      <WbIcon type="bars" />
    </button>
    <a
      class="navbar-brand d-flex align-items-center"
      :href="$routes.staff_dashboard_path"
    >
      <img
        :src="$routes.Images.logo"
        class="logo"
        alt="Workbright Logo"
      />
    </a>
  </div>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'
  export default {
    components: {
      WbIcon,
    },
  }
</script>
