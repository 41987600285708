// Reactive Slideout Panel (slides in from right of the screen). Built off of a Bootstrap modal.
// This is similar to SlideoutPanel but includes an additional "extended-panel" for added features/functionality.

<template>
  <div v-if="shouldShow">
    <div
      ref="panel"
      class="slideout-panel-modal modal right fade"
      :class="sizeClass"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="extended-panel">
            <div class="extended-content">
              <slot name="extended-content" />
            </div>
          </div>
          <div class="primary-panel panel panel-snapshot">
            <div class="panel-heading">
              <!-- Close with the cancel() method, not data-dismiss, allowing us to check the locked setting -->
              <button
                class="close"
                aria-label="Close"
                type="button"
                @click="cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <slot name="primary-title" />
            </div>
            <div class="primary-content panel-body">
              <slot name="primary-content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseModal from 'components/mixins/BaseModal'
  import BaseSlideout from 'components/mixins/BaseSlideout'

  export default {
    name: 'slideout-panel-extended',
    mixins: [BaseModal, BaseSlideout],
  }
</script>
