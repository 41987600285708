// Standard Bootstrap-based modal
//
// Refer to BaseModal documentation for usage
<template>
  <div
    v-if="shouldShow"
    ref="panel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      v-bind:class="{ 'modal-lg': largeModal }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <!-- Close with the cancel() method, not data-dismiss, allowing us to check the locked setting -->
          <button
            class="close"
            aria-label="Close"
            type="button"
            @click="cancel"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3
            v-if="title"
            class="modal-title"
          >{{ title }}</h3>
          <slot
            v-if="!title"
            name='title'
          />
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <slot name='footer' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseModal from 'components/mixins/BaseModal'

  export default {
    name: 'wb-modal',
    mixins: [BaseModal],

    props: {
      largeModal: {
        type: Boolean,
        required: false,
      },
    },
  }
</script>
