<template>
  <div class="row">
    <div class="col-lg-10">
      <WbPanel title="Service Provider Settings">
        <p>
          These are the SAML settings for WorkBright (the SSO "Service Provider"). They should be copied into your Identity Provider application.
        </p>
        <form class="form-horizontal pt-4">
          <FormFieldReadOnlyText
            ref="identifier"
            label="Identifier (Entity ID)"
            :value="ssoPaths.identifier"
          />

          <FormFieldReadOnlyText
            ref="replyUrl"
            label="Reply URL (Assertion Consumer Service)"
            :value="ssoPaths.reply"
          />

          <FormFieldReadOnlyText
            ref="signInUrl"
            label="Sign in URL"
            :value="ssoPaths.signIn"
          />

          <FormFieldReadOnlyText
            ref="signOutUrl"
            label="Sign out URL"
            :value="ssoPaths.signOut"
          />

          <FormFieldReadOnlyText
            ref="relayState"
            label="Relay State"
            :value="ssoPaths.root"
          />
        </form>
      </WbPanel>
    </div>
  </div>
</template>

<script>
  import WbPanel from 'components/common/WbPanel'
  import FormFieldReadOnlyText from 'components/common/FormFieldReadOnlyText'

  export default {
    name: 'sso-service-provider-settings-container',

    components: {
      WbPanel,
      FormFieldReadOnlyText,
    },

    props: {
      ssoPaths: {
        type: Object,
        required: true,
      },
    },
  }
</script>
