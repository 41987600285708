<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="row breathe shallow">
    <div
      v-if="label"
      class="col-xs-7 text-right action-label"
    >
      <strong>{{ actionName }}</strong>
    </div>
    <div class="col-xs-5">
      <input
        ref="toggle"
        v-model="permission.grant"
        type="checkbox"
        data-toggle="toggle"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'permissions-toggle',

    props: {
      permission: {
        type: Object,
        required: true,
      },

      label: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    emits: [
      'permissionChanged',
    ],

    computed: {
      actionName() {
        return this.permission.actionName()
      },

      grant() {
        return this.permission.grant
      },

      disabled() {
        return this.permission.disabled()
      },
    },

    watch: {
      grant(newGrant) {
        if (this.$refs.toggle.checked != newGrant) {
          $(this.$refs.toggle).bootstrapToggle(newGrant ? 'on' : 'off')
        }
      },

      disabled() {
        this.refreshDisabled()
      },
    },

    mounted() {
      $(this.$refs.toggle).bootstrapToggle({
        on: 'Allow',
        off: 'Deny',
        onstyle: 'success',
        offstyle: 'danger',
      }).change(((event) => {
        this.handleChange(event.target.checked)
      }))
      this.refreshDisabled()
    },

    methods: {
      handleChange (checked) {
        this.$emit('permissionChanged', this.permission, checked)
      },
      refreshDisabled() {
        $(this.$refs.toggle).bootstrapToggle(this.disabled ? 'disable' : 'enable')
      },
    },
  }
</script>
