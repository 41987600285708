<template>
  <div>
    <PageHeader title="Promote Staff to Admin" />
    <WbCard
      v-show="!isWizardMode"
      class="form-horizontal"
    >
      <div v-show="isSelectEmployeeMode || isFormMode">
        <FormField
          label='Staff members'
          :required='true'
          :errors="errors"
          name='employee_id'
          hint="Select the staff member you want to promote."
          orientation="horizontal"
        >
          <EmployeeSearch
            ref="employeeSearch"
            v-model="employee"
          />
        </FormField>
      </div>
      <div v-if="isFormMode">
        <AdminUserPolicyFields
          ref="policyFields"
          :accessPolicies="accessPolicies"
          :groups="groups"
          :accessPolicy="formData.accessPolicy"
          :scope="formData.scope"
          :errors="errors"
          @accessPolicySelected="handleAccessPolicySelected"
          @scopeSelected="handleScopeSelected"
        />
        <div
          v-show="shouldShowCustomPolicyPermissions"
          class="form-group"
        >
          <FormField
            label="Permissions"
            name="permissions"
            orientation="horizontal"
            :errors="errors"
            :required="true"
          >
            <div class="pt-1">
              <span
                v-if="customAccess.valid"
                id="policy-msg"
              >
                &#10003; Permissions Created
              </span>
              <WbButton @click="switchToWizardMode">
                {{ customAccess.valid ? "Modify" : "Create permissions" }}
              </WbButton>
            </div>
          </FormField>
        </div>
        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 mt-3">
            <WbButton
              ref="saveButton"
              label="Save"
              :isLoading="isSaveButtonLoading"
              @click="submit"
            />
            <WbButton
              ref="cancelButton"
              context="default"
              label="Cancel"
              @click="cancel"
            />
          </div>
        </div>
      </div>
    </WbCard>
    <CustomAccessPolicyWizard
      v-if="isWizardMode"
      ref="wizard"
      :policy="customAccess.wizardPermissions"
      :name="fullName"
      :backButtonEnabled="false"
      @prewizardBack="handleWizardBack"
      @done="handleDoneWizard"
    />
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import AdminUserPolicyFields from 'components/admin/admin_users/AdminUserPolicyFields'
  import CustomAccessPolicyWizard from "components/admin/custom_access_policies/CustomAccessPolicyWizard"
  import EmployeeSearch from 'components/employees/EmployeeSearch'
  import FormField from 'components/common/FormField'
  import PageHeader from 'components/common/PageHeader'
  import Policy from "models/policy_generator/policy"
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import WbCard from 'components/common/WbCard'

  export default {
    name: 'the-new-admin-promote-root',

    components: {
      AdminUserPolicyFields,
      CustomAccessPolicyWizard,
      EmployeeSearch,
      FormField,
      PageHeader,
      WbButton,
      WbCard,
    },

    props: {
      accessPolicies: {
        type: Array,
        required: true,
      },
      groups: {
        type: Array,
        default: () => ([]),
      },
    },

    data() {
      return {
        customAccess: {
          valid: null,
          permissions: new Policy(),
          wizardPermissions: new Policy(),
        },
        search: {
          elements: null,
        },
        employee: null,
        errors: {},
        formData: {
          accessPolicy: null,
          scope: this.scope?.map((s) => parseInt(s)) || [],
        },
        mode: 'select-employee',
        isSaveButtonLoading: false,
      }
    },

    computed: {
      isSelectEmployeeMode() {
        return this.mode === 'select-employee'
      },

      isFormMode() {
        return this.mode === 'form'
      },

      isWizardMode() {
        return this.mode === 'wizard'
      },

      shouldShowCustomPolicyPermissions() {
        return this.formData.accessPolicy === 'custom_access'
      },

      canSwitchToWizardMode() {
        return (Boolean(this.employee) && this.formData.scope.length > 0)
      },

      fullName() {
        return `${this.employee.first_name} ${this.employee.last_name}`
      },

      accessRole() {
        if (this.formData.accessPolicy === null) {return null}

        return this.formData.accessPolicy === 'full_access' ? 'admin' : 'restricted_admin'
      },

      policyRequestData() {
        return this.formData.accessPolicy === 'custom_access' ? JSON.stringify(this.customAccess.permissions.toJSON()) : null
      },

      requestData() {
        return {
          promote: {
            employee_id: this.employee.id,
            access_role: this.accessRole,
            access_policy: this.formData.accessPolicy,
            scope: this.formData.scope.length > 0 ?  this.formData.scope : null,
            policy: this.policyRequestData,
          },
        }
      },
    },

    watch: {
      employee() {
        this.switchToFormMode()
      },
    },

    methods: {
      ...mapActions({
        promoteEmployee: 'admin_users/promoteEmployee',
      }),

      async submit() {
        this.clearErrors()

        if (!this.localValidate()){
          return
        }

        try {
          this.isSaveButtonLoading = true
          const response = await this.promoteEmployee({data: this.requestData})
          Util.navigateAndShowAjaxFlashNotice(this.$routes.admin_users_path, response.xhr)
        } catch (xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError('An error occurred trying to create this admin user', xhr)
          }
        } finally {
          this.isSaveButtonLoading = false
        }
      },

      handleAccessPolicySelected(accessPolicy) {
        this.formData.accessPolicy = accessPolicy
      },

      handleScopeSelected(scope) {
        this.formData.scope = scope
      },

      handleCustomAccess() {
        if (this.formData.accessPolicy === "custom_access"){
          this.customAccess.selected = true
          this.customAccess.permissions.setScope(this.formData.scope)
          this.customAccess.valid = this.customAccess.permissions.valid()
        } else {
          this.customAccess.selected = false
        }
      },

      switchToFormMode() {
        this.mode = 'form'
      },

      switchToWizardMode() {
        if (this.canSwitchToWizardMode){
          this.mode = 'wizard'
        } else {
          Util.showFlashNotice("Please fill in all admin information before creating the permissions.")
        }
      },

      handleWizardBack() {
        this.customAccess.wizardPermissions = this.customAccess.permissions
        this.switchToFormMode()
      },


      handleDoneWizard() {
        this.customAccess.permissions = this.customAccess.wizardPermissions
        this.clearErrors()
        this.switchToFormMode()
        this.handleCustomAccess()
      },

      localValidate() {
        if (this.formData.accessPolicy === "custom_access"){
          if (!this.customAccess.permissions.valid()){ // If not valid
            this.errors.permissions = ["This is required"]
          }
        }

        return Object.keys(this.errors).length === 0
      },

      cancel() {
        window.location.href = this.$routes.admin_users_path
      },

      clearErrors() {
        this.errors = {}
      },
    },
  }
</script>
