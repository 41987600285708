<template>
  <div>
    <PageHeader title="Upload Your Documentation" />
    <AlertMessage
      v-if="hasErrors && !isPersonaVerifiable(currentAttachment)"
      ref="errorMessage"
      :message="errorMessage"
    />
    <PersonaUploadDocumentation
      v-if="isPersonaVerifiable(currentAttachment)"
      :key="personaUploadDocumentationKey"
      ref="personaUploadDocumentation"
      :document="getDocument(currentAttachment)"
      :errors="errors"
      :documentationKey="getDocumentationKey(currentAttachment)"
      :attachmentAttributes="getAttachmentAttributes(currentAttachment)"
      :currentEmployee="currentEmployee"
      :personaSettings="personaSettings"
      @update="(formData) => {updateData(currentAttachment, formData)}"
      @verification-created="handleVerificationCreated"
      @verification-finished="(formData) => handleVerificationFinished(currentAttachment, formData)"
    />
    <UploadDocumentation
      v-else
      :key="uploadDocumentationKey"
      ref="uploadDocumentation"
      :document="getDocument(currentAttachment)"
      :errors="errors"
      :attachmentAttributes="getAttachmentAttributes(currentAttachment)"
      :currentAccount="currentAccount"
      :skipVeriff="skipVeriff"
      @update="(formData) => {updateData(currentAttachment, formData)}"
    />
  </div>
</template>
<script>
  import PageHeader from 'components/common/PageHeader'
  import AlertMessage from "components/common/AlertMessage"
  import UploadDocumentation from 'components/document_submissions/i9/UploadDocumentation'
  import { mapGetters } from 'vuex'
  import PersonaUploadDocumentation from 'components/document_submissions/i9/PersonaUploadDocumentation'

  export default {
    name: 'the-upload-documentation-root',
    components: {
      AlertMessage,
      PageHeader,
      UploadDocumentation,
      PersonaUploadDocumentation,
    },
    props: {
      documentVerificationEnabled: {
        type: Boolean,
        default: false,
      },
      documentationSet: {
        type: Array,
        required: true,
      },
      currentEmployee: {
        type: Object,
        required: true,
      },
      personaSettings: {
        type: Object,
        required: false,
        default: () => {},
      },
      handleUpdate: {
        type: Function,
        required: true,
      },
      verificationCreatedCallback: {
        type: Function,
        required: true,
      },
      verificationFinishedCallback: {
        type: Function,
        required: true,
      },
      skipVeriff: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        success: [],
        errors: {},
        documentIndex: 0,
      }
    },
    computed: {
      ...mapGetters({
        currentAccount: 'account/current',
      }),
      hasErrors () {
        return Object.keys(this.errors).length
      },
      currentAttachment () {
        return this.documentationSet[this.documentIndex]
      },
      errorMessage () {
        return !!this.errors.message ? this.errors.message : this.$locale.t('errors.form.generic')
      },
      personaUploadDocumentationKey() {
        return `persona${this.currentAttachment.cid}`
      },
      uploadDocumentationKey() {
        return `upload${this.currentAttachment.cid}`
      },
    },
    methods: {
      getDocument (attachmentModel) {
        return {...attachmentModel.docSetChoice.attributes}
      },
      getDocumentationKey(attachmentModel) {
        return `${attachmentModel.get('documentation_key')}`
      },
      getAttachmentAttributes (attachmentModel) {
        return {...attachmentModel.attributes}
      },
      updateData (attachment, data) {
        const attributes = {...data}
        this.handleUpdate(attachment, attributes)
      },
      setErrors (errors) {
        this.errors = errors
        this.scrollToTop()
      },
      setDocumentIndex (index) {
        this.documentIndex = index
      },
      isPersonaVerifiable(attachmentModel) {
        return this.documentVerificationEnabled &&
          attachmentModel.docSetChoice.isPersonaSupported &&
          this.isOfVerifiableAge()
      },
      isOfVerifiableAge () {
        return (this.currentEmployee?.age || 0) >= 14
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
      handleVerificationCreated() {
        this.verificationCreatedCallback()
      },
      handleVerificationFinished(attachment, attributes) {
        this.verificationFinishedCallback(attachment, {...attributes})
      },
    },
  }
</script>
