<template>
  <div
    class="wb-dot-indicator"
    :class="`wb-dot-indicator--${variant}`"
  />
</template>

<script>
  export default {
    name: 'wb-dot-indicator',
    props: {
      variant: {
        required: true,
        default: '',
      },
    },
  }
</script>
