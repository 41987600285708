<template>
  <div
    class="alert"
    :class="alertClasses"
  >
    <strong v-if="title">
      {{title}}
    </strong>
    <div
      v-if="message"
      v-html="message"
    />
    <slot v-else />
  </div>
</template>
<script>

  export default {
    name: 'alert-message',

    props: {
      elevated: {
        type: Boolean,
      },
      title: {
        type: String,
        required: false,
      },
      message: {
        type: String,
        required: false,
      },
      variant: {
        type: String,
        required: false,
        default: 'danger',
        validator: value => ['danger', 'info', 'warning', 'success', 'secondary'].includes(value),
      },
    },

    computed: {
      alertClasses() {
        const classes = [`alert-${this.variant}`]
        if (this.elevated) {
          classes.push('alert-elevated')
        }

        return classes
      },
    },
  }
</script>
