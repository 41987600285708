<template>
  <RadioToggle
    v-if="selectedRadioToggleOptions"
    ref="toggle"
    title="Status:"
    :value="status"
    :options="selectedRadioToggleOptions"
    @selected="handleFilterChange"
  />
</template>

<script>
  import RadioToggle from 'components/common/RadioToggle'

  export default {
    components: {
      RadioToggle,
    },
    props: {
      filter: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        default: 'all',
      },
    },
    data() {
      return {
        radioToggleOptions: {
          pending_countersign: [
            { label: 'All', value: 'all' },
            { label: 'Ready', value: 'ready' }, // uncertified
            { label: 'Pending', value: 'pending' }, // uncertified_remote, certify_remote_verifying, certify_remote_verifying_remote
            { label: 'Mismatch', value: 'rejected' }, // certify_not_matched_remote, certify_not_matched
            // note, we are leaving the internal value as 'rejected' for now to minimize necessary change
          ],
        },
      }
    },
    computed: {
      selectedRadioToggleOptions() {
        return this.radioToggleOptions[this.filter] || null
      },
    },
    methods: {
      handleFilterChange(selection) {
        this.$emit('selected', selection)
      },
    },
  }

</script>
