/* global moment */
import Constants from 'lib/constants'
import Routes from 'lib/routes'
import PubSub from 'lib/pub_sub'
import Util from 'lib/util'
import ApiOperation from 'lib/api_operation'
import RepresentableField from 'models/representable_field'
import ReportsToolbarView from 'views/reports/table_toolbar_view'
import ReportLoadingView from 'views/reports/report_loading_view'
import ReportFiltersView from 'views/reports/report_filters_view'
import ReportAttributesFormView from 'views/reports/report_attributes_form_view'
import ColumnCustomizerItemView from 'views/reports/column_customizer_itemview'
import EmployeesListTableCollectionView from 'views/employees/employee_list_table_collection_view'
import masks from 'lib/util/masks'

export default Marionette.LayoutView.extend({
  template: false,

  regions: {
    columnCustomizerRegion: ".column-customizer",
    filterSelectionRegion: '#filters-region',
    resultsRegion: "#results-region",
  },

  ui: {
    renameReportButton: ".js-rename-report",
    saveReportButton: ".js-save-report",
    runReportButton: ".js-run-report",
    cancelReportButton: '.js-cancel-report',

    reportAttributesModalNode: '.report-attributes-form-modal',
  },

  bindings: function() {
    const bindings = {
      ".report-title": {
        observe: 'name',
        escape: true,
        onGet: (reportName) => {
          // Using escape: true above turns null => ""
          if (!reportName)
          {return "New Report"}

          return reportName
        },
      },
      '.js-save-report .ladda-label': {
        observe: 'id',
        onGet: function(val) {
          if (val == null)
          {return "Save..."}
          else
          {return "Save"}
        },
      },
    }

    bindings[this._uiBindings.renameReportButton] = {
      observe: 'id',
      updateView: false,
      visible: function(val) {
        // Not really sure why stickit passes an empty '' as val here (instead of null).
        return ((val != null) && (_.isNumber(val)))
      },
    }

    return bindings
  },

  events: {
    "click @ui.saveReportButton": "saveReport",
    "click @ui.runReportButton": "runReport",
    "click @ui.renameReportButton": "renameReport",
    "click @ui.cancelReportButton": "cancelReport",
  },

  modelEvents: {
    "change:id": "modelCreated",
  },

  initialize: function() {
    this.report = this.model

    // A map of Field Key => Representable Field
    this.fieldsMap = new Map()

    this._bindEnterKey()

    // Turn off this binding if modal shown
    $(document).on('shown.bs.modal.bindEnter', () => {
      $(document).off("keypress.handleEnterKey")
    })

    // Rebind enter key when modal closed
    $(document).on('hidden.bs.modal.bindEnter', () => {
      if ($('body').data('open-modal-count') == 0)
      {this._bindEnterKey()}
    })
  },

  onRender: function() {
    this.stickit()

    // Since our ReportFiltersView will immediately rendering any existing filters in the collectrion, we need to make
    // sure we have the fieldsTree available for the "Select a Field" UI component. Fetch the fields tree from the
    // API and only after that, initialize and show the ReportFiltersView.
    //
    // This also has the benefit of getting the fields tree ONCE when this layout is first rendered, rather than each
    // time a new filter row is added.
    App.Collections.RepresentableFieldCollection
      .fetchFieldsTree(['reportable', 'filterable'])
      .done((_responseText, _textStatus, xhr) => {
        const reportFiltersView = new ReportFiltersView({
          collection: this.report.get('filters'),
          fieldsTree: xhr.responseJSON,
        })
        this.filterSelectionRegion.show(reportFiltersView)
      })

    const columnCustomizerView = new ColumnCustomizerItemView({ el: this.columnCustomizerRegion.el,
      list: this.$el.find(".sortable-list") })
    columnCustomizerView.render()
    this.columnCustomizerRegion.attachView(columnCustomizerView)

    // Initialize popovers here because they are within a template
    $(document).find('a.help-popover').each(function() { $(this).popover({ html: true }) })

    this.ui.cancelReportButton.hide()
  },

  onBeforeDestroy: function() {
    if (App.Reports.listTableCollectionView) {
      App.Reports.listTableCollectionView.destroy()
      delete App.Reports.listTableCollectionView
    }

    // Unbind the keypress on destroy since we only want to use it within this view
    $(document).off("keypress.handleEnterKey")
    $(document).off('hidden.bs.modal.bindEnter')
    $(document).off('shown.bs.modal.bindEnter')
  },

  // FIXME: Don't rely on DOM data - use Backbone collection
  getSelectedColumnLabels: function () {
    return _.map(this.$el.find(".sortable-list li"), function(item) {
      return _.escape($(item).find('.item-label').text())
    })
  },

  runReport: function () {
    if (!this._validate()) {
      this.ui.runReportButton.data('ladda').stop()
      return
    }

    this.channel = PubSub.subscribeToUserChannel({ topic: 'report_query', unique: true })
    this.channel.bind('pusher:subscription_succeeded', this.channelSubscribeSuccess, this)
    this.channel.bind('pusher:subscription_error', this.channelSubscribeFailed, this)
    this.channel.bind('failed', this.onReportQueryFailed, this)
    this.channel.bind('cancelled', () => {
      bootbox.alert({
        title: "Report Cancelled",
        message: "This report was cancelled because you started a report in another window.",
        className: "modal-info",
      })
      this.resetRunReportUI()
    }, this)
    this.channel.bind('done', this.onReportQueryDone, this)

    const loadingView = new ReportLoadingView({ pubSubChannel: this.channel })
    this.resultsRegion.show(loadingView)

    this.ui.cancelReportButton.show()
  },

  // Send request to cancel a report. This cancels`` *any* report for this user, including:
  // - The current report running in this window
  // - A report running in another window
  //
  // Returns a promise when cancel request has finished
  cancelReport: function() {
    const cancelDeferred = $.Deferred()

    $.ajax({
      method: 'POST',
      url: Routes.cancel_query_reports_path,
      wbGenericFailureMsg: "Sorry, we couldn't cancel this report.",
    }).done( () => {
      this.channel.unsubscribe()
      this.resetRunReportUI()
      cancelDeferred.resolve()
    })

    return cancelDeferred.promise()
  },

  saveReport: function() {
    this._syncColumnsAndFiltersToModel()

    const validationErrorTitle = "Save Report"
    if (!this.report.isValid()) {
      Util.errorDialog(`Before we can save this report, ${this.report.validationError}`, validationErrorTitle)
      return
    } else if (!this.filterSelectionRegion.currentView.validate()) {
      Util.errorDialog(`Some of your filters are incomplete. Before we can save this report, you'll have to adjust
                        those filters (or remove them).`,
      validationErrorTitle)
      return
    }

    if (this.report.isNew()) {
      // Save along with the name and description
      // FIXME: Refactor to use events and the controller JS (this view should only be a form to collect data) instead
      // of saveAttributesOnly stuff
      const reportAttributesModal = new ReportAttributesFormView({
        // el: this.ui.reportAttributesModalNode[0],
        model: this.report,
        saveAttributesOnly: false,    // since this is a first-time save, include filters & columns in the Save operation
      })
      reportAttributesModal.render()
    } else {
      this.ui.saveReportButton.data('ladda').start()

      this.report.save(null, {
        wait: true,
        error: this.onSaveError.bind(this),
        wbGenericFailureMsg: "Sorry, we couldn't save this report.",
      })
        .done(function(_responseText, _textStatus, xhr) {
          Util.showAjaxFlashNotice(xhr)
        }.bind(this))
        .always(function() {
          this.ui.saveReportButton.data('ladda').stop()
        }.bind(this))
    }
  },

  renameReport: function() {
    const reportAttributesModal = new ReportAttributesFormView({
      // el: this.ui.reportAttributesModalNode[0],
      model: this.report,
      saveAttributesOnly: true,
    })
    reportAttributesModal.render()
  },

  onSaveError: function(_model, xhr) {
    // FIXME: Remote Validation
    if (xhr.getResponseHeader('X-Form-Errors')) {
      Util.showFlashNotice(Util.getAjaxFlashMessages(xhr).alert)
    }
  },

  // Propagete the creation event up to the sector for navigation purposes
  // In theory, the ID should only ever change when going from new (null ID) -> created.
  modelCreated: function() {
    App.vent.trigger("report:created", this.report)
  },

  _exportToExcel: function(_e, _dt, node) {
    this._export({ fileType: 'xlsx', buttonEl: node })
  },

  _exportToCSV: function(_e, _dt, node) {
    this._export({ fileType: 'csv', buttonEl: node })
  },

  // POSTs table data and redirects to download report export path on success
  // Arguments:
  //   options - (Object) with the following *required* keys:
  //               fileType - (string) e.g. 'csv', 'xlsx'
  //               buttonEl - ($element)
  _export: function (options) {
    this.exportBtn = options.buttonEl

    const exportRoute = `${Routes.export_reports_path}?key=${encodeURIComponent(this.channel.name)}&file_type=${options.fileType}`

    this._fileDownloading()
    this._setCurrentWindowLocation(exportRoute)
  },

  _setCurrentWindowLocation: function (newRoute) {
    if (newRoute.length) {
      window.location.href = newRoute
    }
  },

  // Checks for cookie set by the server when file is downloading
  // Disables export button
  _fileDownloading: function () {
    // Disable button and add loading spinner
    this.exportBtn.addClass('disabled')
    this.exportBtn.find('.fa-download').addClass('fa-circle-o-notch fa-spin')

    if (this.timerId) {
      // Clear timer if exists
      this._clearIntervalTimer()
    }

    this.timerId = window.setInterval(() => {
      if (document.cookie.match(/report_export_status/)) {
        // Regex copied straight from:
        // https://developer.mozilla.org/en-US/docs/Web/API/document/cookie#Example_2_Get_a_sample_cookie_named_test2
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)report_export_status\s*\=\s*([^;]*).*$)|^.*$/, "$1")

        switch (parseInt(cookieValue)) {
        case 404: // key not found
          Util.errorDialog(`Sorry, the results of this run are no longer available for export.<br/><br/>
                              Please re-run this report to obtain the latest data, and then try your export again.`,
          'Export')
          break

        case 424: // dependency failed
          Util.errorDialog(`Sorry, one or more fields that are part of this report are no longer available.<br/><br/>
                              If you need to save this data immediately, use the 'Copy' or 'Print' functions. Otherwise,
                              re-run this report to see which Output Data or Filters are affected.`,
          'Export')
          break

        default: // success (200) or unrecognized = noop.
          break
        }

        this._fileFinished()
      }
    }, 1000)
  },

  // Expires cookie and re-enables export button
  _fileFinished: function () {
    // Set expiration of cookie to previous date to remove it. Using formula from:
    // https://developer.mozilla.org/en-US/docs/Web/API/document/cookie#Example_4_Reset_the_previous_cookie
    document.cookie = 'report_export_status=; Expires=Thu, 01 Jan 1970 00:00:00 GMT;'

    // Enable button and remove loading spinner
    this.exportBtn.removeClass('disabled')
    this.exportBtn.find('.fa-download').removeClass('fa-circle-o-notch fa-spin')

    // Clear window.setInterval
    this._clearIntervalTimer()
  },

  // Clears setInterval timers set when
  _clearIntervalTimer: function () {
    clearInterval(this.timerId)
  },

  _initColumns: function () {
    return _.map(this.tableData.columns, function(columnKey, index) {
      const columnData = { data: columnKey, filter: columnKey }

      // Certain fields need additional formatting. If the user is authorized to view the field,
      // this formatter function will apply the last transformation prior before the value is
      // outputted onto the table.
      let formatterFn = null

      if (columnKey == 'checkbox') {
        columnData['sortable'] = false // Turn selector column sortability off
        columnData['className'] = 'row-selector'

        formatterFn = function(_value, row) {
          return App.Util.underscoreTemplateToHTML("#dt-row-select-tmpl", { id: row['id'] })
        }
      }

      if (columnKey == "a4s_employee_email") {
        formatterFn = function(value) {
          return `<a href='mailto:${value}'>${value}</a>`
        }
      } else if (columnKey == "a4s_employee_first_name" || columnKey == "a4s_employee_last_name") {
        formatterFn = function(value, row) {
          return `<a href='/staff/${row['id']}'>${value}</a>`
        }
      } else if (columnKey == "a4s_employee_ssn") {
        formatterFn = function(value) {
          return masks.ssn_last4(value)
        }
      }

      // Columns with DateField type have value in JSON format, render value and set type for sorting
      if (this._fieldType(columnKey) == 'DateField') {
        columnData['type'] = 'date'

        formatterFn = function(value) {
          if (!value) { return null }

          return value.toUpperCase().trim() == "N/A"
            ? "N/A"
            : moment(value).format(Constants.DATE_TIME_FORMAT)
        }
      }

      columnData['render'] = function(data, type, row, meta) {
        return App.Util.DataTables.renderWithAuth(data, type, row, meta, formatterFn)
      }

      // Set column name
      columnData['title'] = this.columnTitles[index]

      return columnData
    }.bind(this))
  },

  // Get the type of a field in the Field Tree
  //
  // fieldKey - String 'a4s_employee_email'
  //
  // returns: String type (eg 'DateField'), Null if not found
  _fieldType(fieldKey) {
    const field = this.fieldsMap.get(fieldKey)
    return field ? field.type : null
  },

  // Convert Representable Fields arary to a map, "field_key" => Field
  // Assigns fieldsMap property
  //
  // fields: Array, representable fields objects
  //
  // returns - undefined
  _initFieldsMap(fields) {
    fields.forEach((field => {
      this.fieldsMap.set(field.field_key, field)
    }))
  },

  _initDataTable: function (data) {
    this.employeeCollection = new App.Collections.EmployeeCollection(data)
    this.$reportTable = this.$el.find("#query-results-table")

    this.$reportTable.DataTable({
      scrollX: false,
      dom: 'lBfrtip', // Initialize table elements
      buttons: [
        { text: '<i class="fa fa-download"></i> Excel', action: this._exportToExcel.bind(this) },
        { text: '<i class="fa fa-download"></i> CSV', action: this._exportToCSV.bind(this) },
        'copy',
        'print',
      ],
      retrieve: true,
      destroy: true,
      sorting: [],
      data: data,
      paginate: false,
      pagingType: "simple_numbers",
      pageLength: 25,
      lengthChange: true,
      columns: this._initColumns(),
      rowCallback: function(row, data) {
        $(row).data('id', data.id)
        App.Util.DataTables.singleLineData(row, data) // REVIEW: Is there a way to do this on DataTable initialization?
      },
      language: {
        emptyTable: '<div class="text-center"><h4>No records to show.</h4></div>',
      },
      initComplete: () => {
        this._buildCollectionView()
        this._attachToolbar()
      },
    })
  },

  _getFiltersData: function() {
    return this.filterSelectionRegion.currentView.getFiltersDataForTransport()
  },

  // FIXME: Don't rely on DOM data - Use backbone collection
  _getSelectedColumnIds: function () {
    return _.map(this.$el.find(".sortable-list li"), function(item) {
      return $(item).attr("data-id")
    })
  },

  // Returns a Backbone RepresentableField collection from the fields selected in the column customizer
  _getSelectedColumnsCollection: function() {
    return _.map(this._getSelectedColumnIds(), function(fieldKey) {
      return new RepresentableField({ field_key: fieldKey })
    })
  },

  _syncColumnsAndFiltersToModel: function() {
    this.report.set({
      filters: this.filterSelectionRegion.currentView.collection,
      columns: this._getSelectedColumnsCollection(),
    })
  },

  _bindEnterKey: function () {
    $(document).on("keypress.handleEnterKey", function(e) {
      if (e.which === 13) {
        this.ui.runReportButton.click()
      }
    }.bind(this))
  },

  // Validates whether or not this report can be run
  // Returns - Boolean
  _validate: function() {
    const validationErrorTitle = "Run Report"
    if (!this.report.isValid()) {
      Util.errorDialog(`Before we can run this report, ${this.report.validationError}`, validationErrorTitle)
      return false
    } else if (!this.filterSelectionRegion.currentView.validate()) {
      Util.errorDialog(`Some of your filters are incomplete. Before we can run this report, you'll have to adjust
                        those filters (or remove them).`,
      validationErrorTitle)
      return false
    }

    // FIXME: Don't rely solely on UI for the collection of data (and, by extension, validation)
    const itemsInList = $('.sortable-list-item')
    if (itemsInList.length < 1) {
      bootbox.alert({
        title: "No Column Fields Selected",
        message: "Please add fields to your report by clicking 'Add a Field'.",
        className: "modal-info",
      })
      return false
    }

    return true
  },

  // PubSub callback for failed channel subscription.
  //
  // statusCode - (Number) HTTP code
  channelSubscribeFailed: function() {
    this.channel = null

    // REVIEW: Technically, this could be an auth problem on the channel (but that would be unusual).
    Util.errorDialog(`Sorry, we encountered a network error and couldn't run this report.
                      It may help to refresh this page and try again.`, 'Run Report')
  },

  // PubSub callback for successful channel subscription. At this point, we can kick off the report query operation.
  channelSubscribeSuccess: function() {
    const data = {
      columns: this._getSelectedColumnIds(),
      filters: this._getFiltersData(),
      pubsub_channel: this.channel.name,
      report: {id: this.model.id},
    }

    new ApiOperation({
      path: Routes.query_reports_path,
      data: data,
      ajaxOptions: {
        progressBar: false,
        wbGenericFailureMsg: "Sorry, we couldn't run this report.",
      },
    }).fail(xhr => {
      if (xhr.status == 429) {
        this.resetRunReportUI()
        bootbox.dialog({
          message: `This report was not started because you are already running a report.<br/><br/>
                    Would you like to cancel your other report and run this instead?`,
          title: "You are already running a report",
          className: "modal-danger",
          closeButton: false,
          buttons: {
            success: {
              label: "Yes, run this report instead",
              className: "btn-danger",
              callback: () => {
                this.cancelReport().done( () => {
                  this.ui.runReportButton.data('ladda').start()
                  this.runReport()
                })
              },
            },
            cancel: {
              label: "Nevermind",
              className: "btn-default",
            },
          },
        })
      } else {
        this.resultsRegion.currentView.setFailedState()
        this.ui.runReportButton.data('ladda').stop()
      }
    })
  },

  onReportQueryFailed: function(data) {
    if (data && data.errors) {
      const invalidFilters = data.errors.invalid_filters || []
      const invalidColumns = data.errors.invalid_columns || []

      if (invalidFilters.length > 0) {
        // Mark each filter as having an invalid field and then re-validate the entire filterSelectionRegion which
        // will automatically show the error messages.
        //
        for (const columnKey of invalidFilters) {
          const matchingFilters = this.filterSelectionRegion.currentView.collection.select((filter) => {
            const field = filter.get('field')
            return (field && field.get('field_key') == columnKey)
          })

          _.each(matchingFilters, (filter) => {
            filter.set('fieldError', true)
          })
        }

        this.filterSelectionRegion.currentView.validate()
      }

      if (invalidColumns.length > 0) {
        for (const columnKey of invalidColumns) {
          this.columnCustomizerRegion.currentView.indicateFieldError(columnKey)
        }
      }

      Util.errorDialog(`Sorry, we couldn't run this report because one or more fields are no longer available.<br/><br/>
                        Please review your Output Data and Filters, and once you've removed the problematic items,
                        run the report again.`,
      'Run Report')
    } else {
      const message = 'Sorry, we encountered an error running this report.'
      Util.errorDialog(message, 'Run Report')
    }

    this.ui.runReportButton.data('ladda').stop()
    this.ui.cancelReportButton.hide()
  },

  // Reset the UI to run another report
  resetRunReportUI: function() {
    this.resultsRegion.reset()
    this.ui.runReportButton.data('ladda').stop()
    this.ui.cancelReportButton.hide()
    this.ui.cancelReportButton.data('ladda').stop()
  },

  // Callback from channel when report query is complete. Shows the results in a DataTable.
  //
  // data - Array of Objects representing the results
  onReportQueryDone: function(data) {
    this.resultsRegion.currentView.setProgress('90', 'Downloading results...')

    new ApiOperation({
      path: Routes.fetch_results_reports_path,
      data: {
        key: data.key,
      },
      ajaxOptions: {
        progressBar: false,
        wbGenericFailureMsg: "Sorry, we couldn't retrieve this report.",
      },
    }).fail(() => {
      // FIXME: really should retry this operation if it's just a network failure, since we're sure the results data
      // IS available. if 404, then something really is screwed up.
      this.resultsRegion.currentView.setFailedState()
    }).done((_responseText, _textStatus, xhr) => {
      if (this.resultsRegion.currentView) {
        this.resultsRegion.currentView.setProgress(100, 'Loading table...')
      }

      const resultsTableView = new Marionette.ItemView({ template: "#results-table-tmpl" })
      this.resultsRegion.show(resultsTableView)

      // FIXME: All of the code below is pretty ugly and needs to be cleaned up.
      //  - Move to a ResultsTableView
      //  - this.tableData is stored for the sake of Export; move to cache results in Redis and simply store cache key
      //  - Refactor getSelectedColumnXYZ
      //  - Clean up the whole `checkbox` thing for Bulk Actions.

      const columnLabels = this.getSelectedColumnLabels()

      // Add checkbox tmpl to data array
      const rowSelectorTmpl = Util.underscoreTemplateToHTML("#row-selector-th-tmpl")
      columnLabels.unshift(rowSelectorTmpl)

      this.columnTitles = columnLabels
      this.tableData = {
        columns: this._getSelectedColumnIds(),
        filters: this._getFiltersData(),
      }
      // Prepend checkbox to columnKey array
      this.tableData.columns.unshift('checkbox')

      if (this.tableData.filters.length == 0) {
        delete this.tableData.filters
      }

      this._initFieldsMap(xhr.responseJSON.columns)
      this._initDataTable(xhr.responseJSON.records)
    }).always(() => {
      this.ui.runReportButton.data('ladda').stop()
      this.ui.cancelReportButton.hide()
    })
  },

  // Creates CollectionView from table data
  _buildCollectionView: function () {
    App.Employees.listTableCollectionView = new EmployeesListTableCollectionView({ $tableEl: this.$reportTable })
    App.Employees.listTableCollectionView.attachToDataTable(this.$reportTable, this.employeeCollection)
  },

  // Attaches toolbar to table toolbar region
  _attachToolbar: function () {
    $('<span class="dt-toolbar"/>').insertBefore( "table" )
    this.addRegion("toolbarRegion", { el: ".dt-toolbar" })

    this.toolbarRegion.show(new ReportsToolbarView({
      template: "#bulk-actions-btns-tmpl",
      collectionView: App.Employees.listTableCollectionView,
    }))

    $.runInitializers(this.$reportTable)
  },
})
