<template>
  <input
    ref="toggle"
    type="checkbox"
    data-toggle="toggle"
    :checked="value"
  />
</template>

<script>
  export default {
    name: 'toggle',

    props: {
      value: {
        type: Boolean,
        required: true,
      },

      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },

      onLabel: {
        type: String,
        required: false,
        default: 'ON',
      },

      offLabel: {
        type: String,
        required: false,
        default: 'OFF',
      },

      onClass: {
        type: String,
        required: false,
        default: 'primary',
      },

      offClass: {
        type: String,
        required: false,
        default: 'default',
      },
    },

    computed: {
      status() {
        return this.disabled ? 'disable' : 'enable'
      },
    },

    mounted() {
      $(this.$refs.toggle).bootstrapToggle({
        on: this.onLabel,
        off: this.offLabel,
        onstyle: this.onClass,
        offstyle: this.offClass,
      }).change(((event) => {
        this.$emit("toggleChanged", event.target.checked)
      }))

      $(this.$refs.toggle).bootstrapToggle(this.status)
    },
  }
</script>
