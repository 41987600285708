<template>
  <WbModal
    ref="modal"
    title="Edit Payroll Data"
    watchPageContextVariable="edit_payroll"
    :locked="$wait.is('edit_payroll')"
  >

    <form class="form-horizontal">
      <PayrollGroupFieldset
        :fields="localInternalFields"
        :errors="errors"
        :disableFields="disableInternalFields"
      >
        <template #title>
          Internal Fields <small> (hidden from staff)</small>
        </template>
      </PayrollGroupFieldset>
      <PayrollGroupFieldset
        :fields="localStaffFields"
        :errors="errors"
        :disableFields="disableStaffFields"
      >
        <template #title>
          Staff Fields <small> (completed by staff)</small>
        </template>
      </PayrollGroupFieldset>
    </form>

    <template #footer>
      <button
        class="btn btn-default"
        :disabled="$wait.is('edit_payroll')"
        @click="hide"
      >Cancel</button>
      <LaddaButton
        ref="submit"
        @click="submit"
      >Submit</LaddaButton>
    </template>
  </WbModal>
</template>

<script>
  import { mapActions } from 'vuex'
  import Util from 'lib/util'
  import LaddaButton from 'components/common/LaddaButton'
  import WbModal from 'components/common/WbModal'
  import PayrollGroupFieldset from 'components/employees/payroll/PayrollGroupFieldset'

  export default {
    name: 'update-payroll-modal',

    components: {
      LaddaButton,
      WbModal,
      PayrollGroupFieldset,
    },

    props: {
      employeeId: {
        type: Number,
        required: true,
      },

      internalFields: {
        type: Array,
        required: true,
      },

      staffFields: {
        type: Array,
        required: true,
      },

      disableStaffFields: {
        type: Boolean,
        required: false,
        default: false,
      },

      disableInternalFields: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        errors: {},
        localStaffFields: null,
        localInternalFields: null,
      }
    },

    watch: {
      payrollFields() {
        this.setLocalFields()
      },
    },

    created () {
      this.setLocalFields()
    },

    methods: {
      ...mapActions({
        submitFields: 'payroll_fields/updateEmployeePayrollFields',
      }),

      hide() {
        this.$refs.modal.hide()
      },

      setLocalFields() {
        this.localStaffFields = this.staffFields.map(field => { return {...field}})
        this.localInternalFields = this.internalFields.map(field => { return {...field}})
      },

      submit() {
        this.$wait.start('edit_payroll')
        this.errors = {}

        this.submitFields({ payroll_fields: this.getFormattedParams(), employee_id: this.employeeId })
          .then(() => {
            setTimeout(() => {
              this.hide()
            })
            Util.showFlashNotice('Payroll fields updated')
          })
          .catch(response => {
            if (response.responseJSON && response.responseJSON.errors) {
              this.errors = response.responseJSON.errors
            } else {
              Util.ajaxErrorDialog('An error occurred updating the payroll fields.', response)
            }
          })
          .finally(() => {
            this.$refs.submit.stop()
            this.$wait.end('edit_payroll')
          })
      },

      // Grab the values from the inputs and format them to send as REST params
      // Create an object of field names and their values:
      //  - {standard:payroll:pay_rate: '100', standard:payroll:direct_deposit_account_type: 'checking'}
      getFormattedParams() {
        const params = {}
        this.localStaffFields.forEach(pf => {
          params[pf.internal_id] = pf.value
        })
        this.localInternalFields.forEach(pf => {
          params[pf.internal_id] = pf.value
        })
        return params
      },
    },
  }
</script>
