<template>
  <div class="form-group superadmin_announcement_audience">
    <FormLabel
      label="Audience"
      for="superadmin_announcement_audience"
      class="col-sm-4 col-md-3 col-lg-3 control-label"
    />
    <div class="col-sm-7">
      <AccountSearch
        :value="audience"
        @input="handleSearchInput"
      />
      <input
        id="superadmin_announcement_audience"
        type="hidden"
        name="superadmin_announcement[audience]"
        :value="localValue"
      />
    </div>
  </div>
</template>
<script>
  import FormLabel from 'components/common/FormLabel'
  import AccountSearch from 'components/superadmin/accounts/AccountSearch'
  export default {
    name: 'the-account-search-root',
    components: {
      AccountSearch,
      FormLabel,
    },
    props: {
      audience: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        localValue: null,
      }
    },
    created () {
      this.handleSearchInput(this.audience)
    },
    methods: {
      handleSearchInput (value) {
        this.localValue = value.map(option => option.value)
      },
    },
  }
</script>
