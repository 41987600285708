<template>
  <div>
    <PageHeader
      title="I-9 Forms"
      :lead="headerLead"
    />
    <I9InboxFilterNav
      :filter="filter"
      class="pt-2"
      @change="handleFilterChange"
    />
    <div class="d-flex flex-column flex-md-row">
      <I9InboxExpirationFilterToggle
        class="mb-3 mr-3"
        :filter="filter"
        :expiration="expiration"
        @selected="handleExpirationFilterChange"
      />
      <I9InboxStatusFilter
        v-if="currentAccount.remoteCountersignOn"
        class="mb-3 mr-3"
        :filter="filter"
        :status="status"
        @selected="handleStatusFilterChange"
      />
      <I9InboxDocumentVerificationFilter
        v-if="currentAccount.documentVerificationEnabled"
        ref="DocumentVerificationFilter"
        class="mb-3"
        :filter="filter"
        :status="document_verification_status"
        @selected="handleDocVerificationFilterChange"
      />
    </div>
    <I9InboxEmployerInstructions
      :filter="filter"
      :is_i9rc_on="currentAccount.remoteCountersignOn"
    />
    <div class="clearfix">
      <I9InboxActionButtons
        v-if="shouldShowActionButtons"
        :isLoading="isLoading"
        :selectionCount="selectionCount"
        @signSection2="signSection2"
        @editSection2="editSection2"
        @requestNewI9="requestNewI9"
      />
      <WbSearchInput
        ref="search"
        class="pull-right"
        :label="null"
        placeholder="Search"
        :autofocus="true"
        :value="query"
        :throttle="true"
        @input="handleSearchInput"
      />
    </div>
    <Spinner
      v-if="isLoading"
      ref="loader"
    />
    <template
      v-else
    >
      <I9InboxAssignmentTable
        :assignments="assignments"
        :sort="sort"
        :shouldShowVerificationStatus="shouldShowVerificationStatus"
        @sort="handleSortUpdate"
      />
      <WbListPaginationFooter
        v-if="shouldShowPagination"
        ref="pagination"
        :previousPage="previousPage"
        :nextPage="nextPage"
        :lastPage="lastPage"
        :totalItems="totalItems"
        :indexStart="indexStart"
        :indexEnd="indexEnd"
        @pageChange="handlePageChange"
      />
    </template>
  </div>
</template>
<script>
  import I9InboxAssignmentTable from 'components/i9/employer_review/I9InboxAssignmentTable'
  import I9InboxActionButtons from 'components/i9/employer_review/I9InboxActionButtons'
  import I9InboxFilterNav from 'components/i9/employer_review/I9InboxFilterNav'
  import I9InboxEmployerInstructions from 'components/i9/employer_review/I9InboxEmployerInstructions'
  import I9InboxExpirationFilterToggle from 'components/i9/employer_review/I9InboxExpirationFilterToggle'
  import I9InboxStatusFilter from 'components/i9/employer_review/I9InboxStatusFilter'
  import I9InboxDocumentVerificationFilter from 'components/i9/employer_review/I9InboxDocumentVerificationFilter'
  import WbSearchInput from 'components/common/WbSearchInput'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import PageHeader from 'components/common/PageHeader'
  import Spinner from 'components/common/Spinner'
  import StringUtil from 'lib/util/string'
  import Util from 'lib/util'
  import { mapActions, mapGetters } from 'vuex'
  import PersistedPageState from 'lib/persisted_page_state'

  const DEFAULT_SORT_DIR = 'asc'
  const DEFAULT_SORT_FIELD = 'name'
  const DEFAULT_EXPIRATION_FILTER = 'all'
  const DEFAULT_STATUS_FILTER = 'all'
  const DEFAULT_DOCUMENT_VERIFICATION_STATUS_FILTER = 'all'
  const CERTIFY_STATUS_FILTERS = {
    all: [],
    ready: ['uncertified'],
    pending: ['uncertified', 'uncertified_remote', 'certify_remote_verifying', 'certify_remote_verifying_remote'],
    rejected: ['certify_not_matched_remote', 'certify_not_matched'],
  }

  export const FILTERS_TO_SHOW_VERIFICATION_STATUS = [
    'pending_countersign',
    'pending',
    'missing_rejected',
    'awaiting_ssn',
    'reverify',
    'countersigned',
  ]

  export default {
    name: 'the-i9-inbox-root',
    components: {
      I9InboxAssignmentTable,
      I9InboxExpirationFilterToggle,
      I9InboxActionButtons,
      I9InboxFilterNav,
      I9InboxEmployerInstructions,
      I9InboxStatusFilter,
      I9InboxDocumentVerificationFilter,
      PageHeader,
      Spinner,
      WbListPaginationFooter,
      WbSearchInput,
    },
    data () {
      return {
        headerLead: 'Choose the Form I-9s you want to view below. The I-9 must be completed within three business days of a staff member\'s first day of employment.',
        isLoading: false,
        persistedState: new PersistedPageState(),
        qsUpdate: 0,
        query: '',
      }
    },
    computed: {
      ...mapGetters({
        pageContext: 'pageContext',
        assignments: 'i9_inbox_assignments/collection',
        pagination: 'i9_inbox_assignments/pagination',
        currentAccount: 'account/current',
      }),

      filter: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('filter') || 'pending_countersign'
        },
        set (newValue) {
          this.persistedState.savePageState('filter', newValue)
          this.qsUpdate++
        },
      },

      expiration: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('expiration') || 'all'
        },
        set (newValue) {
          this.persistedState.savePageState('expiration', newValue)
          this.qsUpdate++
        },
      },

      document_verification_status: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('document_verification_status') || DEFAULT_DOCUMENT_VERIFICATION_STATUS_FILTER
        },
        set (newValue) {
          this.persistedState.savePageState('document_verification_status', newValue)
          this.qsUpdate++
        },
      },

      shouldShowVerificationStatus () {
        return this.currentAccount.documentVerificationEnabled &&
          FILTERS_TO_SHOW_VERIFICATION_STATUS.includes(this.filter)
      },

      status: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('status') || 'all'
        },
        set (newValue) {
          this.persistedState.savePageState('status', newValue)
          this.qsUpdate++
        },
      },

      page: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('page') || 1
        },
        set (newValue) {
          this.persistedState.savePageState('page', newValue)
          this.qsUpdate++
        },
      },

      sortDirection: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('sort_dir') || DEFAULT_SORT_DIR
        },
        set (newValue) {
          this.persistedState.savePageState('sort_dir', newValue)
          this.qsUpdate++
        },
      },

      sortField: {
        get () {
          this.qsUpdate
          return this.persistedState.fetchPageState('sort') || DEFAULT_SORT_FIELD
        },
        set (newValue) {
          this.persistedState.savePageState('sort', newValue)
          this.qsUpdate++
        },
      },

      sort () {
        return {
          direction: this.sortDirection,
          field: this.sortField,
        }
      },

      shouldShowPagination () {
        return this.pagination !== null && this.lastPage > 1
      },
      indexStart () {
        let val = 0
        if (this.pagination) {
          val = (this.pagination.page - 1) * this.pagination.per_page + 1
        }
        return val
      },
      isSection2Disabled () {
        return this.selectionCount === 0 || this.isLoading
      },
      indexEnd () {
        let val = 0
        if (this.pagination) {
          val = Math.min(this.indexStart + this.pagination.per_page - 1, this.totalItems)
        }
        return val
      },

      previousPage () {
        return this.pagination?.previous_page
      },
      nextPage () {
        return this.pagination?.next_page
      },
      lastPage () {
        return this.pagination?.total_pages
      },
      totalItems () {
        return this.pagination?.total_items
      },
      submissionsReadyIds () {
        return this.assignments?.filter(el => (el.submission.countersignable && el.submission.certify_status == 'uncertified')).map(el => el.current_submission_id)
      },
      selectedIds () {
        return this.assignments?.filter(el => el.selected).map(el => el.current_submission_id)
      },
      selectionCount () {
        return this.selectedIds.length
      },
      signSection2ButtonText () {
        const SIGN_TEXT = 'Sign Section 2'
        return this.section2Text(SIGN_TEXT)
      },
      editSection2ButtonText () {
        const EDIT_TEXT = 'Edit Section 2'
        return this.section2Text(EDIT_TEXT)
      },
      isPendingCountersignActive() {
        return this.filter == 'pending_countersign'
      },
      shouldShowActionButtons() {
        return this.filter !== 'awaiting_ssn'
      },
    },
    watch: {
      query (newValue) {
        if (newValue.length >= 3 || newValue.length == 0 ) {
          this.requestAssignments()
        }
      },
    },
    created () {
      this.requestAssignments()
    },
    methods: {
      ...mapActions({
        fetchAssignments: 'i9_inbox_assignments/fetchAssignments',
        unselectAllAssignments: 'i9_inbox_assignments/unselectAllAssignments',
        bulkReset: 'i9_inbox_assignments/bulkReset',
      }),

      handleFilterChange (value) {
        this.filter = value
        this.page = 1
        this.sortDirection = DEFAULT_SORT_DIR
        this.sortField = DEFAULT_SORT_FIELD
        this.expiration = DEFAULT_EXPIRATION_FILTER
        this.status = DEFAULT_STATUS_FILTER
        this.document_verification_status = DEFAULT_DOCUMENT_VERIFICATION_STATUS_FILTER
        this.requestAssignments()
      },

      handlePageChange (page) {
        this.page = page
        this.requestAssignments()
      },

      handleSearchInput (value) {
        this.page = 1
        this.query = value
      },

      handleExpirationFilterChange (value) {
        this.expiration = value
        this.page = 1
        this.sortDirection = DEFAULT_SORT_DIR
        this.sortField = DEFAULT_SORT_FIELD
        this.requestAssignments()
      },

      handleStatusFilterChange (value) {
        this.status = value
        this.page = 1
        this.sortDirection = DEFAULT_SORT_DIR
        this.sortField = DEFAULT_SORT_FIELD
        this.requestAssignments()
      },

      handleDocVerificationFilterChange (value) {
        this.document_verification_status = value
        this.page = 1
        this.sortDirection = DEFAULT_SORT_DIR
        this.sortField = DEFAULT_SORT_FIELD
        this.requestAssignments()
      },

      handleSortUpdate (field) {
        this.page = 1
        if (field === this.sort.field) {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortField = field
          this.sortDirection = DEFAULT_SORT_DIR
        }
        this.requestAssignments()
      },

      async requestAssignments () {
        this.isLoading = true

        try {
          await this.fetchAssignments({
            filter: this.filter,
            expiration: this.expiration,
            status: CERTIFY_STATUS_FILTERS[this.status],
            document_verification_status: this.document_verification_status,
            sort: this.sort,
            query: this.query,
            page: this.page,
          })
          this.unselectAllAssignments()
        } catch (xhr) {
          Util.genericAjaxError('An error occurred fetching assignments.', xhr)
        } finally {
          this.isLoading = false
        }
      },

      section2Text(inputText) {
        let text = ''
        if (this.selectionCount === 0) {
          text = (inputText)
        } else {
          const forms = StringUtil.pluralizeWord(this.selectionCount, 'form')
          text = (`${inputText} for (${this.selectionCount}) selected ${forms}`)
        }
        return text
      },

      signSection2 () {
        window.location.href = `${this.$routes.onboarding_i9_review_path}?${this.buildUrlSection2()}`
      },

      editSection2 () {
        window.location.href = `${this.$routes.onboarding_i9_review_path}?${this.buildUrlSection2(true)}`
      },

      async requestNewI9() {
        try {
          await this.bulkReset()
          await this.requestAssignments()
        } finally {
          Util.showFlashNotice("New I9s requested")
        }
      },

      buildUrlSection2 (isEdit=false) {
        let ids = []
        if (this.selectionCount > 0) {
          ids = [...this.selectedIds]
        } else {
          ids = [...this.submissionsReadyIds]
        }
        let urlParams = ids.map(function(i){return `ids[]=${i}`}).join('&')

        if (isEdit) {
          urlParams += '&edit=true'
        }
        return urlParams
      },
    },
  }
</script>
