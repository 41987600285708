<template>
  <div class="row">
    <label class="control-label col-sm-5">
      {{ actionName }}
    </label>
    <div class="col-sm-7">
      <input
        ref="toggle"
        v-model="permission.grant"
        type="checkbox"
        data-toggle="toggle"
      />
    </div>
  </div>
</template>

<script>
  import PermissionsToggle from 'components/permissions/common/PermissionsToggle'

  export default {
    name: 'permissions-toggle-wide',
    extends: PermissionsToggle,
  }
</script>
