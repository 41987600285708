import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'

export function fetchSubmissionAttachments (id) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'GET',
      type: 'JSON',
      url: getRoute('attachments_i9_submission_path', { id }),
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function updateBusinessData(id, businessData) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('update_business_data_i9_submission_path', { id }),
      data: { business: businessData },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}
