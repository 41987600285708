// Filterable list of an Employee's activeAssignments

<template>
  <div class="assignment-root">
    <DocumentAssignmentListContainer />
    <HistoricalSubmissionsContainer />
    <BulkResetAssignmentsContainer />
    <SubmissionAuditLogContainer />

    <SlideoutPanel
      watchPageContextVariable="show_upload_submission"
      title="Upload Submissions"
    >
      <UploadSubmissionSelect />
    </SlideoutPanel>
  </div>
</template>

<script>
  import UploadSubmissionSelect from './UploadSubmissionSelect'
  import HistoricalSubmissionsContainer from './HistoricalSubmissionsContainer'
  import SubmissionAuditLogContainer from 'components/document_submissions/SubmissionAuditLogContainer'
  import BulkResetAssignmentsContainer from 'components/employees/bulk_reset_assignments/BulkResetAssignmentsContainer'
  import SlideoutPanel from 'components/common/SlideoutPanel'
  import DocumentAssignmentListContainer from './DocumentAssignmentListContainer'

  import { mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'

  export default {
    name: 'the-employees-document-assignments-index-root',
    components: {
      BulkResetAssignmentsContainer,
      SlideoutPanel,
      HistoricalSubmissionsContainer,
      SubmissionAuditLogContainer,
      UploadSubmissionSelect,
      DocumentAssignmentListContainer,
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
