<template>
  <div class="alert alert-success">
    <FormFieldSelect
      ref="reason"
      label="Duplicate Continue Reason"
      class="mt-3"
      fieldWidth="long-field"
      name="duplicate_continue_reason_code"
      placeholder="Please select a reason..."
      :errors="errors"
      :options="options"
      :value="value"
      @input="handleChange"
    />
    <FormFieldTextArea
      v-if="isOtherReasonSelected"
      ref="otherReason"
      v-model="otherReason"
      name="duplicate_continue_reason_other"
      placeholder="Explanation"
      :errors="errors"
    />
    <div class="mt-3">
      <WbButton
        ref="saveBtn"
        context="success"
        :isLoading="isLoading"
        @click="handleSave"
      >
        Continue Case
      </WbButton>
    </div>
  </div>
</template>
<script>
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import { mapActions } from 'vuex'

  const OTHER_REASON = 'OTHER'
  const REASON_ERROR = 'Please supply a duplicate reason'
  const OTHER_REASON_ERROR = 'Please supply a full explanation for the duplicate'

  export default {
    components: {
      FormFieldTextArea,
      FormFieldSelect,
      WbButton,
    },
    props: {
      everifyCase: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
        localValue: null,
        otherReason: this.everifyCase.duplicate_continue_reason_other,
        reasons: this.everifyCase.duplicate_continue_reasons,
        value: this.everifyCase.duplicate_continue_reason_code,
        errors: {},
      }
    },
    computed: {
      formOtherReason () {
        return this.localValue === OTHER_REASON ? this.otherReason : null
      },
      isOtherReasonSelected () {
        return this.localValue === OTHER_REASON
      },
      isValidOtherReason () {
        return (this.otherReason && !(/^\s*$/.test(this.otherReason)))
      },
      options () {
        return this.getMappedOptions(this.reasons)
      },
    },
    watch: {
      value: {
        immediate: true,
        handler () {
          this.localValue = this.value
        },
      },
    },
    methods: {
      ...mapActions({
        patchDuplicateReason: 'document_submissions/patchDuplicateReason',
      }),
      getMappedOptions(options = []) {
        return options.map(({ name, code }) => ({
          label: name,
          value: code,
        }))
      },
      handleChange (value) {
        this.errors = {}
        this.otherReason = null
        this.localValue = value
      },
      handleSave () {
        if (this.validateForm()) {
          this.patchData()
        }
      },
      async patchData () {
        this.isLoading = true

        try {
          await this.patchDuplicateReason({
            documentSubmissionId: this.everifyCase.submission_id,
            reason: this.localValue,
            otherExplanation: this.formOtherReason,
          })
          this.$emit('update')
        } catch (xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError('An error occurred while trying to submit the duplicate continue reason', xhr)
          }
        } finally {
          this.isLoading = false
        }

      },
      validateForm () {
        this.errors = {}

        if (!this.localValue) {
          this.errors.duplicate_continue_reason_code = [REASON_ERROR]
        }

        if (this.isOtherReasonSelected && !this.isValidOtherReason){
          this.errors.duplicate_continue_reason_other = [OTHER_REASON_ERROR]
        }

        return Object.keys(this.errors).length === 0
      },
    },
  }
</script>
