<template>
  <div class="policy-generator">
    <WbWizard
      :loading="isLoading"
      :nextLabel="nextLabel"
      @previous="handlePrevStep"
      @next="nextStep"
    >
      <template #steps>
        <WbOrderedList>
          <WbOrderedListItem
            v-for="(step, index) in stepList"
            :key="index"
            :active="step.active"
          >
            {{ step.label }}
          </WbOrderedListItem>
        </WbOrderedList>
      </template>
      <template #body>
        <component
          :is="currentStepComponentName"
          ref="currentStep"
          :nav-direction="navDirection"
          :policy="localPolicy"
          :firstName="name"
          v-on:skip="skipStep"
        />
      </template>
    </WbWizard>
  </div>
</template>

<script>

  import { mapActions } from 'vuex'
  import BaseWizard from "components/common/BaseWizard"
  import StepProfileFields from "components/permissions/steps/StepProfileFields"
  import StepProfileFieldsCustomize from "components/permissions/steps/StepProfileFieldsCustomize"
  import StepDocuments from "components/permissions/steps/StepDocuments"
  import StepDocumentsCustomize from "components/permissions/steps/StepDocumentsCustomize"
  import StepEmployeeManagement from "components/permissions/steps/StepEmployeeManagement"
  import StepOtherPeople from "components/permissions/steps/StepOtherPeople"
  import StepOtherActions from "components/permissions/steps/StepOtherActions"
  import StepSummary from "components/permissions/steps/StepSummary"

  import Spinner from "components/common/Spinner"
  import Util from "lib/util"
  import WbCard from "components/common/WbCard"
  import WbOrderedList from "components/common/WbOrderedList"
  import WbOrderedListItem from 'components/common/WbOrderedListItem'
  import WbWizard from 'components/common/WbWizard'

  export default {
    components: {
      StepProfileFields,
      StepProfileFieldsCustomize,
      StepDocuments,
      StepDocumentsCustomize,
      StepEmployeeManagement,
      StepOtherPeople,
      StepOtherActions,
      StepSummary,
      Spinner,
      WbCard,
      WbOrderedList,
      WbOrderedListItem,
      WbWizard,
    },

    mixins: [BaseWizard],

    props: {
      policy: {
        type: Object,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        localPolicy: this.policy,
        steps: [
          {component: "StepProfileFields", label: "Profile Fields"},
          {component: 'StepProfileFieldsCustomize', label: "Profile Fields"},
          {component: "StepDocuments", label: "Forms" },
          {component: "StepDocumentsCustomize", label: "Forms" },
          {component: "StepEmployeeManagement", label: "Employee Management"},
          {component: "StepOtherPeople", label: "Other People" },
          {component: "StepOtherActions", label: "Other Actions" },
          {component: "StepSummary", label: "Summary" },
        ],
        currentStepIdx: 0,
        navDirection: null,
        history: null,
        isLoading: false,
      }
    },

    computed: {
      nextlabel() {
        return "Next"
      },
    },

    created() {
      this.loadPermissionsResources()
    },

    methods: {
      ...mapActions({
        fetchPermissionPolicies: 'permissions/fetchPermissionPolicies',
      }),

      done() {
        this.$emit("done", this.localPolicy)
      },

      async loadPermissionsResources() {
        this.isLoading = true

        try {
          await this.fetchPermissionPolicies()
        } catch (xhr) {
          Util.genericAjaxError(this.$locale.t("errors.ajax.generic"), xhr)
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>
