<template>
  <SlideoutPanel watchPageContextVariable="show_audit_log_submission_id">
    <Spinner
      v-if="isLoading"
      ref="loader"
    />
    <template
      v-if="!isLoading"
      #title
    >
      <h3
        v-if="submissionId"
        class="modal-title"
      >
        Audit Events
        <br />
        <small>
          {{ documentName }}
          <br />
          {{ employeeName }}
        </small>
      </h3>
    </template>
    <template v-if="!isLoading">
      <div
        v-if="!hasSubmission"
        class="alert alert-warning"
      >
        No current submission
      </div>
      <div class="submission-audit-log-list">
        <ul class="list-group wb-list">
          <SubmissionAuditLogItem
            v-for="event in auditLog"
            :key="event.id"
            :event="event"
          />
        </ul>
      </div>
    </template>
  </SlideoutPanel>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import SlideoutPanel from 'components/common/SlideoutPanel'
  import SubmissionAuditLogItem from 'components/document_submissions/SubmissionAuditLogItem'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'

  export default {
    name: 'submission-audit-log-container',

    components: {
      SlideoutPanel,
      SubmissionAuditLogItem,
      Spinner,
    },
    data () {
      return {
        isLoading: false,
      }
    },
    computed: {
      submissionId() {
        return this.pageContext.show_audit_log_submission_id
      },

      submission() {
        return this.getSubmission(this.submissionId)
      },

      documentName() {
        return this.submission.document_name
      },

      hasSubmission() {
        return (this.submissionId != null) && this.auditLog.length
      },

      auditLog() {
        if (!this.submission) { return [] }
        // Display most recent events at the top of the list
        return Array.from(this.submission.audit_logs)
          .sort((a,b) => new Date(b.server_timestamp) - new Date(a.server_timestamp))
      },

      employeeId() {
        return this.submission.employee_id
      },

      employee() {
        return this.getEmployee(this.employeeId)
      },

      // eslint-disable-next-line vue/return-in-computed-property
      employeeName() {
        if (this.employee) {
          return this.$employee.fullName(this.employee)
        } else { // TODO: Move this logic to created, handle loading state of async method
          this.fetchEmployee()
        }
      },

      ...mapGetters({
        getSubmission: 'document_submissions/get',
        getEmployee: 'employees/get',
      }),

      ...mapState([
        'pageContext',
      ]),
    },
    watch: {
      submissionId(newVal) {
        if (newVal) {
          this.getSubmissionAsync(newVal)
        }
      },
    },
    methods: {
      fetchEmployee() {
        this.employeesBulkShow({
          employeeIds: [this.employeeId],
        }).catch(() => {
          // Error! Close the slideout
          this.deletePageContextKey('show_audit_log_submission_id')
        })
      },

      getSubmissionAsync (id) {
        // Fetch the current submission if necessary and not already stored
        if (!this.submission) {
          this.isLoading = true

          this.fetchSubmission(id)
            .catch((xhr) => {
              // Error!
              Util.genericAjaxError("An error occurred promoting.", xhr)
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      },

      ...mapActions({
        employeesBulkShow: 'employees/bulkShow',
        fetchSubmission: 'document_submissions/fetchSubmission',
      }),
    },
  }
</script>
