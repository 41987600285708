<!-- This component is only to be used in the New Employee form wizard -->

<template>
  <div>
    <EmploymentDates
      v-model="employment"
      orientation="horizontal"
      :errors="this.fieldErrors"
    />
    <hr />
    <ScheduleOnboarding
      v-model="onboarding"
      label="Schedule Notifications"
      orientation="horizontal"
    />
    <div
      v-if="shouldShowRI9NotificationMethodsToggle"
      ref="rI9NotificationMethod"
      class="form-group"
    >
      <div class="control-label col-sm-4 col-md-3">
        Remote I-9 Notification Method:
      </div>
      <div class="col-sm-8 col-md-9">
        <EmployeeNotificationMethodsToggle
          v-model="ri9NotificationMethods"
          :name="`${formPrefix}[ri9_notification_methods]`"
          type="ri9"
        />
      </div>
    </div>
    <div
      v-if="shouldShowOnboardingNotificationMethodsToggle"
      ref="onboardingNotificationMethod"
      class="form-group"
    >
      <div class="control-label col-sm-4 col-md-3">
        Onboarding Notification Method:
      </div>
      <div class="col-sm-8 col-md-9">
        <EmployeeNotificationMethodsToggle
          v-model="onboardingNotificationMethods"
          :name="`${formPrefix}[onboarding_notification_methods]`"
          type="onboarding"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from 'lib/constants'
  import EmployeeNotificationMethodsToggle from 'components/employees/notification/EmployeeNotificationMethodsToggle'
  import EmploymentDates from 'components/employments/EmploymentDates'
  import moment from 'moment'
  import ScheduleOnboarding from 'components/employees/ScheduleOnboarding'
  import { mapGetters } from 'vuex'

  export default {
    name: 'new-employee-employment-form-fields',

    components: {
      EmployeeNotificationMethodsToggle,
      EmploymentDates,
      ScheduleOnboarding,
    },

    props: {
      collectionViewName: {
        type: String,
        required: false,
      },

      formPrefix: {
        type: String,
        default: 'employee',
      },

      updateCallback: {
        type: Function,
        required: true,
      },

      value: {
        type: Object,
        required: true,
        validator: val => {
          return _.every(Object.keys(val), key => {
            return ['hire_date', 'start_date', 'end_date', 'onboarding_start_date'].includes(key)
          })
        },
      },
    },

    data() {
      return {
        // Default to Don't Onboard to play it safe (this has always been the default)
        onboarding: {
          selection: 'never',
          date: null,
        },

        employment: {
          hire_date: null,
          start_date: null,
          end_date: null,
        },

        fieldErrors: {},

        ri9NotificationMethods: [],
        onboardingNotificationMethods: [],
      }
    },

    computed: {
      requiresStartDate() {
        return this.currentAccount.startDateRequired
      },

      shouldShowOnboardingNotificationMethodsToggle() {
        return this.currentAccount.onboardingSmsEnabled
      },

      shouldShowRI9NotificationMethodsToggle() {
        return this.currentAccount.ri9SmsEnabled
      },

      ...mapGetters({
        currentAccount: 'account/current',
      }),
    },

    watch: {
      employment: {
        handler(val) {
          this.updateDates()

          // The new employee wizard is a native rails view form and therefore we cannot grab validation
          // errors from a JSON POST response, the actual request response is returned in HTML, so instead we handle some basic validation client-side.
          this.validateStartDatePresence(val.start_date)
          this.validateStartDateBeforeEndDate(val)
        },
        deep: true, // detect all object changes
      },

      onboarding: {
        handler() { this.updateDates() },
        deep: true,
      },
    },

    created() {
      this.employment.hire_date = this.formatDate(this.value.hire_date)
      this.employment.start_date = this.formatDate(this.value.start_date)
      this.employment.end_date = this.formatDate(this.value.end_date)

      if (this.value.onboarding_start_date) {
        this.onboarding.selection = moment().format() >= this.value.onboarding_start_date ? 'now' : 'schedule'
        this.onboarding.date = this.formatDate(this.value.onboarding_start_date)
      }

      this.validateStartDatePresence(this.value.start_date)
    },

    methods: {
      updateDates() {
        const dates = { ...this.employment, onboarding_start_date: this.onboarding.date }
        this.updateCallback(dates)

        if (this.collectionViewName) {
          this.$store.commit(`${this.collectionViewName}/SET_FILTER_VALUE`, {
            filter_key: 'employment_dates',
            value: dates,
          })
        }
      },

      formatDate(str) {
        return str ? moment(str).format(Constants.DATE_FORMAT) : null
      },

      validateStartDatePresence(value) {
        if (this.requiresStartDate) {
          if (value?.length) {
            this.fieldErrors = {}
          } else {
            this.fieldErrors = { start_date: [this.$locale.t('employments.errors.start_date.required')] }
          }
        }
      },

      validateStartDateBeforeEndDate(val) {
        // Must have both start and end dates for this validation
        if (val.start_date && val.start_date) {
          const dateFormat = Constants.DATE_FORMAT
          const startDate  = moment(val.start_date, dateFormat)
          const endDate    = moment(val.end_date, dateFormat)

          // Start date must be before or on end date, same validation that happens in `WorkbrightApi::EmploymentForm.rb`
          // REVIEW: there is Moment warning that happens because of date format, '12/12/12'. I believe we should be using ISO timestamps instead,
          // but couldn't get that to work while still displaying an easily readable format in the actual field.
          if (startDate.isAfter(endDate)) {
            this.fieldErrors = { start_date: [this.$locale.t('employments.errors.start_date.invalid')] }
          } else {
            this.fieldErrors = {}
          }
        }
      },
    },
  }
</script>
