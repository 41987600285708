// Reactive Slideout Panel (slides in from right of the screen). Built off of a Bootstrap modal.
//
// Refer to BaseModal documentation for usage
<template>
  <div v-if="shouldShow">
    <div
      ref="panel"
      class="slideout-panel-modal modal right fade"
      :class="sizeClass"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="panel panel-snapshot">
            <div class="panel-heading">
              <!-- Close with the cancel() method, not data-dismiss, allowing us to check the locked setting -->
              <button
                class="close"
                aria-label="Close"
                type="button"
                @click="cancel"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h3
                v-if="title"
                class="modal-title"
              >
                {{ title }}
              </h3>
              <slot
                v-if="!title"
                name='title'
              />
            </div>
            <div class="panel-body">
              <div class="slideout-content-region">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseModal from 'components/mixins/BaseModal'
  import BaseSlideout from 'components/mixins/BaseSlideout'

  export default {
    name: 'slideout-panel',
    mixins: [BaseModal, BaseSlideout],
  }
</script>
