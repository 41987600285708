<template>
  <div>
    <div class="sso-header">
      <h2>
        Single Sign-On (SSO)
      </h2>
      <p>
        Single Sign On (SSO) allows you to log into many websites, such as WorkBright, using one set of login credentials.
      </p>
      <hr />
    </div>
    <div
      v-for="(ssoConfig, index) in pageContext.ssoConfigs"
      :key="index"
      class="sso-config-forms"
    >
      <SsoSamlConfigFormContainer
        :ssoConfig="ssoConfig"
        :ssoProviderNames="pageContext.ssoProviderNames"
      />
    </div>
    <SsoServiceProviderSettingsContainer :ssoPaths="pageContext.ssoPaths" />
    <SsoScimProvisioningContainer
      :scimPath="pageContext.ssoPaths.scim"
      :scimToken="pageContext.scimToken"
    />
    <div class="sso-footer pt-3">
      <i>
        Currently, we only support SAML authentication but we're always interested in supporting our customers' needs. If your company uses a different auth type, please ask us about it.
      </i>
    </div>
  </div>
</template>

<script>
  import SsoServiceProviderSettingsContainer from 'components/sso/SsoServiceProviderSettingsContainer'
  import SsoScimProvisioningContainer from 'components/sso/SsoScimProvisioningContainer'
  import SsoSamlConfigFormContainer from 'components/sso/SsoSamlConfigFormContainer'
  import { mapState } from 'vuex'

  export default {
    name: 'the-sso-accounts-index-root',

    components: {
      SsoServiceProviderSettingsContainer,
      SsoScimProvisioningContainer,
      SsoSamlConfigFormContainer,
    },

    computed: {
      ...mapState([
        'pageContext',
      ]),
    },
  }
</script>
