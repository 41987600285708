<template>
  <div
    class="image-upload"
    :class="{'has-error' : error}"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <FormLabel
      v-if="label"
      ref="label"
      for="file"
      :label="label"
      :required="required"
    />
    <Spinner
      v-if="isLoadingExistingImage"
      ref="spinner"
    />
    <template v-else>
      <input
        ref="file"
        type="file"
        name="file"
        class="d-none"
        :accept="acceptedFileTypes"
        @change="handleFileChange"
      />
      <template v-if="!isUploading">
        <template v-if="hasFile">
          <template v-if="fileIsImage">
            <Toolbar
              v-if="!!cropper"
              ref="fileEditing"
            >
              <div>
                <WbButton
                  ref="crop"
                  context="default"
                  size="sm"
                  tooltip="Crop"
                  tooltipPosition="top"
                  @click="startCrop"
                >
                  <WbIcon
                    type="crop"
                    class="mr-0"
                  />
                </WbButton>
                <WbButton
                  ref="rotateClockwise"
                  context="default"
                  size="sm"
                  tooltip="Rotate Clockwise"
                  tooltipPosition="top"
                  @click="rotate(1)"
                >
                  <WbIcon
                    type="repeat"
                    class="mr-0"
                  />
                </WbButton>
                <WbButton
                  ref="rotateCounterclockwise"
                  context="default"
                  size="sm"
                  tooltip="Rotate Counterclockwise"
                  tooltipPosition="top"
                  @click="rotate(-1)"
                >
                  <WbIcon
                    type="undo"
                    class="mr-0"
                  />
                </WbButton>
              </div>
              <div
                v-if="isCropping"
                ref="cropControls"
              >
                <WbButton
                  ref="applyCrop"
                  size="sm"
                  context="success"
                  tooltip="Apply Crop"
                  tooltipPosition="top"
                  @click="applyCrop"
                >
                  <WbIcon
                    type="check"
                    class="mr-0"
                  />
                </WbButton>
                <WbButton
                  ref="cancelCrop"
                  size="sm"
                  context="danger"
                  tooltip="Cancel Crop"
                  tooltipPosition="top"
                  @click="cancelCrop"
                >
                  <WbIcon
                    type="close"
                    class="mr-0"
                  />
                </WbButton>
              </div>
            </Toolbar>
            <img
              ref="image"
              class="img-responsive"
              :src="fileUrl"
            />
          </template>
          <div
            v-else
            class="thumbnail"
          >
            <img :src="thumbnail" />
          </div>
          <div class="mt-3">
            <WbButton
              ref="removeFile"
              tooltip="Remove"
              context="danger"
              size="sm"
              @click="handleRemoveFile"
            >
              <WbIcon
                class="mr-0"
                type="close"
              />
            </WbButton>
          </div>
        </template>
        <div
          v-else
          class="image-upload-instructions"
        >
          <div
            v-if="isDragging"
            ref="dragIndicator"
            class="drag-indicator"
          >
            Release to drop file here.
          </div>
          <WebcamImageCapture
            v-else-if="isWebcamMode"
            ref="webcam"
            @cancel="handleWebcamCancel"
            @photoTaken="handleWebcamImage"
          />
          <div
            v-else
            ref="fileDropper"
            class="text-center"
          >
            Drop file here or
            <u
              ref="fileOpener"
              class="underline"
              role="button"
              @click="handleOpenFile"
            >
              click here
            </u>
            to upload.
            <div
              v-if="takePhotoOnDesktopEnabled"
              class="mt-3"
            >
              <WbButton
                ref="cameraSelect"
                context="default"
                size="sm"
                @click="handleCameraSelect"
              >
                Capture a Photo
                <WbIcon
                  class="pl-1"
                  type="camera"
                />
              </WbButton>
            </div>
          </div>
        </div>
        <div
          v-if="help"
          ref="help"
          class="help-block"
          v-html="help"
        />
        <div
          v-if="error"
          class="help-block"
          v-html="error"
        />
      </template>
    </template>
    <div ref="dropzone">
      <div
        v-if="isUploading"
        class="uploading-instructions text-center"
      >
        <div>{{ uploadInstructions }}</div>
        <div class="dz-progress progress mt-2">
          <div
            class="progress-bar progress-bar-success"
            role="progressbar"
            :style="{width: uploadProgress + '%'}"
          >
            <span class="progress-msg" />
          </div>
        </div>
        <WbButton
          context="warning"
          @click="cancelUpload"
        >
          <WbIcon
            icon-set="fa"
            type="ban"
          />
          Cancel
        </WbButton>
      </div>
    </div>
  </div>
</template>

<script>
  import Cropper from 'cropperjs'
  import FormLabel from 'components/common/FormLabel'
  import Toolbar from 'components/common/Toolbar'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import WebcamImageCapture from 'components/forms/WebcamImageCapture'
  import Spinner from 'components/common/Spinner'
  import AWSRequestXHRDecorator from 'decorators/aws_request_xhr_decorator'
  import ErrorService from 'lib/error_service'
  import DropzoneUploadService from 'services/dropzone_upload_service'
  import Util from 'lib/util'
  import BrowserSupportMixin from "components/i9_remote_countersign/common/BrowserSupportMixin"
  import { getRoute } from 'lib/routes'
  import { isHeicFile, heicToJpg } from 'services/imageConverterService'

  const ACCEPTABLE_FILE_TYPES = ['.pdf','.jpg','.jpeg','.png', '.heic', '.heif']
  const IMG_MIME_TYPES = ['image/jpeg', 'image/png', 'image/heic', 'image/heif']
  const THUMBNAILS = [
    {
      url: getRoute('Images.pdf'),
      matchString: /pdf/,
      extensions: ['pdf'],
    },
  ]

  export default {
    components: {
      FormLabel,
      Toolbar,
      WbButton,
      WbIcon,
      WebcamImageCapture,
      Spinner,
    },
    mixins: [BrowserSupportMixin],
    props: {
      help: {
        type: String,
      },
      label: {
        type: String,
      },
      required: {
        type: Boolean,
      },
      uploadServiceClass: {
        type: Function,
        default: DropzoneUploadService,
      },
      error: {
        type: String,
      },
      existingFileUrl: {
        type: String,
        required: false,
      },
      accountTakePhotoOnDesktopEnabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'update',
      'remote',
      'success',
    ],
    data () {
      return {
        uploadService: null,
        uploadInstructions: null,
        uploadProgress: 0,
        uploadOptions: null,
        cropper: null,
        file: null,
        fileUrl: this.existingFileUrl,
        fileKey: null,
        fileContentType: null,
        isWebcamMode: false,
        isCropping: false,
        isDragging: false,
        isUploading: false,
        isLoadingExistingImage: false,
      }
    },
    computed: {
      takePhotoOnDesktopEnabled () {
        return this.accountTakePhotoOnDesktopEnabled && !this.browserSupport.isMobile
      },
      acceptedFileTypes () {
        return ACCEPTABLE_FILE_TYPES.join(',')
      },
      fileExtension () {
        return this.file.name.split('.').pop()
      },
      fileIsImage () {
        if (!!this.file && !!this.fileUrl) {
          return IMG_MIME_TYPES.includes(this.file.type)
        }
        return false
      },
      hasFile () {
        return !!this.file && !!this.fileUrl
      },
      thumbnail () {
        let thumbnailUrl
        for (const thumbnail of THUMBNAILS){
          if (this.file.type.match(thumbnail.matchString) || thumbnail.extensions.includes(this.fileExtension)){
            thumbnailUrl = thumbnail.url
          }
        }
        return thumbnailUrl
      },
    },
    watch: {
      fileUrl () {
        this.$emit('update', this.fileUrl)
      },
    },
    mounted () {
      this.initializeUploadService()
    },
    unmounted () {
      this.revokeFileUrl()
    },
    created() {
      this.loadExistingImage(this.fileUrl)
    },
    methods: {
      applyCrop () {
        this.uploadCanvasBlob()
        this.isCropping = false
      },
      cancelCrop () {
        this.cropper?.clear()
        this.isCropping = false
      },
      generateUrl (file) {
        if (file) {
          this.revokeFileUrl()
          return URL.createObjectURL(file)
        }
      },
      async generateBlob (fileUrl) {
        const res = await fetch(fileUrl, { method: 'GET' })
        const blob = await res.blob()

        return new Blob([blob], { type: "image/png" })
      },
      handleDragOver () {
        if (this.hasFile) { return false }

        this.isDragging = true
      },
      handleDragLeave () {
        if (this.hasFile) { return false }

        this.isDragging = false
      },
      handleDrop (event) {
        if (this.hasFile) { return false }

        this.$refs.file.files = event.dataTransfer.files
        this.handleFileChange()
        this.isDragging = false
      },
      async handleFileChange () {
        let file = this.$refs.file.files[0]
        this.$refs.file.value = ''

        if (await isHeicFile(file)) {
          this.isLoadingExistingImage = true
          try {
            file = await heicToJpg(file)
          } catch (e) {
            console.error(e)
            Util.errorDialog(
              'We were unable to process the file you provided. Please try uploading a different file.',
              'Upload Error',
            )
            return
          } finally {
            this.isLoadingExistingImage = false
          }
        }

        if (file) {
          const fileUrl = this.upload(file)
          if (fileUrl) {
            this.setFile(file, fileUrl)
          }
        }
      },
      handleOpenFile () {
        this.$refs.file.click()
      },
      handleRemoveFile () {
        this.file = null
        this.fileKey = null
        this.fileUrl = null
        this.fileContentType = null
        this.uploadService.removeAllFiles(true)
        this.destroyCropper()
        this.notifyRemove()
      },
      handleCameraSelect () {
        this.isWebcamMode = true
      },
      handleWebcamCancel () {
        this.isWebcamMode = false
      },
      handleWebcamImage (image) {
        if (image.url) {
          this.isWebcamMode = false
          this.upload(new File([image.blob], 'photo.jpeg', { type: image.blob.type }))
        }
      },
      revokeFileUrl () {
        if (this.fileUrl) {
          URL.revokeObjectURL(this.fileUrl)
        }
      },
      rotate (amount = 1) {
        this.cropper.rotate(90 * amount)
        this.uploadCanvasBlob()
      },
      destroyCropper () {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = null
        }
      },
      setCropper () {
        this.$nextTick(() => {
          this.destroyCropper()
          try {
            this.cropper = new Cropper(this.$refs.image, {
              autoCrop: false,
              dragMode: 'none',
              movable: false,
              scalable: false,
              zoomable: false,
            })
          } catch (err) {
            new ErrorService('Failed to Start Cropper', 'CropperImageError').report({ ref: this.$refs.image, err })
            this.cropper = null
          }
        })
      },
      setFile (file, fileUrl) {
        this.file = file
        this.fileUrl = fileUrl
        if (file && fileUrl && IMG_MIME_TYPES.includes(file.type)) {
          this.setCropper()
        }
      },
      async loadExistingImage (fileUrl) {
        if (fileUrl) {
          this.isLoadingExistingImage = true

          const blob = await this.generateBlob(fileUrl)
          this.setFile(new File([blob], 'photo.png', { type: blob.type }), fileUrl)

          this.isLoadingExistingImage = false
        }
      },
      startCrop () {
        this.cropper.crop()
        this.isCropping = true
      },
      upload (file) {
        this.uploadService.removeAllFiles(true)
        this.uploadService.addFile(file) // Fires the upload
      },
      uploadCanvasBlob () {
        const fileName = this.file.name
        const { width, height, left, top } = this.cropper?.getCanvasData()

        const canvas = this.cropper.getCroppedCanvas({
          width,
          height,
          top,
          left,
        })

        try {
          canvas.toBlob(blob => {
            this.upload(new File([blob], fileName, { type: blob.type }))
          })
        } catch (err) {
          new ErrorService('Failed to upload Canvas Blob', 'CropperImageError').report({
            fileName: fileName,
            cropper: this.cropper,
            canvas,
            err,
          })
        }
      },
      cancelUpload () {
        this.uploadService.removeAllFiles(true)
        this.isUploading = false
      },
      handleAccept (file, callback) {
        if (file.size === 0) {
          callback('file_empty')
          return
        }

        this.isUploading = true
        this.uploadProgress = 0
        this.uploadInstructions = "Preparing to upload..."

        this.uploadService.presign(file, callback)
      },
      handleComplete (_file) {
        this.isUploading = false
      },
      handleSending (file, _xhr, formData) {
        Object
          .entries(file.awsSignatureFormData)
          .forEach(([key, value]) => { formData.append(key, value)})
        this.uploadInstructions = 'Uploading, please wait.'
      },
      handleSuccess (file, response) {
        /**
         * response XML
         *
         * <?xml version="1.0" encoding="UTF-8"?>
         * <PostResponse>
         *  <Location>https://wb-dropzone-dev.s3.amazonaws.com/uploads%2F246a5a7643adc79a651b33e9b9f71dbd00b8%2F1.png</Location>
         *  <Bucket>wb-dropzone-dev</Bucket>
         *  <Key>uploads/246a5a7643adc79a651b33e9b9f71dbd00b8/1.png</Key>
         *  <ETag>"953480de7122a92868cc44c853bc8e95"</ETag>
         * </PostResponse>
        */
        const domParser = new DOMParser()
        const xmlDoc = domParser.parseFromString(response, 'application/xml')
        const awsLocationElement = xmlDoc.querySelector('Location')
        const awsKeyElement = xmlDoc.querySelector('Key')

        this.setFile(file, awsLocationElement?.textContent)
        this.fileKey = awsKeyElement?.textContent
        this.fileContentType = file.type

        this.notifySuccess()
      },
      handleProgress (_file, progress, _bytesSent) {
        this.uploadProgress = progress
      },
      handleError (file, errorMessage, xhr) {
        this.uploadService.removeFile(file)

        if (xhr) {
          this.handleXhrError(file, errorMessage, xhr)
        } else {
          this.renderErrorMessage(errorMessage)
        }
      },
      handleXhrError (file, errorMessage, xhr) {
        new ErrorService('Failed to upload file to DZ bucket', 'AWSUploadError').report({
          dz_file: file,
          dz_error: errorMessage,
          xhr_readyState: xhr.readyState,
          xhr_status: xhr.status,
          xhr_statusText: xhr.statusText,
          aws_error: AWSRequestXHRDecorator(xhr).responseJSON['Error'],
        })

        Util.ajaxErrorDialog('Sorry, there was an issue uploading this file. Please refresh the page and try again.', xhr)
      },
      renderErrorMessage (errorMessage) {
        switch (errorMessage) {
          case 'file_type':
            Util.errorDialog(this.uploadService.getDefaultValue('fileTypeErrorMsg'), "File Type Not Allowed")
            break
          case 'file_too_big':
            Util.errorDialog(`Sorry, your file is too big to upload. Files must be ${this.uploadService.options.maxFilesize} MB or smaller.`, 'File Too Big')
            break
          case 'file_empty':
            Util.errorDialog(`It looks like this file is empty (0 bytes). Please double-check the file by opening it up in a different app (such as a PDF reader or image viewer).<br/><br/>If you're sure the file is OK but you still get this error message, you may need to restart your browser and try again.`, "Empty File")
            break
        }
      },
      notifyRemove () {
        this.$emit('remove')
      },
      notifySuccess () {
        this.$emit('success', {url: this.fileUrl, key: this.fileKey, contentType: this.fileContentType})
      },
      initializeUploadService () {
        const uploadOptions = {
          previewTemplate: "<div></div>",
          accept: this.handleAccept,
        }

        this.uploadService = new this.uploadServiceClass({element: this.$refs.dropzone, options: uploadOptions})
        this.uploadService.on('complete', this.handleComplete)
        this.uploadService.on('sending', this.handleSending)
        this.uploadService.on('uploadprogress', this.handleProgress)
        this.uploadService.on('success', this.handleSuccess)
        this.uploadService.on('error', this.handleError)
      },
    },
  }
</script>
