<template>
  <div class="wb-multiselect">
    <Multiselect
      v-model="localValue"
      :allow-empty="false"
      :options="fields"
      group-label="label"
      :group-select="false"
      group-values="items"
      label="label"
      :multiple="false"
      track-by="field_key"
      @input="handleInput"
    />
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect'

  export default {
    components: {
      Multiselect,
    },
    props: {
      fields: {
        type: Array,
        required: true,
      }, // Array of Objects { items: Array: { label: String, field_key: String }, label: String}
      value: {
        type: String, // field_key of selected field
      },
    },
    data () {
      return {
        localValue: null,
      }
    },
    watch: {
      value () {
        this.localValue = this.findValueFromKey(this.value)
      },
    },
    created () {
      this.localValue = this.findValueFromKey(this.value)
    },
    methods: {
      handleInput ({ field_key }) {
        this.$emit('update:value', field_key)
      },
      findValueFromKey (key) {
        for (let i = 0; i < this.fields.length; i++) {
          const items = this.fields[i].items
          for (let j = 0; j < items.length; j++) {
            const item = items[j]
            if (item.field_key === key) {
              return item
            }
          }
        }
        return null
      },
    },
  }
</script>
