// row containing employee profile data
<template>
  <tr v-highlight-on-change="value">
    <td>{{ label }}</td>
    <td
      v-if="isRestricted"
      class="info-item"
    >
      <BlockedContentIcon />
    </td>
    <td
      v-else
      ref="valueContent"
      class="block-translate"
    >
      <slot v-if="hasSlot" />
      <span v-else>{{ this.value }}</span>
    </td>
  </tr>
</template>

<script>
  import BlockedContentIcon from 'components/common/BlockedContentIcon'

  export default {
    name: 'profile-row',

    components: {
      BlockedContentIcon,
    },

    props: {
      label: {
        required: true,
        type: String,
      },
      value: {
        required: true,
      },
    },

    computed: {
      isRestricted() {
        return this.value && !!this.value._wb_blocked
      },

      hasSlot() {
        return !!this.$slots['default']
      },
    },
  }
</script>
