<template>
  <WbButton
    v-if="visible"
    ref="adminRejectButton"
    context="danger"
    @click.prevent="handleClick"
  >
    <WbIcon type="ban" />
    Reject
  </WbButton>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import WbIcon from 'components/common/WbIcon'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'admin-reject-button',
    components: {
      WbButton,
      WbIcon,
    },
    props: {
      visible: {
        required: true,
        type: Boolean,
      },
    },
    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
      handleClick() {
        this.setPageContextKeys({ rejectionReasonModalKey: true })
      },
    },
  }
</script>
