<template>
  <div
    class="historical-submissions-list"
    :class="{ 'with-indicators': hasIndicators }"
  >
    <div
      v-for="submissionDateGroup in submissionDateGroups"
      :key="submissionDateGroup.year"
      class="year-group"
    >
      <h4 class="text-center">{{ submissionDateGroup.year }}</h4>
      <div
        v-if="submissionDateGroup.submissions.length == 0"
        class="text-center"
      >
        No submissions this year
      </div>
      <ul class="list-group wb-list">
        <ListItem
          v-for="submission in submissionDateGroup.submissions"
          :key='submission.id'
          :href="$routes.admin_employee_submission_path({employee_id: pageContext.employee.id, id: submission.id})"
          :item='submission.id'
          :class="{ 'currently-viewed': visibleSubmissionId == submission.id }"
          :secondaryActions="pageContext.secondaryActionsEnabled"
        >
          <div class="wb-list__content">
            <SubmissionStatusIndicator :submission="submission" />
            <div class="wb-list__title-slideout">
              {{ formatDate(submission.submitted_at) }}
              <br />
              <small>
                Uploaded by {{ submission.uploaded_by_full_name }}
              </small>
              <div>
                <CurrentSubmissionBadge :submissionId="submission.id" />
              </div>
              <div v-if="visibleSubmissionId == submission.id">
                <span class="label label-default">
                  Submission you are currently viewing
                </span>
              </div>
            </div>
          </div>
          <template #secondaryActionsMenuItems>
            <DropdownListItem    
              title="Set as Current Submission"
              :disabled="isCurrentSubmission(submission) || submission.status !== 'accepted'"
              permissionModel="documents"
              :permissionRecordId="submission.document_id"
              permissionAction="upload_submission"
              @click="handleSetAsCurrentClick(submission)"
            />
          </template>
        </ListItem>
      </ul>
    </div>
  </div>
</template>

<script>
  import ListItem from 'components/common/ListItem'
  import SubmissionStatusIndicator from 'components/common/SubmissionStatusIndicator'
  import { mapGetters } from 'vuex'
  import DropdownListItem from 'components/common/DropdownListItem'
  import CurrentSubmissionBadge from 'components/document_submissions/CurrentSubmissionBadge'
  import moment from 'moment'

  export default {
    name: 'historical-submissions-list',
    components: {
      ListItem,
      SubmissionStatusIndicator,
      DropdownListItem,
      CurrentSubmissionBadge,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },

      hasIndicators: {
        type: Boolean,
        required: false,
        default: false,
      },

      visibleSubmissionId: {
        type: Number,
        required: false,
      },
    },

    computed: {
      // Submissions grouped by their year
      //
      // [
      //   { year: 2012, submissions: [{ id: 1, ... }] },
      //   { year: 2013, submissions: [] },
      //   { year: 2014, submissions: [{ id: 2, ... }] },
      //   ...
      // ]
      submissionDateGroups() {
        // Sort submissions by date, descending
        const dateSortedSubmissions = _.sortBy(this.items, s => { return s.submitted_at }).reverse()

        // Find current year, and the year of the first submission
        const currentYear = moment().year()
        const firstSubmissionYear = dateSortedSubmissions.length > 0 ?
          moment(dateSortedSubmissions.slice(-1)[0].submitted_at).year() : currentYear

        // Group submissions by year
        const dateGroupedSubmissions = _.groupBy(dateSortedSubmissions, submission => {
          return moment(submission.submitted_at).year()
        })

        // Build an array of submissions for each year, from the year of the first submission to today.
        // Include empty years in the range.
        const submissionGroups = []
        for (let year = currentYear; year >= firstSubmissionYear; year--) {
          submissionGroups.push({
            year: year,
            submissions: dateGroupedSubmissions[year] || [],
          })
        }

        return submissionGroups
      },

      ...mapGetters({
        pageContext: 'pageContext',
        assignmentFindBy: 'document_assignments/findBy',
      }),
    },

    methods: {
      isCurrentSubmission(submission) {
        // Does any assignment identify this submission as current?
        const submissions = this.assignmentFindBy({ current_submission_id: submission.id })
        return !!submissions[0]
      },

      handleSetAsCurrentClick(submission) {
        this.$emit('setAsCurrent', submission)
      },

      formatDate(jsonDate) {
        return moment(jsonDate).format('ddd MMM D @ h:mm A')
      },
    },
  }
</script>
