<template>
  <span>
    <TooltipIcon
      :icon="icon"
      :message="name"
    />
    <span
      v-if="shouldShowType"
      ref="type"
    >
      {{ type }}
    </span>
  </span>
</template>
<script>
  import TooltipIcon from 'components/common/TooltipIcon'

  const CONFIG_BY_TYPE = {
    acknowledge: {
      icon: 'eye',
      title: 'Acknowledge',
    },
    sign: {
      icon: 'pencil',
      title: 'Digital Signature',
    },
    upload: {
      icon: 'files-o',
      title: 'Upload Files',
    },
  }
  const DEFAULT_ICON = 'file-text-o'

  export default {
    components: {
      TooltipIcon,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      name: {
        type: String,
      },
    },
    computed: {
      config (){
        return CONFIG_BY_TYPE[this.type.toLowerCase()]
      },
      icon () {
        return this.config?.icon || DEFAULT_ICON
      },
      shouldShowType () {
        return !this.config
      },
    },
  }
</script>
